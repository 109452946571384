/**List available drivers in the system with an option
 * to update the balance amont for each driver after collection
 * and an action button for adding new driver
 * Drivers could be filtered by phone number
 */
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Table, Space, Button, Divider, Modal, Form, Input, Spin } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { notify, ValidateUser } from 'utilities';
import { GENERATE_DRIVER_CODE, GET_DRIVERS, SETTLE_BALANCE } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';
import { useTranslation } from 'react-i18next';

import SearchDrivers from './search-drivers';

const { Item } = Form;

const DriversListContainer = styled.div``;

const DriversList = () => {
  ValidateUser();
  const [t, i18n] = useTranslation("translation");
  const history = useHistory();
  const [form] = Form.useForm();
  const searchInput = useRef(null);

  const [page, setPage] = useState(1);
  const [driver, setDriver] = useState(0);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState(0);

  const { data, loading, refetch } = useQuery(GET_DRIVERS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [
    generateCode,
    { loading: generatingCode },
  ] = useMutation(GENERATE_DRIVER_CODE, { awaitRefetchQueries: true });

  const [
    settleBalance,
    { loading: settlingBalance },
  ] = useMutation(SETTLE_BALANCE, { awaitRefetchQueries: true });

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <SearchDrivers
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t('Search')}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              {t('Reset')}
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filter,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Verification Code',
      dataIndex: ['verification_code', 'code'],
      key: 'verification_code',
    },
    {
      title: 'Balance Amount',
      key: 'balance',
      render: (driver) => `${driver.balance} L.E.`,
    },
    {
      title: 'Actions',

      render: (driver) => {
        const currentTimestamp = new Date();
        const expiryTimestamp = new Date(
          driver.verification_code && driver.verification_code.expiry_time
        );

        const ViewOrdersButton = (
          <Link to={`/driver-orders/${driver.id}`}>{t('View Orders')}</Link>
        );

        const ViewDriverCodeGeneration = (
          <Button
            style={{ height: 18, padding: 0 }}
            type="link"
            disabled={currentTimestamp < expiryTimestamp}
            onClick={() =>
              generateCode({
                variables: {
                  id: driver.id,
                },
                refetchQueries: () => [
                  {
                    query: GET_DRIVERS,
                    variables: {
                      page,
                      first: 5,
                    },
                  },
                ],
              }).then(function (data) {
                data = data.data.generateDriverCode;

                if (data.status !== true) {
                  notify('error', data.message);
                }
                if (data.status === true) {
                  notify('success', data.message);
                }
              })
            }
          >
            {t('Regenerate Code')}
          </Button>
        );
        const CollectAmountButton = (
          <Button
            style={{ height: 18, padding: 0 }}
            type="link"
            disabled={driver.balance <= 0}
            onClick={() => {
              setDriver(driver);
              setVisible(true);
            }}
          >
            {t('Collect')}
          </Button>
        );
        return (
          <Space>
            <Can perform="SETTLE_DRIVER_BALANCE" yes={CollectAmountButton} />
            <Can perform="CREATE_DRIVER" yes={ViewDriverCodeGeneration} />
            <Can
              perform="LIST_DRIVER_ORDERS"
              yes={() => <Divider type="vertical" />}
            />
            <Can perform="LIST_DRIVER_ORDERS" yes={ViewOrdersButton} />
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  const onSubmit = (values) => {
    const { amount } = values;

    settleBalance({
      variables: {
        driverId: driver.id,
        amount,
      },
      refetchQueries: () => [
        {
          query: GET_DRIVERS,
          variables: {
            page,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        const {
          data: {
            driverSettleBalance: { message, status },
          },
        } = res;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };

  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setFilter(filters.phone);
    refetch({
      page: pagination.current,
      driverId: filters.phone || undefined,
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <DriversListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Drivers')}</PrimaryTitle>
        <Can
          perform="CREATE_DRIVER"
          yes={
            <PrimaryButton onClick={() => history.push('/new-driver')}>
              {t('Add Driver')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <Spin spinning={generatingCode}>
        <Table
          bordered
          dataSource={data?.allDrivers?.data}
          columns={columns}
          loading={loading || settlingBalance}
          rowKey="id"
          onChange={onChange}
          pagination={{
            total: data?.allDrivers?.paginatorInfo?.total,
            pageSize: 5,
            showSizeChanger: false,
          }}
          scroll={{ x: 400 }}
        />
      </Spin>

      <Modal
        destroyOnClose
        visible={visible}
        title={t('Amount To Collect')}
        okText={t('Collect')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        confirmLoading={settlingBalance}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          name="amount_form"
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            label={t('Amount')}
            name="amount"
            rules={[
              {
                required: true,
                message: t('Please, enter the required amount!'),
              },
            ]}
          >
            <Input placeholder={t('Enter Amount')} style={{ width: 250 }} />
          </Item>
          <p style={{ margin: 0, lineHeight: 2 }}>{t('L.E.')}</p>
        </Form>
      </Modal>
    </DriversListContainer>
  );
};

export default DriversList;
