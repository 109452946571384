/** This file is contains Listing table for partners segments,
 * actions buttons for add new partner, adding new segments and
 * exporting segments data*/
import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Button, Table, Modal, Switch, InputNumber } from 'antd';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import {  notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle } from 'components';
import GET_AGENTS from '../../services/agent/get-agents';
import TOGGLE_PRE_PAID from '../../services/agent/toggle-prepaid';
import AddCreditAmount from '../../services/agent/add-agent-credit';
import { useTranslation } from 'react-i18next';
import Can from '../../components/Can';
import { useHistory} from 'react-router-dom';

const SegmentsListContainer = styled.div``;

const AgentList = () => {
  ValidateUser();
  const [amount, setAmount] = useState(0);
    const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [selectedAgent, setSelectedAgent] = useState(0);
  const [creditFormVisible, setCreditFormVisible] = useState(false);
  /** query for listing all segments */
  const { data, loading, fetchMore, refetch } = useQuery(GET_AGENTS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [AddCredit, { loading: loadingCredit }] = useMutation(AddCreditAmount);
  const [togglePrePaidMutation, { loading: loadingMutation }] = useMutation(
    TOGGLE_PRE_PAID
  );
  const addCredit = () => {
    if (amount == 0) {
      alert('please set value first');
      return;
    }

    AddCredit({
      variables: {
        id: selectedAgent,
        amount: amount,
      },
    })
      .then((res) => {
        refetch().then(() => {});
      })
      .catch((err) => {
        console.info(err);
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      })
      .finally(() => {
        setCreditFormVisible(false);
        setSelectedAgent(0);
        setAmount(0);
      });
  };

  const togglePrePaid = (agentId) => {
    togglePrePaidMutation({
      variables: {
        id: agentId,
      },
    })
      .then((res) => {})
      .catch((err) => {
        console.info(err);
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      })
      .finally(() => {
        refetch().then(() => {});
      });
  };
  /** Columns Names for segments table */
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
    },
    {
      title: 'Totals',
      dataIndex: 'totals',
    },
    {
      title: 'Credit Balance',
      dataIndex: 'credit_balance',
    },
    {
      title: 'No Credit',
      render: (agent) => {
        return (
          <Can
            perform="CHANGE_PRE_PAID"
            yes={
              <Switch
                checked={agent.enable_borrowing}
                defaultChecked={agent.enable_borrowing}
                onChange={() => togglePrePaid(agent.id)}
              />
            }
            no={agent.enable_borrowing ? 'Yes' : 'No'}
          />
        );
      },
    },
    {
      title: 'Add Credit',
      render: (agent) => {
        return (
          <Button
            onClick={() => {
              setSelectedAgent(agent.id);
              setAmount(0);
              setCreditFormVisible(true);
            }}
          >
            {t('Add Credit')}
          </Button>
        );
      },
    },
    {
      title: 'Transaction',
      render: (agent) => {
        return (
          <Button
            onClick={() => {
                history.push(`/agent-logs/${agent.id}`);
            }}
          >
            {t('view')}
          </Button>
        );
      },
    },
    {
      title: 'Credit Balance',
      dataIndex: 'credit_balance',
    },
  ];
  //Translate Function For columns
 

  return (
    <SegmentsListContainer>
      <Modal
        destroyOnClose
        visible={creditFormVisible}
        title={t('Add Credit to Selected Agent')}
        okText={t('Add Credit')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setCreditFormVisible(false);
          setSelectedAgent(0);
          setAmount(0);
        }}
        confirmLoading={loadingCredit}
        onOk={addCredit}
      >
        <InputNumber
          onChange={(value) => {
            setAmount(value);
          }}
          placeholder={t('Enter Amount')}
          style={{ width: '70%' }}
        />
        {t('L.E.')}
      </Modal>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>{t('Agent List')}</PrimaryTitle>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.agents.data}
        columns={columns}
        loading={loading || loadingMutation}
        rowKey="id"
        pagination={{
          total: data?.agents?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
    </SegmentsListContainer>
  );
};

export default AgentList;
