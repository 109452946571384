import React, { useState, useMemo } from 'react';

import styled from '@emotion/styled/macro';
import { Input, Button, Spin, Select, Form, Col, Row, Checkbox } from 'antd';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { addCartPromoCode, removeCartPromoCode } from 'services';
import { notify } from 'utilities';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const PromoCodeContainer = styled.div`
  margin: 30px auto 20px auto;
  .ant-input {
    width: 200px;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    margin-bottom: 4px;
  }

  p {
    display: inline-block;
    margin-inline-end: 20px;
    color: #002d59;
    font-size: 16px;
    font-weight: 500;
  }

  .promoMessage {
    display: block;
    font-size: 14px;
  }
`;
const { Item } = Form;
const PromoCode = ({
  cartToken,
  currentCart,
  setCurrentCart,
  currentPromoCode,
  restructureTickets,
  selectedCustomer,
}) => {
  const offlinePromoCodes = gql`
    query {
      allPromoCodes(first: 1000, active: true, offline: true) {
        data {
          id
          name
          code
        }
      }
    }
  `;

  const { data: offlinePromos } = useQuery(offlinePromoCodes, {});

  const promos = offlinePromos && offlinePromos?.allPromoCodes?.data;
  const [promoCode, setPromoCode] = useState(null);
  const [offline, setOffline] = useState(false);
  const [offlinePromo, setOfflinePromo] = useState(false);
  const [t,i18n] = useTranslation("translation");

  const options =
    promos &&
    promos.map((promo) => (
      <Option key={promo.id} value={promo.code}>
        {promo.name}
      </Option>
    ));
  const calculateTicketsPrice = (groupedTickets, key) =>
    Object.keys(groupedTickets).reduce(
      (accumulator, groupId) => accumulator + groupedTickets[groupId][key],
      0
    );

  const groupedTickets = useMemo(
    () => restructureTickets(currentCart.tickets),
    [currentCart.tickets, restructureTickets]
  );

  const totalPrice = useMemo(
    () => calculateTicketsPrice(groupedTickets, 'groupPrice'),
    [groupedTickets]
  );

  const originalTotalPrice = useMemo(
    () => calculateTicketsPrice(groupedTickets, 'originalGroupPrice'),
    [groupedTickets]
  );

  console.info(currentPromoCode);
  const isPromocodeApplied =
    totalPrice !== originalTotalPrice ||
    (currentPromoCode && currentPromoCode.value === 0);

  const [applyPromoCode, { loading: applyingPromo }] = useMutation(
    addCartPromoCode,
    {
      variables: {
        token: cartToken,
        promo_code: offline ? offlinePromo : promoCode,
        customer_id: selectedCustomer.id,
      },
    }
  );

  const [removePromoCode, { loading: removingPromo }] = useMutation(
    removeCartPromoCode,
    {
      variables: {
        token: cartToken,
      },
    }
  );

  const submitPromo = () => {
    if (!promoCode && !offlinePromo) {
      notify('error', 'Please enter promo code !');
      return;
    }
    applyPromoCode()
      .then(
        ({
          data: {
            addCartPromoCode: { data, status, message },
          },
        }) => {
          if (status) {
            setCurrentCart(data);
            notify('success', message);
          } else {
            notify('error', message);
          }
        }
      )
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });
  };

  const removePromo = () => {
    removePromoCode()
      .then(
        ({
          data: {
            removeCartPromoCode: { data, status, message },
          },
        }) => {
          setCurrentCart(data);
          if (status) {
            setCurrentCart(data);
            notify('success', message);
          } else {
            notify('error', message);
          }
        }
      )
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });
  };
  const togglePromo = (values) => {
    console.log(values.target.checked);
  };
  return (
    <PromoCodeContainer>
      <Checkbox onChange={(e) => setOffline(e.target.checked)}>
        {t("Offline Promo Code")}
      </Checkbox>
      <div style={!offline ? {} : { display: 'none' }}>
        <p>+ &nbsp; {t("Use Promo Code")}</p>
        <Input
          placeholder={t('Enter Promo Code')}
          onInput={({ target: { value } }) => {
            setPromoCode(value);
          }}
          type="text"
          size="large"
        />
      </div>
      <div style={offline ? {} : { display: 'none' }}>
        <p>+ &nbsp; {t("Offline PromoCode")}</p>
        <Select
          size="large"
          placeholder="Please choose promo"
          onChange={(promo) => {
            setOfflinePromo(promo);
          }}
        >
          {options}
        </Select>
      </div>
      <div>
        <Button onClick={submitPromo} type="primary" size="large">
          {currentPromoCode && currentPromoCode.active
            ? t('Change Code')
            : t('Discount')}
          {applyingPromo && <Spin />}
        </Button>

        {currentPromoCode && currentPromoCode.active && (
          <Button
            onClick={removePromo}
            type="primary"
            size="small"
            style={{ marginLeft: '15px' }}
          >
            {t("Remove Code")}
            {removingPromo && <Spin />}
          </Button>
        )}

        {currentPromoCode && currentPromoCode.active && !isPromocodeApplied && (
          <p className="promoMessage" style={{ color: '#D3072A' }}>
            The applied promocode doesn't apply to your selected tickets.
          </p>
        )}
      </div>
      <div>
        {currentPromoCode && currentPromoCode.active && isPromocodeApplied && (
          <>
            <p style={{display: 'flex' , flexDirection:"Row"}}>
              {t("Activated Promo Code:")}{' '}
              <span style={{ color: '#0BCA47' }}>{currentPromoCode.code}</span>
            </p>
          </>
        )}
      </div>
    </PromoCodeContainer>
  );
};

export default PromoCode;
