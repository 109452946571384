/**Filter component to filter orders by phone number, order number or order status */
import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Select, Spin, Space, DatePicker, Collapse } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ACTIVE_LOCATIONS, GET_USERLISTS } from '../../services';
import EXPORT_SHIPPING_REPORT from '../../services/shipments/export-shipping-report';
import { Can, ListHeader, PrimaryTitle } from '../../components';
import { notify } from '../../utilities';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;

const Filter = ({
  form: { getFieldDecorator, validateFields, setFieldsValue, resetFields },
  refetch,
  resetPage,
}) => {
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS
  );
  const { data: users, loading: usersLoading } = useQuery(GET_USERLISTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 100,
      page: 1,
    },
  });
  const [filteredValues, setFilteredValues] = useState({
    orderNumber: null,
    customerPhone: null,
    station: null,
    customerName: null,
    from: null,
    to: null,
    creatable_id: null,
  });
  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const { activeLocations } = locationsData || { activeLocations: [] };

  const FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;

    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const {
          orderNumber,
          customerPhone,
          station,
          customerName,
          shipmentDate,
          creatable_id,
        } = values;
        setFilteredValues({
          orderNumber: orderNumber || '',
          customerPhone: customerPhone || '',
          station: station || '',
          creatable_id: creatable_id || '',
          customerName: customerName || '',
          from: shipmentDate
            ? shipmentDate[0].format(FORMAT_YYYY_MM_DD)
            : undefined,
          to: shipmentDate
            ? shipmentDate[1].format(FORMAT_YYYY_MM_DD)
            : undefined,
        });
        refetch({
          orderNumber: orderNumber || '',
          customerPhone: customerPhone || '',
          station: station || '',
          customerName: customerName || '',
          creatable_id: creatable_id | '',
          from: shipmentDate
            ? shipmentDate[0].format(FORMAT_YYYY_MM_DD)
            : undefined,
          to: shipmentDate
            ? shipmentDate[1].format(FORMAT_YYYY_MM_DD)
            : undefined,
        });
      }
    });
  };

  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_SHIPPING_REPORT,
    {
      variables: {
        orderNumber: filteredValues.orderNumber || '',
        customerPhone: filteredValues.customerPhone || '',
        station: filteredValues.station || '',
        customerName: filteredValues.customerName || '',
        from: filteredValues.from || '',
        to: filteredValues.to || '',
      },
    }
  );
  const handleExport = () => {
    exportReport({
      variables: {
        orderNumber: filteredValues.orderNumber || '',
        customerPhone: filteredValues.customerPhone || '',
        station: filteredValues.station || '',
        customerName: filteredValues.customerName || '',
        from: filteredValues.from || '',
        to: filteredValues.to || '',
      },
    })
      .then((res) => {
        const {
          data: {
            exportShipping: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };

  const handleReset = () => {
    resetPage();
    resetFields();
    refetch({
      orderNumber: undefined,
      customerPhone: undefined,
      station: undefined,
      customerName: undefined,
      from: undefined,
      to: undefined,
      creatable_id: undefined,
    });
  };

  return (
    <Collapse>
      <Panel header={t('Show Filter Form')} key="1">
        <div>
          <Form
            layout="vertical"
            onSubmit={handleSubmit}
            style={{ width: '50%' }}
          >
            <Form.Item label={t('Order Number')}>
              {getFieldDecorator('orderNumber')(
                <Input
                  size="large"
                  placeholder={t('Enter Order Number')}
                ></Input>
              )}
            </Form.Item>
            <Form.Item label={t('Phone Number')}>
              {getFieldDecorator('customerPhone', {
                rules: [
                  {
                    // len: 11,
                    message: t('Invalid Phone Number'),
                    // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder={t('Enter Customer Phone')}
                ></Input>
              )}
            </Form.Item>
            <Form.Item label={t('Customer Name')}>
              {getFieldDecorator('customerName')(
                <Input
                  size="large"
                  placeholder={t('Enter Customer Name')}
                ></Input>
              )}
            </Form.Item>
            <Form.Item
              size="large"
              className={'selectStation'}
              label={t('Station')}
            >
              {getFieldDecorator('station')(
                <Select
                  placeholder={t('Select Assigned Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {nameLang === 'en'
                                  ? location.name_en
                                  : location.name_ar}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('Creation Range')}>
              {getFieldDecorator('shipmentDate', {
                rules: [
                  {
                    type: 'array',

                    message: 'Please specify the shipment date range!',
                  },
                ],
              })(
                <RangePicker placeholder={[t('Start date'), t('End date')]} />
              )}
            </Form.Item>
            <Form.Item label={t('Booked by')}>
              {getFieldDecorator('creatable_id')(
                <Select
                  size="large"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder={t('Select Booked by')}
                >
                  {users &&
                    users.users &&
                    users.users.data.map((user, idx) => (
                      <Option key={idx} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <space>
              <Button type="primary" size="large" htmlType="submit">
                {t('Filter Records')}
              </Button>
              <Button type="primary" size="large" onClick={handleReset}>
                {t('Reset Fields')}
              </Button>
              <Can
                perform="EXPORT_TICKET_REPORT"
                yes={
                  <Button
                    className={'ant-btn-danger'}
                    type="primary"
                    size="large"
                    loading={exporting}
                    onClick={() => handleExport()}
                  >
                    {t('Export Report')}
                  </Button>
                }
              />
            </space>
          </Form>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Form.create({})(Filter);
