/** Component responsible for listing
 * the available shifts and action button for adding
 * new shifts and editing the shift time of an existing shift */
import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Button,
  Space,
  Form,
  Input,
  DatePicker,
  Select,
  Spin,
  Popconfirm,
  Modal,
} from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_LOCATIONS,
  GET_SHIFTS_LOGS,
  EXPORT_SHIFT_LOG,
  EXPORT_SHIFT_LOG_ACTIONS,
  GET_ACTIVE_LOCATIONS,
  FORCE_CANCEL_TICKET,
  ADD_MONEY_TO_SHIFT,
} from 'services';
import { ListHeader, PrimaryTitle, Can } from 'components';
import { useDebounce, notify } from 'utilities';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const ShiftsListContainer = styled.div`
  .filter-form {
    width: 100%;
    margin: 10px;
  }
  .filter-form input,
  .filter-form input select {
  }
`;
const ShiftsLogs = () => {
  const { Option, OptGroup } = Select;

  const { Item } = Form;
  const [form] = Form.useForm();
  const [addMoneyForm] = Form.useForm();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  /** query for listing the available shifts */
  const { data, loading, fetchMore, refetch } = useQuery(GET_SHIFTS_LOGS, {
    variables: {
      first: 5,
      page: 1,
    },

    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleReset = () => {
    form.resetFields();
    setFilteredValues({
      created_at: '',
      station: '',
      user_name: '',
      id: '',
    });
    refetch({
      created_at: undefined,
      station: undefined,
      user_name: undefined,
      id: undefined,
    });
  };
  const { data: locations, loading: loadingLocations } = useQuery(
    GET_LOCATIONS,
    {
      variables: {
        page: 1,
        first: 1000,
        orderBy: 'EN',
      },
    }
  );
  const [filteredValues, setFilteredValues] = useState({
    created_at: '',
    station: '',
    user_name: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [shift_id, setShift] = useState(null);
  const [addMoneyVisible, setAddMoneyVisible] = useState(false);
  const [exportShiftLog] = useMutation(EXPORT_SHIFT_LOG);
  const [addMoney, { loading: addingMoney }] = useMutation(ADD_MONEY_TO_SHIFT);
  const [exportShiftLogActions, { loading: exporting }] = useMutation(
    EXPORT_SHIFT_LOG_ACTIONS,
    {
      variables: {
        user_name: filteredValues.user_name,
        station: filteredValues.station,
        created_at: filteredValues.created_at,
      },
    }
  );
  const availableLocations = locations?.locations?.data?.reduce(
    (result, location) => {
      const cityName = location.city.name_en;
      const cityId = location.city.id;
      if (!Object.keys(result).includes(cityName)) {
        result[cityName] = [{ ...location, city_id: cityId }];
      } else {
        result[cityName].push({ ...location, city_id: cityId });
      }
      return result;
    },
    {}
  );

  const handleShiftViewExport = () => {
    exportShiftLogActions()
      .then((res) => {
        const {
          data: {
            exportStationShiftLogActions: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };

  const AddMoney = (values) => {
    const { amount, comment } = values;
    addMoney({
      variables: {
        id: shift_id,
        amount: amount,
        comment,
      },
    })
      .then((response) => {
        const {
          data: {
            addOrDownToShiftLog: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        refetch({ page: currentPage });
        notify(
          notificationType,
          i18n.language === 'en' ? message : t('ammount_added')
        );
        setShift(0);
        addMoneyForm.resetFields();
        setAddMoneyVisible(false);
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      });
  };

  const onFinish = (values) => {
    const { createdAt, station, userName, id } = values;
    setFilteredValues({
      created_at: createdAt ? createdAt.toISOString().split('T')[0] : '',
      station: station || '',
      user_name: userName || '',
      id: id || '',
    });
    setCurrentPage(1);
    refetch({
      created_at: createdAt ? createdAt.toISOString().split('T')[0] : undefined,
      station: station || undefined,
      user_name: userName || undefined,
      id: id || undefined,
    });
  };
  const handleExport = (id) => {
    exportShiftLog({
      variables: {
        id: id,
      },
    })
      .then((res) => {
        const {
          data: {
            exportStationShiftLog: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };

  /** Listed columns for available shifts */
  const columns = [
    {
      title: 'shift_id',
      key: 'shiftInfo',
      render: (shift) => shift?.shiftLog.id,
    },
    {
      title: 'Station',
      dataIndex: [
        'shiftLog',
        'shift',
        'station',
        i18n.language === 'en' ? 'name_en' : 'name_ar',
      ],
    },
    {
      title: 'Username',
      key: 'agentName',
      render: (shift) => shift?.shiftLog?.user?.name,
    },
    {
      title: 'Status',
      key: 'shiftStatus',
      render: (shift) => {
        if (shift?.shiftLog?.ended_at) {
          return 'Ended';
        } else {
          return 'Started';
        }
      },
    },

    {
      title: 'Shipping Amount',
      dataIndex: 'shippingAmount',
    },
    {
      title: 'Cash Payments',
      dataIndex: 'cashPayments',
    },
    {
      title: 'Offline Credit Card',
      dataIndex: 'offlineCreditCard',
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSales',
    },
    {
      title: 'Other Fees',
      dataIndex: 'otherFees',
    },
    {
      title: 'Pay at Store',
      dataIndex: 'payStore',
    },
    {
      title: 'Cash Refund Amount',
      dataIndex: 'cashRefundAmount',
    },
    {
      title: 'Offline Refund Amount',
      dataIndex: 'offlineRefundAmount',
    },
    {
      title: 'Cash Drawer',
      dataIndex: 'cashDrawer',
    },
    {
      title: 'Start Datetime',
      key: 'shiftStartDate',
      render: (shift) => {
        let dateObj = moment(shift.shiftLog?.started_at).format(
          'DD MMM YYYY hh:mm:ss'
        );
        if (moment(dateObj).isValid()) {
          return dateObj;
        } else {
          return '';
        }
      },
    },
    {
      title: 'End Datetime',
      key: 'shiftEndDate',
      render: (shift) => {
        let dateObj = moment(shift.shiftLog?.ended_at).format(
          'DD MMM YYYY hh:mm:ss'
        );
        if (moment(dateObj).isValid()) {
          return dateObj;
        } else {
          return '';
        }
      },
    },
    {
      title: 'Actions',
      render: (shift) => {
        const AddMoneyButton = (
          <Button
            type="link"
            danger
            onClick={() => {
              setAddMoneyVisible(true);
              setShift(shift?.shiftLog?.id);
            }}
          >
            {t('Money+/-')}
          </Button>
        );
        return (
          <Space size="middle">
            <Button
              type="link"
              onClick={() => handleExport(shift?.shiftLog.id)}
            >
              {t('Export')}
            </Button>

            <Can perform="ADD_MONEY_TO_SHIFT" yes={AddMoneyButton} />
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const expandedColumns = [
    {
      title: t('#Orders'),
      dataIndex: 'ordersCount',
    },
    {
      title: t('#Tickets'),
      dataIndex: 'ticketsCount',
    },
    {
      title: t('#Shipping Orders'),
      dataIndex: 'shippingOrdersCount',
    },
  ];
  return (
    <ShiftsListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Shifts Logs')}</PrimaryTitle>
        <Can
          perform="EXPORT_TICKET_REPORT"
          yes={
            <Button
              type="primary"
              loading={exporting}
              onClick={() => handleShiftViewExport()}
            >
              {t('Export Logs Report')}
            </Button>
          }
        />
      </ListHeader>

      <Modal
        title={
          <Space size={'middle'}>
            <ExclamationCircleOutlined
              style={{ fontSize: 22, color: '#FFCC00' }}
            />
            {t("Add_decrease_Amount_to_Shift")}
          </Space>
        }
        visible={addMoneyVisible}
        onCancel={() => {
          setAddMoneyVisible(false);
        }}
        footer={[
          <Button key="force_cancel" onClick={() => setAddMoneyVisible(false)}>
            {t('Return')}
          </Button>,
        ]}
      >
        <Spin spinning={addingMoney}>
          <Form form={addMoneyForm} onFinish={AddMoney} layout={'inline'}>
            <Item
              style={{ width: '100%', marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: t('Please, enter the amount!'),
                },
              ]}
              name="amount"
              label={t('Amount')}
            >
              <Input placeholder={t('Enter Amount')} defaultValue="0" />
            </Item>
            <Item
              style={{ width: '100%', marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: t('Please, enter the comment!'),
                },
              ]}
              name="comment"
              label={t("add_comment")}
            >
              <Input placeholder={t('Enter comment')} defaultValue="" />
            </Item>
            <Item>
              <Button type="danger" htmlType="submit">
                {t('Confirm')}
              </Button>
            </Item>
          </Form>
        </Spin>
      </Modal>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className={'filter-form'}
        style={{ width: '50%' }}
      >
        <Item name="id" label={t('shift_id')}>
          <Input size="middle" placeholder={t('Enter shift log id')}></Input>
        </Item>
        <Item name="userName" label={t('User Name')}>
          <Input size="middle" placeholder={t('Enter User Name')}></Input>
        </Item>
        <Item name="createdAt" label={t('Create at')}>
          <DatePicker placeholder={t('Select date')} />
        </Item>
        <Item name="station" label={t('Station')}>
          <Select
            placeholder={t('Select Assigned Station')}
            size="middle"
            showSearch
            optionFilterProp="children"
            allowClear
          >
            {loadingLocations || !availableLocations ? (
              <Option value={null} disabled style={{ textAlign: 'center' }}>
                <Spin tip="Loading Locations..." />
              </Option>
            ) : (
              Object.keys(availableLocations).map((cityName, cityIndex) => (
                <OptGroup key={`city_${cityIndex}`} label={cityName}>
                  {availableLocations[cityName].map(
                    (location, locationIndex) => (
                      <Option
                        key={`location_${cityIndex}_${locationIndex}`}
                        value={location.id}
                      >
                        {nameLang === "en" ? location.name_en : location.name_ar}
                      </Option>
                    )
                  )}
                </OptGroup>
              ))
            )}
          </Select>
        </Item>

        <Item>
          <Button type="primary" htmlType="submit">
            {t('Search')}
          </Button>
        </Item>
        <Item>
          <Popconfirm
            title={t('Are you sure you want to reset all filters?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onConfirm={handleReset}
            onCancel={() => {}}
          >
            <Button htmlType="button" type="primary" danger>
              {t('Reset All Filters')}
            </Button>
          </Popconfirm>
        </Item>
      </Form>
      <Table
        bordered
        dataSource={data?.stationShiftsLogs.shiftLogsRecords}
        columns={columns}
        loading={loading}
        rowKey={(shift) => shift?.shiftLog.id}
        scroll={{ x: 400 }}
        pagination={{
          current: currentPage,
          total: data?.stationShiftsLogs?.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPage(page);
            fetchMore({
              variables: {
                page,
                first: 5,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              scroll={{ x: 400 }}
              rowKey={(shift) => shift?.shiftLog.id}
              columns={expandedColumns}
              dataSource={[record]}
            />
          ),
        }}
      />
    </ShiftsListContainer>
  );
};

export default ShiftsLogs;
