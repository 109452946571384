/** Component for creating/editing staff member */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Popconfirm,
  Checkbox,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {} from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import {
  notify,
  formatRole,
  FormContainer,
  ActionButton,
  CancelButton,
} from 'utilities';
import gql from 'graphql-tag';
import { isMobile } from 'react-device-detect';
import i18next, { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option, OptGroup } = Select;

const RoleEditContainer = styled.section`
  ${FormContainer}
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  .ant-btn-link {
  transform: ${i18next.language === "ar" ? 'translate(-200px, -35px)' : null} ;
  }
  ${ActionButton}
  ${CancelButton}
`;
const UpdateRole = gql`
  mutation UpdatePermissionRoles($id: ID!, $permissions: [Int!]!) {
    UpdatePermissionRoles(id: $id, permissions: $permissions) {
      status
      message
    }
  }
`;

const RolePermissionForm = ({
  location: { state: { role, permissions, defaultChecked } = {} },
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const [form] = Form.useForm();
  const [UpdateRolePermissions, { loading: UpdateLoading }] = useMutation(
    UpdateRole
  );
  if (!role) history.push('/roles');
  const onFinish = (values) => {
    const { permissions } = values;
    UpdateRolePermissions({
      variables: {
        id: role.id,
        permissions: permissions,
      },
    })
      .then((res) => {
        if (res?.data?.UpdatePermissionRoles?.status === true) {
          notify('success', nameLang === "en" ? res?.data?.UpdatePermissionRoles?.message : t('UpdatePermissionRoles') );
          history.push('/roles');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });
  };
  return (
    <RoleEditContainer>
      <ListHeader>
        <PrimaryTitle>{t('Edit Role Permission - ') + role.name}</PrimaryTitle>
      </ListHeader>

      <Spin spinning={UpdateLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          layout={isMobile ? 'horizontal' : 'inline'}
          name="assign-permissions"
          preserve={false}
          initialValues={{
            permissions: defaultChecked,
          }}
        >
          <Item label={t('Select Role Permission')} name="permissions">
            <Checkbox.Group>
              {permissions &&
                permissions.length > 1 &&
                permissions.map((perm, index) => {
                  return (
                    <Col>
                      <Checkbox key={perm.id} value={perm.id}>
                        {perm.name}
                      </Checkbox>
                    </Col>
                  );
                })}
            </Checkbox.Group>
          </Item>
          <Row gutter={10} align="middle">
            <Col span={25}>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={UpdateLoading}
                >
                  {t('Update')}
                </Button>
              </Item>
            </Col>

            <Col span={8}>
              <Item>
                <Popconfirm
                  title={t('Are you sure you want to discard?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => history.goBack()}
                  onCancel={() => {}}
                >
                  <Button type="link" size="large" danger>
                    {t('Cancel')}
                  </Button>
                </Popconfirm>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </RoleEditContainer>
  );
};

export default RolePermissionForm;
