/** Component that lists all shipping details and
 * contain an action button for printing these details
 */
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Spin, Divider, Row, Col} from 'antd';
import {GET_ORDER_DETAILS} from 'services';
import {useQuery} from '@apollo/react-hooks';
import {ListHeader, PrimaryButton, Can} from 'components';
import ReactToPrint from 'react-to-print';
import {ValidateUser} from '../../utilities';
import {ReactComponent as Logo} from 'images/bluebuslogo.svg';
import {default as ShipmentRules} from './rules';
import {useTranslation} from 'react-i18next';
import qz from "qz-tray";

const ShipmentDetails = () => {
  ValidateUser();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const componentRef = useRef();
  const {orderId} = useParams();
  const {data, loading} = useQuery(GET_ORDER_DETAILS, {
    variables: {
      id: orderId,
    },
  });
  const [defaultPrinter, setDefaultPrinter] = useState(null);
  useEffect(async () => {
    await getDefault();
  }, [])


  async   function getDefault() {
    await qz.websocket.connect()
        .then(qz.printers.getDefault)
        .then(function (printer) {
          console.info('connect')
          setDefaultPrinter(printer);
        })
        .catch(function (e) {
          console.error(e);
        });
  }

  const pure = async () => {

    const config = qz.configs.create(defaultPrinter);
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const todayDate = today.toDateString();


    const dataToPrint = '<!DOCTYPE html>' +
        '<html lang="en">' +
        '' +
        '<head>' +
        '    <meta charset="UTF-8"> ' +
        '    <style>' +
        'body{}  h5,p{margin: 5px;padding: 0;    font-size: 14px;}  li{margin: 10px;}  .ant-col {display: inline-block;max-width: 100%;min-height: 1px;}  .ant-col-6 {display: inline-block;-ms-flex: 0 0 25%;flex: 0 0 25%;width: 23%;}  .ant-col-12 {display: inline-block;-ms-flex: 0 0 50%;flex: 0 0 50%;width: 48%;} </style>' +
        '</head>' +
        '<body>' +
        '<div class="ant-spin-container">' +
        '    <div>' +
        '        <div style="padding-left: 50px;">' +
        '            <div class="ant-row" style="margin: 0px -10px 10px -25px;">' +
        '                <div class="ant-col ant-col-12" style="margin-bottom: 10px">' +
        '                     <img src="https://api.bluebus.com.eg/svg/logo.png" style="width: 125px; height: 25px">' +
        '                </div>' +
        '                <div class="ant-col ant-col-12"><h4 style="margin: 25px 0px 0px;">Shipping Voucher</h4></div>' +
        '' +
        '            </div>' +
        '' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Order Number</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.order_number + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Issue Date</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.created_at + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Employee</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;"> ' + data?.shippingOrder?.creatable?.name + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Store</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.store + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender Name</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.name + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver Name</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.receiver?.name + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender Phone</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.phone + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver Phone</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.receiver?.phone + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender National ID</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.national_id + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver National ID</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">0</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">From City</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.from_location?.name_en + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">To City</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.to_location?.name_en + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Description</h5></div>' +
        data?.shippingOrder?.items?.map((item) => {
          return (
              `<div class="ant-col ant-col-4"><p style="font-size: 11px;">${item.name}</p></div>`
          );
        }) +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 14px; margin-top: 2px;">Price</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 14px; margin-top: 2px;">EGP ' + data?.shippingOrder?.total_price + '</p></div>' +
        '            </div>' +
        '            <div style="margin-top:-20px;margin-right: 0; padding: 0; display: block;"><h5 dir="rtl">شروط وتعليمات الشحن:</h5>'
        + '                <ul dir="rtl">' +
        '                    <li dir="rtl" style="font-size: 10px;">يتم توصيل الشحن خلال 48 ساعة من معاد تسليم الشحن</li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻳﺤﻖ ﻟﻠشرﻛﺔ الإطلاع ﻋﻠﻰ محتويات الشحنة ﻟﻠﺘﺄﻛﺪ ﻣﻦ ﻋﺪم ﻣﺨﺎﻟﻔﺘﻬﺎ' +
        '                        للائحة اﻟﺸﺤﻦ.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">تسليم اﻟﺸﺤنة ﻟﻠﻌﻤﻴﻞ اﻟﻤﺪون إﺳﻤﻪ ﻋﻠﻰ ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ وذلك بعد' +
        '                        الإطلاع على تحقيق اﻟﺸﺨﺼﻴﺔ. أو ﻣﻦ ﻳﻨﻮب ﻋﻨﻪ ﺑﺘﻔﻮﻳﺾ ﻣﻮﻗﻊ وﺻﻮرة ﺑﻄﺎﻗﺔ الرقم القومي.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">اﻟﺘﺄﻛﺪ ﻣﻦ ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻛﺎﻣﻠﺔ ﻗﺒﻞ ﻣﻐﺎدرة اﻟﻔﺮع مسئولية' +
        '                        العميل المستلم.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻣﺴﺌﻮﻟﻴﺔ اﻟﻌﻤﻴﻞ واﻟشرﻛﺔ ﻏﻴﺮ ﻣﺴﺌﻮﻟﺔ ﻗﺎﻧﻮﻧﻴﺎً ﻋﻦ' +
        '                        ﻣﺎ ﺑﺪاﺧﻞ اﻟﺸﺤنة.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">يسمح بشحن أي مأكولات أو مجمدات داخل ايس بوكس محكم الغلق.</li>' +
        '                    <li dir="rtl" style="font-size: 10px;">إستلام اﻟﺸﺤنة ﻣﻦ اﻟﻔﺮع ﺑﺤﺪ أقصى أﺳﺒﻮع وﺑﻌﺪ ذﻟﻚ تحول ﻟﻠﻔﺮع' +
        '                        اﻟﺮﺋﻴسي.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">أقصى ﻣﻴﻌﺎد لإستلام اﻟﺸﺤﻨﺔ ﺷﻬﺮ ﻣﻦ ﺗﺎرﻳﺦ إﺻﺪار ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ, و' +
        '                        48 ساعة ﻟﻠﻤﺠﻤﺪات ولا ﻳﺤﻖ ﻟﻠﻌﻤﻴﻞ ﻣﻄﺎﻟﺒﺔ اﻟشركة ﺑﻬﺎ ﺑﻌﺪ ذﻟﻚ.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻓﻲ ﺣﺎﻟﺔ اﻟﻐﺎء اﻟﺸﺤنة ﻳﺨﺼﻢ ﻣﺒﻠﻎ 15 جنيه مصري من قيمة الشحن.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﺗﻌﻮﻳﺾ اﻟﻌﻤﻴﻞ ﻓﻲ ﺣﺎﻟﺔ ﻓﻘﺪ اﻟﺸﺤﻨﺔ ﻳﻜﻮن ضعفين ﻗﻴﻤﺔ اﻟﺸﺤﻦ, ﻓﻲ' +
        '                        ﺣﺎﻟﺔ ﻋﺪم ﻣﺨﺎﻟﻔﺔ اﻟﺸﺤﻨﺔ ﻟﺘﻌﻠﻴﻤﺎت اﻟﺸﺤﻦ.' +
        '                    </li>' +
        '                    <h5 dir="rtl"> محظورات الشحن:</h5>' +
        '                    <ul dir="rtl">' +
        '                        <li dir="rtl" style="font-size: 10px;">المشروبات الكحولية وكذلك الحيوانات والطيور والأسماك' +
        '                            الحية.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">المواد القابلة للإشتعال (المواد البترولية – أسطوانات' +
        '                            الأكسجين – أسطوانات البوتجاز – العبوات المضغوطة سبراي - ...الخ).' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">المعادن الثمينة والنقود وإيصالات الأمانة والشيك لحامله.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">الكيماويات والمواد المشعة او المؤكسدة والمعدات التي بها' +
        '                            زئبق.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">الأسلحة والذخائر والمواد المتفجرة والألعاب النارية.</li>' +
        '                    </ul>' +
        '                </ul>' +
        '                <h5 dir="rtl" style="margin-top: -16px;">تتضمن هذه القوانين أمن وسلامة الأتوبيس والركاب، وعدم الإلتزام' +
        '                    بهذه الضوابط يمكن أن يضع الشخص تحت طائلة القانون.</h5></div>' +
        '        </div>' +
        '        </div>' +
        '        </div>' +
        '<div style="padding: 5px; border-top: 2px dashed;">'+
        '<div class="ant-spin-container">' +
        '    <div>' +
        '        <div style="padding-left: 50px;">' +
        '            <div class="ant-row" style="margin: 0px -10px 10px -25px;">' +
        '                <div class="ant-col ant-col-12" style="margin-bottom: 10px">' +
        '                    <img src="https://api.bluebus.com.eg/svg/logo.png" style="width: 125px; height: 25px">' +
        '                </div>' +
        '                <div class="ant-col ant-col-12"><h4 style="margin: 25px 0px 0px;">Shipping Voucher</h4></div>' +
        '' +
        '            </div>' +
        '' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Order Number</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.order_number + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Issue Date</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.created_at + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Employee</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;"> ' + data?.shippingOrder?.creatable?.name + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Store</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.store + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender Name</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.name + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver Name</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.receiver?.name + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender Phone</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.phone + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver Phone</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.receiver?.phone + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Sender National ID</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.sender?.national_id + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Receiver National ID</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">0</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">From City</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.from_location?.name_en + '</p></div>' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">To City</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 11px;">' + data?.shippingOrder?.to_location?.name_en + '</p></div>' +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 11px;">Description</h5></div>' +
        data?.shippingOrder?.items?.map((item) => {
          return (
              `<div class="ant-col ant-col-4"><p style="font-size: 11px;">${item.name}</p></div>`
          );
        }) +
        '            </div>' +
        '            <div class="ant-row" style="margin: -10px;">' +
        '                <div class="ant-col ant-col-6"><h5 style="font-size: 14px; margin-top: 2px;">Price</h5></div>' +
        '                <div class="ant-col ant-col-6"><p style="font-size: 14px; margin-top: 2px;">EGP ' + data?.shippingOrder?.total_price + '</p></div>' +
        '            </div>' +
        '            <div style="margin-top:-20px;margin-right: 0; padding: 0; display: block;"><h5 dir="rtl">شروط وتعليمات الشحن:</h5>'
        + '                <ul dir="rtl">' +
        '                    <li dir="rtl" style="font-size: 10px;">يتم توصيل الشحن خلال 48 ساعة من معاد تسليم الشحن</li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻳﺤﻖ ﻟﻠشرﻛﺔ الإطلاع ﻋﻠﻰ محتويات الشحنة ﻟﻠﺘﺄﻛﺪ ﻣﻦ ﻋﺪم ﻣﺨﺎﻟﻔﺘﻬﺎ' +
        '                        للائحة اﻟﺸﺤﻦ.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">تسليم اﻟﺸﺤنة ﻟﻠﻌﻤﻴﻞ اﻟﻤﺪون إﺳﻤﻪ ﻋﻠﻰ ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ وذلك بعد' +
        '                        الإطلاع على تحقيق اﻟﺸﺨﺼﻴﺔ. أو ﻣﻦ ﻳﻨﻮب ﻋﻨﻪ ﺑﺘﻔﻮﻳﺾ ﻣﻮﻗﻊ وﺻﻮرة ﺑﻄﺎﻗﺔ الرقم القومي.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">اﻟﺘﺄﻛﺪ ﻣﻦ ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻛﺎﻣﻠﺔ ﻗﺒﻞ ﻣﻐﺎدرة اﻟﻔﺮع مسئولية' +
        '                        العميل المستلم.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻣﺴﺌﻮﻟﻴﺔ اﻟﻌﻤﻴﻞ واﻟشرﻛﺔ ﻏﻴﺮ ﻣﺴﺌﻮﻟﺔ ﻗﺎﻧﻮﻧﻴﺎً ﻋﻦ' +
        '                        ﻣﺎ ﺑﺪاﺧﻞ اﻟﺸﺤنة.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">يسمح بشحن أي مأكولات أو مجمدات داخل ايس بوكس محكم الغلق.</li>' +
        '                    <li dir="rtl" style="font-size: 10px;">إستلام اﻟﺸﺤنة ﻣﻦ اﻟﻔﺮع ﺑﺤﺪ أقصى أﺳﺒﻮع وﺑﻌﺪ ذﻟﻚ تحول ﻟﻠﻔﺮع' +
        '                        اﻟﺮﺋﻴسي.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">أقصى ﻣﻴﻌﺎد لإستلام اﻟﺸﺤﻨﺔ ﺷﻬﺮ ﻣﻦ ﺗﺎرﻳﺦ إﺻﺪار ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ, و' +
        '                        48 ساعة ﻟﻠﻤﺠﻤﺪات ولا ﻳﺤﻖ ﻟﻠﻌﻤﻴﻞ ﻣﻄﺎﻟﺒﺔ اﻟشركة ﺑﻬﺎ ﺑﻌﺪ ذﻟﻚ.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﻓﻲ ﺣﺎﻟﺔ اﻟﻐﺎء اﻟﺸﺤنة ﻳﺨﺼﻢ ﻣﺒﻠﻎ 15 جنيه مصري من قيمة الشحن.' +
        '                    </li>' +
        '                    <li dir="rtl" style="font-size: 10px;">ﺗﻌﻮﻳﺾ اﻟﻌﻤﻴﻞ ﻓﻲ ﺣﺎﻟﺔ ﻓﻘﺪ اﻟﺸﺤﻨﺔ ﻳﻜﻮن ضعفين ﻗﻴﻤﺔ اﻟﺸﺤﻦ, ﻓﻲ' +
        '                        ﺣﺎﻟﺔ ﻋﺪم ﻣﺨﺎﻟﻔﺔ اﻟﺸﺤﻨﺔ ﻟﺘﻌﻠﻴﻤﺎت اﻟﺸﺤﻦ.' +
        '                    </li>' +
        '                    <h5 dir="rtl"> محظورات الشحن:</h5>' +
        '                    <ul dir="rtl">' +
        '                        <li dir="rtl" style="font-size: 10px;">المشروبات الكحولية وكذلك الحيوانات والطيور والأسماك' +
        '                            الحية.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">المواد القابلة للإشتعال (المواد البترولية – أسطوانات' +
        '                            الأكسجين – أسطوانات البوتجاز – العبوات المضغوطة سبراي - ...الخ).' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">المعادن الثمينة والنقود وإيصالات الأمانة والشيك لحامله.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">الكيماويات والمواد المشعة او المؤكسدة والمعدات التي بها' +
        '                            زئبق.' +
        '                        </li>' +
        '                        <li dir="rtl" style="font-size: 10px;">الأسلحة والذخائر والمواد المتفجرة والألعاب النارية.</li>' +
        '                    </ul>' +
        '                </ul>' +
        '                <h5 dir="rtl" style="margin-top: -16px;">تتضمن هذه القوانين أمن وسلامة الأتوبيس والركاب، وعدم الإلتزام' +
        '                    بهذه الضوابط يمكن أن يضع الشخص تحت طائلة القانون.</h5></div>' +
        '        </div>' +
        '        </div>' +
        '        </div>' +
        '</body>' +
        '</html>'


    const printData = [
      {
        type: 'pixel',
        format: 'html',
        flavor: 'plain',
        data: dataToPrint

      }


    ];
    qz.print(config, printData).catch((e) => {
      console.info(e)
    }).then(async () => {

      // console.info('done')

    });

  }

  return (
      <Spin spinning={loading}>
        <ListHeader>
          {data?.shippingOrder?.status?.value != 'CANCELLED' && (
              <Can
                  perform="CAN_VIEW_SHIPPING_ORDER"
                  yes={
                    <a style={{color:"white",padding:"10px",background:"#78737e",borderRadius:"10px",marginTop:"20px"}} onClick={pure} >{t("Print Order Details")}</a>

                  }
              />
          )}
        </ListHeader>
        <div ref={componentRef}>
          <div style={{padding: '35px'}}>
            <Row style={{margin: '-10px -10px 10px -25px'}}>
              <Col span={12}>
                <Logo style={{height: '25px', transform: 'translateY(50%)'}}/>
              </Col>
              <Col span={12}>
                <h4 style={{margin: '25px 0 0 0'}}>{t("Shipping Voucher")}</h4>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Order Number")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.order_number}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Issue Date")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                      'en-EG',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                  )}
                </p>
              </Col>
            </Row>

            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Employee")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.creatable?.name}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("Store")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>{data?.shippingOrder?.store}</p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender Name")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.name}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver Name")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.name}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender Phone")}               </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.phone}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver Phone")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.phone}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender National ID")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.national_id}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver National ID")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.national_id}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("From City")}
                </h5>
              </Col>
              <Col span={6}>
                {' '}
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.from_location?.name_en : data?.shippingOrder?.from_location?.name_ar}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("To City")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.to_location?.name_en : data?.shippingOrder?.to_location?.name_ar}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Description")}
                </h5>
              </Col>
              {data?.shippingOrder?.items?.map((item) => {
                return (
                    <Col span={4}>
                      <p style={{fontSize: '11px'}}>{item.name}</p>
                    </Col>
                );
              })}
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5
                    style={{
                      fontSize: '11px',
                      fontWeight: '400px',
                      marginTop: '2px',
                    }}
                >
                  {t("Price")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px', marginTop: '2px'}}>
                  EGP{data?.shippingOrder?.total_price}
                </p>
              </Col>
            </Row>
            <ShipmentRules></ShipmentRules>
          </div>
          <div style={{padding: '35px', borderTop: '2px dashed'}}>
            <Row style={{margin: '-10px -10px 10px -25px'}}>
              <Col span={12}>
                <Logo style={{height: '25px', transform: 'translateY(50%)'}}/>
              </Col>
              <Col span={12}>
                <h4 style={{margin: '25px 0 0 0'}}>{t("Shipping Voucher")}</h4>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Order Number")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.order_number}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Issue Date")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                      'en-EG',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                  )}
                </p>
              </Col>
            </Row>

            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Employee")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.creatable?.name}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("Store")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>{data?.shippingOrder?.store}</p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender Name")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.name}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver Name")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.name}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender Phone")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.phone}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver Phone")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.phone}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Sender National ID")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.sender?.national_id}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Receiver National ID")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {data?.shippingOrder?.receiver?.national_id}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("From City")}
                </h5>
              </Col>
              <Col span={6}>
                {' '}
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.from_location?.name_en : data?.shippingOrder?.from_location?.name_ar}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("To City")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.to_location?.name_en : data?.shippingOrder?.to_location?.name_ar}
                </p>
              </Col>
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>
                  {t("Description")}
                </h5>
              </Col>
              {data?.shippingOrder?.items?.map((item) => {
                return (
                    <Col span={4}>
                      <p style={{fontSize: '11px'}}>{item.name}</p>
                    </Col>
                );
              })}
            </Row>
            <Row style={{margin: -10}}>
              <Col span={6}>
                <h5
                    style={{
                      fontSize: '11px',
                      fontWeight: '400px',
                      marginTop: '2px',
                    }}
                >
                  {t("Price")}
                </h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px', marginTop: '2px'}}>
                  EGP{data?.shippingOrder?.total_price}
                </p>
              </Col>
            </Row>
            <ShipmentRules></ShipmentRules>
          </div>
        </div>
      </Spin>
  );
};

export default ShipmentDetails;
