/**Component that lists all orders (details) done by driver */
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Table, Spin } from 'antd';
import { useParams, Link } from 'react-router-dom';

import { ListHeader, PrimaryTitle, Can } from 'components';
import { GET_DRIVER } from 'services';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const DriverOrdersList = () => {
  const [page, setPage] = useState(1);
  const { driverId } = useParams();
  const [t,i18n]=useTranslation("translation");

  const { loading, data, fetchMore } = useQuery(GET_DRIVER, {
    fetchPolicy: 'network-only',
    variables: {
      id: driverId,
      first: 5,
      page: 1,
    },

    notifyOnNetworkStatusChange: true,
  });

  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'order_number',
    },
    {
      title: 'Line',
      dataIndex: 'tickets',
      key: 'line',
      render: (tickets) =>
        [...new Set(tickets.map((ticket) => ticket.trip.ref_code))].join(', '),
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
    },
    {
      title: 'Customer Phone',
      dataIndex: 'customer.phone',
    },
    {
      title: 'Order Price',
      dataIndex: 'price',
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
    },
    {
      title: 'Purchase Date',
      dataIndex: 'created_at',
    },
    {
      title: '',
      dataIndex: '',
      render: (order) => {
        return (
          <Can
            perform="VIEW_ORDER_DETAILS"
            yes={<Link to={`/order/${order.id}`}>View Details</Link>}
          />
        );
      },
    },
  ];
  //Translate Function For columns



  return (
    <Spin spinning={!data && loading}>
      {data && data.getDriver && (
        <>
          <ListHeader>
            <PrimaryTitle>{t("Driver Orders")}</PrimaryTitle>
          </ListHeader>

          <Table
            scroll={{ x: 400 }}
            dataSource={data.getDriver.orders.data.map((order, index) => {
              return {
                ...order,
                key: index,
              };
            })}
            columns={columns}
            loading={loading}
            pagination={{
              total:
                data &&
                data.getDriver &&
                data.getDriver.orders.paginatorInfo &&
                data.getDriver.orders.paginatorInfo.total,
              pageSize: 5,
              onChange: (page) => {
                setPage(page);
                fetchMore({
                  variables: {
                    page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              },
            }}
          />
        </>
      )}
    </Spin>
  );
};

export default DriverOrdersList;
