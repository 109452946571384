/**Component for viewing trip template details for a
 * certain trip template */
import React, { useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Modal, Button, Table, Row, Col, Dropdown, Spin } from 'antd';

import { Can, ListHeader, PrimaryTitle } from 'components';
import { FetchTrips, DEACTIVATE_TRIP } from 'services';
import { notify } from 'utilities';

import RouteLinesCard from './route-lines';
import TripInfoCard from './trip-info';
import TripTimeLine from './trip-timeline';
import DriversInfoCard from './drivers-info';
import BusesInfoCard from './buses-info';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const TripList = () => {
  const [visible, setVisible] = useState(false);
  const [trip, setTrip] = useState(null);
  const [page, setPage] = useState(0);
  const nameLang = i18next.language;
  const { id: tripId } = useParams();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const { data, loading, fetchMore } = useQuery(FetchTrips, {
    variables: { tripTemplateId: tripId, first: 10, page: 1 },
    notifyOnNetworkStatusChange: true,
  });
  const [deactivateTrip, { loading: refunding }] = useMutation(DEACTIVATE_TRIP);

  const handleOk = () => {
    const { id } = trip;

    deactivateTrip({
      variables: {
        id,
      },
      refetchQueries: () => [
        {
          query: FetchTrips,
          variables: {
            tripTemplateId: tripId,
            first: 10,
            page: page,
          },
        },
      ],
    })
      .then((response) => {
        const {
          data: {
            deactivateTrip: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        if (trip.seats_tickets.length > 0 || trip.has_shipping) {
          history.push(`/cancel-trip/${id}`);
        }
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', t(message));
        }
      });
    setVisible(false);
  };
  const columns = [
    {
      title: t('trip ID'),
      dataIndex: 'ref_code',
      key: 'ref_code',
    },
    {
      title: t('Datetime'),
      dataIndex: 'date',
      key: 'date',
      render: (date, row) => {
        return `${date} - ${row.time}`;
      },
    },
    {
      title: t('Bus'),
      key: 'buses',
      render: (record) => {
        const buses = record.routeLines && record.routeLines[0].bus;
        return buses ? (
          <Dropdown
            key="busesCard"
            overlay={<BusesInfoCard key="busesInfoCard" data={[buses]} />}
          >
            <Link to="#" className="ant-dropdown-link">
              {t('More Details')}
            </Link>
          </Dropdown>
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Drivers'),
      key: 'drivers',
      render: (record) => {
        const drivers = record?.routeLines && record?.routeLines[0].drivers;
        return drivers ? (
          <Dropdown
            key="driversCard"
            overlay={<DriversInfoCard key="driverInfoCard" data={drivers} />}
          >
            <Link to="#" className="ant-dropdown-link">
              {t('More Details')}
            </Link>
          </Dropdown>
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Assign bus and drivers'),
      dataIndex: '',
      key: 'bus_drivers_assignment',
      render: (trip) => {
        const AssignButton = (
          <Link to={`/driver-bus-assigning/${trip.id}/${trip.busSalon.id}`}>
            {t('Assign')}
          </Link>
        );

        return <Can perform="ASSIGN_DRIVER" yes={AssignButton} />;
      },
    },
    {
      title: t('Work Order'),
      dataIndex: '',
      key: 'work_order_details',
      render: (trip) => {
        const WorkOrderButton = (
          <Link to={`/work-order/${trip.id}`}>{t('View to Print')}</Link>
        );

        return <Can perform="VIEW_ACTIVE_TRIP_DETAILS" yes={WorkOrderButton} />;
      },
    },

    {
      title: t('Trip Status'),
      dataIndex: 'is_active',
      render : (trip) =>{
       return  trip === "Active" ?( <p>{t("Active")}</p>) : (<p>{t("Inactive")}</p>);
      } 
    },
    {
      render: (trip) => {
        const { is_active, seats_tickets, has_shipping } = trip;

        const cancelTripButton =
          is_active === 'ActiveActive' ? (
            <>
              <Button
                style={{ color: 'white', backgroundColor: 'red' }}
                onClick={() => {
                  setVisible(true);
                  setTrip(trip);
                }}
              >
                {t('Cancel Trip')}
              </Button>
            </>
          ) : is_active === 'Inactive' &&
            (seats_tickets.length > 0 || has_shipping) ? (
            <Button
              type="primary"
              onClick={() => {
                history.push(`/cancel-trip/${trip.id}`);
              }}
            >
              {t('Continue')}
            </Button>
          ) : null;

        return <Can perform="DEACTIVATE_TRIP" yes={cancelTripButton} />;
      },
    },
  ];
  //Translate Function For columns


  return (
    <Spin spinning={loading}>
      {data && data.tripTemplate && (
        <>
          <ListHeader>
            <PrimaryTitle>{t('Trip Template Details')}</PrimaryTitle>
          </ListHeader>
          <Row gutter={16}>
            <Col style={{ maxWidth: '100%', padding: '0' }} span={12}>
              <TripInfoCard
                cardTitle={t('Trip Info')}
                tripTemplate={data.tripTemplate}
              />
              <TripTimeLine
                cardTitle={t('Timeline Info (Locations)')}
                tripTemplate={data.tripTemplate}
              />
            </Col>
            <Col style={{ maxWidth: '100%', padding: '0' }} span={12}>
              <RouteLinesCard
                cardTitle={t('Routelines')}
                routeLines={data.tripTemplate.routeLines}
              />
            </Col>
          </Row>
          <ListHeader>
            <PrimaryTitle>{t('Trip Instance List')}</PrimaryTitle>
          </ListHeader>
          <Table
            scroll={{ x: 400 }}
            dataSource={data?.tripTemplate?.trips?.data?.map((trip, index) => {
              return {
                ...trip,
                key: index,
              };
            })}
            columns={columns}
            loading={loading}
            pagination={{
              total: data?.tripTemplate?.trips?.paginatorInfo?.total,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
                fetchMore({
                  variables: {
                    page: page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              },
            }}
          />
          <Modal
            visible={visible}
            title={t('Are you sure you cancel this trip?.')}
            onOk={handleOk}
            onCancel={() => {
              setVisible(false);
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setVisible(false);
                }}
              >
                {t('Cancel')}
              </Button>,
              <Button
                key="confirm"
                type="primary"
                onClick={handleOk}
                loading={refunding}
              >
                {t('Confirm')}
              </Button>,
            ]}
          >
            <p>
              {t('You are about to cancel trip')} ({trip && trip.id})
              {t('. Are you sure you want to proceed?')}
            </p>
          </Modal>
        </>
      )}
    </Spin>
  );
};

export default TripList;
