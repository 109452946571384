/** Component for listing all customers, filtering by phone
 * and adding new customers
 */
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { Table, Space, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SearchOutlined } from '@ant-design/icons';
import { GET_CASH_COLLECTION } from 'services';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import SearchCusomters from 'lists/tickets/search-customers';
import { ValidateUser } from '../../utilities';
import { cashCollectionList } from 'lists';
import { useTranslation } from 'react-i18next';

const CashoutsListContainer = styled.div``;

const CashOutList = () => {
  ValidateUser();

  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredValues, setFilteredValues] = useState({
    customerId: null,
  });
  const [t, i18n] = useTranslation("translation");
  const { data, loading, refetch } = useQuery(GET_CASH_COLLECTION, {
    variables: {
      page: 1,
      first: 6,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
    },
    {
      title: 'Printed at',
      dataIndex: 'printed_at',
    },
    {
      title: 'User ID',
      key: 'id',
      render: (user) => `${user.id}`,
    },
    {
      title: 'Username',
      dataIndex: ['user', 'name'],
    },
    {
      title: 'User phone',
      dataIndex: ['user', 'phone'],
    },
    {
      title: 'Driver id',
      dataIndex: ['driver', 'id'],
    },
    {
      title: 'Driver name',
      dataIndex: ['driver', 'name'],
    },
    {
      title: 'Driver phone',
      dataIndex: ['driver', 'phone'],
    },
    {
      title: 'Platform id',
      dataIndex: ['station', 'id'],
    },
    {
      title: 'Platform name',
      dataIndex: ['shift', 'shift_log', 'shift', 'name'],
    },
    {
      title: 'Platform code',
      dataIndex: ['shift', 'shift_log', 'shift', 'code'],
    },
    {
      title: 'Shift id',
      dataIndex: ['shift_id'],
    },
    {
      title: 'Station id',
      dataIndex: ['station', 'id'],
    },
    {
      title: 'Station name',
      dataIndex: ['station', 'name_en'],
    },
    {
      title: 'Actions',
      render: (cashCollection) => {
        console.log(cashCollection);
        const { id } = cashCollection;
        console.log(id);
        return (
          <space size="middle">
            <a target="_blank" href={`/cashCollection/view/${id}`}>
              {t("Print")}
            </a>
          </space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setFilteredValues({
      ...filteredValues,
      customerId: filters.phone,
    });
    refetch({
      page: pagination.current,
      customerId: filters.phone || undefined,
    });
  };
  /*  const rowSelection = {
    onChange: (selectedRowKeys) => {
      if (selectedRows.indexOf(selectedRowKeys) === -1) {
        setSelectedRows([...selectedRows, selectedRowKeys]);
      } else {
        selectedRows.splice(selectedRows.indexOf(selectedRowKeys), 1);
      }
    },
  };*/
  const handlePrintCashCollectionBill = () => {
    if (selectedRows.length > 0) {
      let cashCollectionBills = [...new Set(selectedRows.flat())].join('&');
      window.open('/cashCollection/view/' + cashCollectionBills);
    }
  };

  return (
    <CashoutsListContainer>
      <ListHeader>
        <PrimaryTitle>{t("Cash Collection")}</PrimaryTitle>
        <Space size="middle">
          {/*   <Can
            perform="PRINT_TICKETS"
            yes={
              <Button
                type="primary"
                onClick={() => handlePrintCashCollectionBill()}
              >
                Print Cash In bill
              </Button>
            }
          />*/}
        </Space>
      </ListHeader>
      <Table
        bordered
        dataSource={data?.cashCollection?.data}
        columns={columns}
        scroll={{ x: 400 }}
        loading={loading}
        onChange={onChange}
        rowKey="id"
        /* rowSelection={{
          ...rowSelection,
        }}*/
        pagination={{
          total: data?.cashCollection?.paginatorInfo?.total,
          pageSize: 6,
          showSizeChanger: false,
        }}
      />
    </CashoutsListContainer>
  );
};

export default CashOutList;
