import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Table,
  Space,
  Button,
  Modal,
  Skeleton,
  Form,
  Input,
  Popconfirm,
  DatePicker,
  Select,
  Spin,
  Divider,
  Alert,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SearchOutlined } from '@ant-design/icons';

import { GET_TICKET_DATA } from 'services';
import { notify, formatDate } from 'utilities';
import { ListHeader, PrimaryTitle, Can } from 'components';
import SearchCusomters from './search-customers';
import { ValidateUser } from '../../utilities/ValidateUser';
import { CurrentDateTime } from '../../utilities/current_date';
import { TimeToStartTrip } from '../../utilities/timeToStartTrip';
import { isMobile } from 'react-device-detect';
import SEND_DAILY_REPORT from '../../services/tickets/send_daily_report_query';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;
const ButtonWrapper = styled.div`
  button.ant-btn {
    height: 18px;
    padding: 0;
  }
`;
const { Item } = Form;
const TicketsListContainer = styled.div`
  .ant-form-item {
    margin: 10px !important;
  }
`;

const TicketsList = (props) => {
  ValidateUser();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;

  const { data, loading, refetch, error } = useQuery(GET_TICKET_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: props.id,
    },
  });
  const expandedColumns = [
    {
      title: t('Seat Number'),
      dataIndex: 'seat_number',
    },
    {
      title: t('Seat Class'),
      dataIndex: ['seat_type',nameLang === "en" ? 'name_en' : 'name_ar'],
    },
    {
      title: t('Trip Code'),
      dataIndex: ['trip', 'ref_code'],
    },
    {
      title: t('Trip Date'),
      dataIndex: ['trip', 'date'],
    },
    {
      title: t('From Location'),
      dataIndex: ['from_location', nameLang === "en" ? 'name_en' : 'name_ar'],
    },
    {
      title: t('To Location'),
      dataIndex: ['to_location', nameLang === "en" ? 'name_en' : 'name_ar'],
    },
    {
      title: t('Trip Execute'),
      dataIndex: ['from_date'],
    },
    {
      title: t('Departure Time'),
      dataIndex: ['from_time'],
    },
    {
      title: t('Modified_1'),
      render: (ticket) => {
        if (ticket?.updated_to !== null)
          return (
            <Link
              to={`/ticket/${ticket?.updated_to?.id}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              {ticket?.updated_to?.id}
            </Link>
          );

        return ticket?.new_tickets?.map((newTicket, index) => (
          <>
            <Divider type="vertical" />
            <Link
              to={`/ticket/${newTicket.id}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              {newTicket?.id}
            </Link>
          </>
          // <p key={index}>{newTicket.id}</p>
        ));
      },
    },
    {
      title: t('Shift ID'),
      dataIndex: ['station_shift', 'id'],
    },
    {
      title: t('Source'),
      dataIndex: ['order', 'source'],
    },
  ];

  return (
    <>
      <Table
        bordered
        loading={loading}
        columns={expandedColumns}
        dataSource={data !== undefined && [data?.getticketdata]}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default TicketsList;
