import React from 'react';
import { Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { ValidateUser } from 'utilities';
import { useParams } from 'react-router-dom';
import { GET_CREDITS_TRANSACTIONS } from 'services';
import { PrimaryTitle } from 'components';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import {GET_AGENT_LOGS} from "../../services/agent/get-agents";

const SegmentWrapper = styled.div`
  padding-top: 15px;
`;

export  const ViewAgentLogs = () => {
  ValidateUser();
  const { id } = useParams();
  const [t,i18n]=useTranslation("translation");
  const { data, loading,fetchMore } = useQuery(GET_AGENT_LOGS, {
    variables: {
      agent_id: id,
      first:5
    },
  });
  const transactionsColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Transaction',
      render: (agent)=>{ return <span title={agent.transaction}>transaction</span>},

    },

  ];

  //Translate Function For columns
  const ti81n = (columns) =>
    columns.map((ele) => {
      ele.title = t(ele.title);
    });
  ti81n(transactionsColumns);


  return (
    <SegmentWrapper>
      {data?.agentLogs?.data ? (
        <>
          <PrimaryTitle>{t("Job Lists")}</PrimaryTitle>
          <Table
            style={{ paddingBottom: '20px' }}
            scroll={{ x: 400, y: 200 }}
            dataSource={data?.agentLogs?.data}
            columns={transactionsColumns}
            loading={loading}
            rowKey="id"
            bordered
            pagination={{
              total: data?.agentLogs?.paginatorInfo?.total,
              pageSize: 5,
              showSizeChanger: false,
              onChange: (page) => {
                fetchMore({
                  variables: {
                    page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) =>
                      fetchMoreResult ? fetchMoreResult : prev,
                });
              },
            }}
          />
        </>
      ) : null}



    </SegmentWrapper>
  );
};

