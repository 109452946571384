import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Space, Table, Form, Button } from 'antd';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import { notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';
import { GET_JOBS } from '../../services/jobs';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const JobListContainer = styled.div``;

const JobList = () => {
  ValidateUser();
  const [t, i18n] = useTranslation("translation");
  const [page, setPage] = useState(1);
  const { data, loading, fetchMore, refetch } = useQuery(GET_JOBS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'status',
      render: (job) => {
        if (job?.link) return t('Ended');
        else return t('Started');
      },
    },
    {
      title: 'user',
      dataIndex: 'user',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
    },
    {
      title: 'model',
      dataIndex: 'model',
    },
    {
      title: 'link',
      render: (job) => {
        if (job?.link)
          return (
            <a href={job?.link} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          );
        else return t('processing');
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <JobListContainer>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>{t('Job List')}</PrimaryTitle>
        <PrimaryButton onClick={() => refetch({ page: page })}>
          {t('refresh')}
        </PrimaryButton>
      </ListHeader>
      <Table
        bordered
        scroll={{ x: 400 }}
        dataSource={data?.listJobs?.data}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.listJobs?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
    </JobListContainer>
  );
};

export default JobList;
