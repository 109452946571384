import React, { useState } from 'react';
import { Button, Space, Table } from 'antd';
import { CASH_OTHER_FEES } from 'services';
import { useQuery } from '@apollo/react-hooks';
import { ValidateUser } from 'utilities';
import { Can, ListHeader, PrimaryTitle } from '../../components';
import { useTranslation } from 'react-i18next';
const ViewCashOtherFees = () => {
  ValidateUser();
  const [t, i18n] = useTranslation("translation");

  const { data, loading, fetchMore, refetch, error } = useQuery(
    CASH_OTHER_FEES,
    {
      variables: {
        first: 5,
        page: 1,
      },
    }
  );
  const [page, handlePageChange] = useState(1);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Username',
      dataIndex: ['user', 'name'],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Action',
      // dataIndex: 'action',
      render:(record)=> t(record.action),
    },
    {
      title: 'Entity',
      dataIndex: 'entity_type',
      render: (item) => {
        let entity = item.split('\\');
        return t(entity[entity.length - 1]);
      },
    },
    {
      title: 'Entity ID',
      key: 'entity_id',
      render: (item) => {
        return `${item.entity_id}`;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Other Cash Fees')}</PrimaryTitle>
      </ListHeader>
      <Table
        bordered
        dataSource={data?.getCustomersExtraFees?.data}
        columns={columns}
        scroll={{ x: 400 }}
        loading={loading}
        rowKey="id"
        pagination={{
          total:
            data &&
            data.getCustomersExtraFees &&
            data.getCustomersExtraFees.paginatorInfo &&
            data.getCustomersExtraFees.paginatorInfo.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
            fetchMore({
              variables: {
                page: page,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              },
            });
          },
        }}
      />
    </>
  );
};

export default ViewCashOtherFees;
