/**Display a model for enetering the ticket number
 * that is required to complete the cashout process
 */
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Modal, Input } from 'antd';

import { CONFIRM_CASHOUT } from 'services';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const ConfirmModal = ({ visible, setVisible, cashoutId, refetch, page }) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;

  const [confirmCashout, { loading }] = useMutation(CONFIRM_CASHOUT);

  const onOk = () => {
    form.validateFields().then((values) => {
      const { ticketNumber } = values;
      confirmCashout({
        variables: {
          id: cashoutId,
          ticket_code: ticketNumber,
        },
      })
        .then((response) => {
          const {
            data: {
              cashoutConfirm: { message, status },
            },
          } = response;
          const notificationType = status ? 'success' : 'error';
          if (status) {
            refetch({ page: page });
            notify(notificationType,  nameLang === "en" ? message : t("cashout_confirmed"));
            setVisible(false);
            form.resetFields();
          } else {
            notify(notificationType, nameLang === "en" ? message : t("invalid_ticket_num"));
          }
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={t("Confirm Cashout")}
      okText={t('Ok')}
      cancelText={t('Cancel')}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Form  form={form} name="confirm_cashout_form">
        <Item
          style={{ padding: '20px 0', margin: 0 }}
          name="ticketNumber"
          label={t("Ticket Number")}
          rules={[
            {
              required: true,
              message: t('Please, enter the ticket number!'),
            },
            {
              pattern: new RegExp('^[0-9-]+$'),
              message: t('Only numbers are allowed!'),
            },
          ]}
        >
          <Input size="large" placeholder="e.g. 0123456789" />
        </Item>
      </Form>
    </Modal>
  );
};

export default ConfirmModal;
