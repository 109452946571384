/**Component that list all available bus classes in the system */
import React from 'react';
import { Table, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { AllBusClasses } from 'services';
import { useHistory } from 'react-router-dom';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const columns = [
  {
    title: 'English Name',
    dataIndex: 'name_en',
    key: 'name_en',
  },
  {
    title: 'Arabic Name',
    dataIndex: 'name_ar',
    key: 'name_ar',
  },
  {
    title: 'Assigned Imported Types',
    dataIndex: 'busTypes',
    key: 'busTypes',
    render: (busTypes) => {
      return busTypes.map((busType) => busType.name).join(', ');
    },
  },
];

const BusClassList = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const { data, loading } = useQuery(AllBusClasses, {});
  return (
    <Spin spinning={loading}>
      <ListHeader>
        <PrimaryTitle>Bus Classes</PrimaryTitle>
        <Can
          perform="CREATE_BUS_CLASSES"
          yes={
            <PrimaryButton
              onClick={() => {
                history.push('/bus-class/new');
              }}
            >
              {t('Add New')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <Table
        dataSource={
          data
            ? data.allBusClasses.map(({ id, ...busClass }) => {
                return {
                  ...busClass,
                  key: id,
                };
              })
            : []
        }
        columns={columns}
        pagination={false}
        scroll={{ x: 400 }}
      />
    </Spin>
  );
};

export default BusClassList;
