import React from 'react';
import { Tabs, Spin, Table } from 'antd';
import { SearchTrips } from 'services';

import { useQuery } from '@apollo/react-hooks';
import { notify } from '../../../utilities';
import { useTranslation } from 'react-i18next';

const TabPane = Tabs.TabPane;

const Trips = ({
  setCurrentTrip,
  showModal,
  formValues,
  setRounded,
  formValues: { from: fromLocationId, to: toLocationId },
}) => {
  const [t, i18n] = useTranslation("translation");
  const departureQueryValues = {
    ...formValues,
    trip_date: formValues.trip_date.format('YYYY-MM-DD'),
    admin_search: true,
    get_suggestions: false,
  };

  const hasRoundTrip = formValues.round_trip_date ? true : false;
  if (hasRoundTrip) {
    departureQueryValues.round_trip_date = formValues.round_trip_date.format(
      'YYYY-MM-DD'
    );
  }

  const roundTripValues = (({ round_trip_date, from, to }) => ({
    trip_date: round_trip_date,
    from: to,
    to: from,
    get_suggestions: false,
  }))(departureQueryValues);

  const { loading, error, data } = useQuery(SearchTrips, {
    variables: departureQueryValues,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: roundTripData } = useQuery(SearchTrips, {
    variables: roundTripValues,
    skip: !hasRoundTrip,
  });

  if (loading) return <Spin />;
  if (error) return `Error! ${error.message}`;
  const changeTab = (activeKey) => {
    if (activeKey == 1) {
      setRounded(false);
    }
    if (activeKey == 2) {
      setRounded(true);
    }
  };
  const columns = (fromLocationId, toLocationId, rounded) => [
    {
      title: t('Trip Code'),
      dataIndex: '',
      render: (trip) => (
        <a
          href="#!"
          onClick={() => {
            const groupTicketId =
              trip.routeLine &&
              `${trip.routeLine.id}_${fromLocationId}_${toLocationId}`;
            setCurrentTrip({
              ...trip,
              groupTicketId,
            });
            showModal(trip);
          }}
        >
          {trip.ref_code}
        </a>
      ),
    },
    { 
      title: t('Date'),
 
      dataIndex: 'fromDate',
    },
    {
      title: t('From Time'),
      dataIndex: 'fromTime',
    },
    {
      title: t('To Time'),
      dataIndex: 'toTime',
    },
    {
      title: t('Bus Salon'),
      dataIndex: 'busSalon.name',
    },
    {
      title: t('Total Seats'),
      dataIndex: 'total_seats_count',
    },
    {
      title: t('Hold Seats'),
      dataIndex: 'hold_seats_count',
    },
    {
      title: t('Booked Seats'),
      dataIndex: 'booked_seats_count',
    },
    {
      title: t('Available Seats'),
      dataIndex: 'available_seats_count',
    },
    {
      title: t('Occupancy Percentage'),
      key: 'occupancyPercentage',
      render: (record) => {
        return <span>{`${Math.round(record.occupancy_percentage)}%`}</span>;
      },
    },
  ];

  return (
    <Tabs defaultActiveKey="1" onChange={changeTab}>
      <TabPane tab={t('Departure Trips')} key={1}>
        <Table
          scroll={{ x: 400 }}
          dataSource={(data && data.searchTrips.trips
            ? data.searchTrips.trips
            : []
          ).map((trip, index) => {
            let fromLocationName = '';
            let toLocationName = '';
            trip.locations.find(({ id, name_en }) => {
              if (id === fromLocationId) fromLocationName = name_en;
              if (id === toLocationId) toLocationName = name_en;
            });
            // this is done to view the trip date instead of the trip execute in the case of two days trips over three cities
            if (trip.date < formValues.trip_date.format('YYYY-MM-DD'))
              trip.date = formValues.trip_date.format('YYYY-MM-DD');
            return {
              ...trip,
              fromLocationName: fromLocationName,
              toLocationName: toLocationName,
              key: index,
            };
          })}
          columns={(() => columns(fromLocationId, toLocationId, false))()}
          loading={loading}
          pagination={{
            pageSize: 5,
          }}
        />
      </TabPane>
      <TabPane disabled={!hasRoundTrip} tab={t('Return Trips')} key={2}>
        <Table
          scroll={{ x: 400 }}
          dataSource={(roundTripData && roundTripData.searchTrips.trips
            ? roundTripData.searchTrips.trips
            : []
          ).map((trip, index) => {
            return {
              ...trip,
              key: index,
            };
          })}
          columns={(() => columns(toLocationId, fromLocationId, true))()}
          loading={loading}
          pagination={{
            pageSize: 5,
          }}
        />
      </TabPane>
    </Tabs>
  );
};

export default Trips;
