import { handleGraphQlError, notify } from 'utilities';
import { useQuery } from '@apollo/react-hooks';
import { me } from '../services';
import { useTranslation } from 'react-i18next';

export const ValidateUser = () => {
  const {t,i18n}= useTranslation("translation");
  useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onError: (err) => {
      if (err['graphQLErrors'][0]?.extensions?.validation) {
        const {
          extensions: { code, validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', t(message));
        }
      }
      localStorage.clear();
      window.location.reload();
    },
  });
};
