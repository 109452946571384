import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Divider,
  Select,
  Empty,
  Spin,
  InputNumber,
  Input,
} from 'antd';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  allPermissions,
  ASSIGN_PARTNERS,
  GET_SEGMENTS,
  SEARCH_USERS,
} from 'services';
import { useDebounce, notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';
import { AuthContext } from 'authContext';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

const PermissionListContainer = styled.div``;

const PermissionList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [form] = Form.useForm();
  const authContext = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [permission, setPermission] = useState(0);
  const [visible, setVisible] = useState(false);
  const [creationFormVisible, setCreationFormVisible] = useState(false);
  const [editFormVisible, setEditFormVisible] = useState(false);
  const createPermissionMutation = gql`
    mutation Createpermission($name: String!) {
      Createpermission(name: $name) {
        status
        message
      }
    }
  `;
  const editPermissionMutation = gql`
    mutation Updatepermission($name: String!, $id: ID!) {
      Updatepermission(name: $name, id: $id) {
        status
        message
      }
    }
  `;
  const [createPermission] = useMutation(createPermissionMutation);
  const [updatePermission] = useMutation(editPermissionMutation);
  const { data, loading, fetchMore, refetch } = useQuery(allPermissions, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },

    {
      title: 'Actions',
      render: (permission) => {
        // open model for adding credit to selected segment
        const EditButton = (
          <Button
            style={{ height: 18, padding: 0 }}
            type="link"
            onClick={() => {
              setPermission(permission);
              setEditFormVisible(true);
            }}
          >
            {t('Edit')}
          </Button>
        );

        return (
          <Space>
            <Can perform="CREAT_PERMISSION" yes={EditButton} />
          </Space>
        );
      },
    },
  ];

  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const onCancel = () => {
    setCreationFormVisible(false);
    setEditFormVisible(false);
  };

  const onSubmit = (values) => {
    const { name } = values;
    let correctName = name.split(' ').join('_');

    createPermission({
      variables: {
        name: correctName,
      },
    })
      .then(function (res) {
        if (res.data?.Createpermission?.status === true)
          notify('success', res.data?.Createpermission?.message);
        refetch();
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });

    setCreationFormVisible(false);
  };
  const onUpdate = (values) => {
    const { name } = values;
    let correctName = name.split(' ').join('_');
    updatePermission({
      variables: {
        name: correctName,
        id: permission.id,
      },
    })
      .then(function (res) {
        if (res.data?.Updatepermission?.status === true)
          notify('success', res.data?.Updatepermission?.message);
        refetch();
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });

    setEditFormVisible(false);
  };

  return (
    <PermissionListContainer>
      <Modal
        destroyOnClose
        visible={creationFormVisible}
        title={t('Create User Permission')}
        okText={t('Create')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        // confirmLoading={assigning}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="partners_form"
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            label={t('Name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('Please, enter a valid name!'),
              },
            ]}
          >
            <Input></Input>
          </Item>
        </Form>
      </Modal>
      <Modal
        destroyOnClose
        visible={editFormVisible}
        title={t('Update User Permission ') + permission.name}
        okText={t('Update')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        // confirmLoading={assigning}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onUpdate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="partners_form"
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            label={t('Name')}
            name="name"
            rules={[
              {
                required: true,
                message: 'Please, enter a valid a new name !',
              },
            ]}
          >
            <Input defaultValue={permission.name}></Input>
          </Item>
        </Form>
      </Modal>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>
          {t('Permission List')}
        </PrimaryTitle>

        <Can
          perform="CREAT_PERMISSION"
          yes={
            <PrimaryButton onClick={() => setCreationFormVisible(true)}>
              {t('Create Permission')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.allPermissions.data}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.allPermissions?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
    </PermissionListContainer>
  );
};

export default PermissionList;
