import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Divider, Spin } from 'antd';
import { CreateCustomer } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { notify } from '../../utilities';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const CreateCustomerForm = ({
  form: { getFieldDecorator, validateFields },
}) => {
  const [loginErrors, handleLoginErrors] = useState({});
  const [t,i18n]=useTranslation("translation");

  const handleCreateCustomer = (e, createCustomer) => {
    e.preventDefault();

    validateFields(['phone', 'name'], (err, values) => {
      if (!err) {
        const { name, phone } = values;
        createCustomer({
          variables: {
            name,
            phone,
          },
        });
      }
    });
  };

  return (
    <>
      <Mutation
        mutation={CreateCustomer}
        onError={(err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        }}
      >
        {(createCustomer, { loading, error, data, called }) => {
          if (loading) return <Spin />;
          return (
            <>
              <ListHeader>
                <PrimaryTitle>{t("Create Customer")}</PrimaryTitle>
              </ListHeader>
              <Divider />
              <Form
                layout={isMobile ? 'horizontal' : 'inline'}
                onSubmit={(e) => handleCreateCustomer(e, createCustomer)}
              >
                <Form.Item
                  label={t("Name")}
                  {...(loginErrors.name && {
                    help: loginErrors.name,
                    validateStatus: 'error',
                  })}
                >
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: t('Please enter the customer name!'),
                        pattern: new RegExp(/^[^0-9][\u0621-\u064Aa-zA-Z\-_\s\d]+$/)
                      },
                    ],
                  })(<Input placeholder={t("name")} />)}
                </Form.Item>
                <Form.Item
                  label={t("Phone Number")}
                  {...(loginErrors.phone && {
                    help: loginErrors.phone,
                    validateStatus: 'error',
                  })}
                >
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: t('Please enter the customer phone number!'),
                        pattern: new RegExp('^[0-9]{7,12}$'),
                      },
                    ],
                  })(<Input placeholder={t("phone number")} />)}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("Create")}
                  </Button>
                </Form.Item>
              </Form>
              {called && !loading && !error && data && (
                <Redirect to={{ pathname: '/customers' }} />
              )}
            </>
          );
        }}
      </Mutation>
    </>
  );
};

const CreateCustomerFormWrapper = Form.create({})(CreateCustomerForm);

export default CreateCustomerFormWrapper;
