/**Component for cancelling trip from trip instances menu item for a certain trip
 * Displays a list of passengers on a current cancelled trip
 * Contain 2 actions (replace or refund) ticket for a certain passenger
 */
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Modal, Button, Table, Spin, List, Card, Checkbox } from 'antd';

import { ListHeader, PrimaryTitle, Can } from 'components';
import {
  REFUND_TICKET,
  GET_ACTIVE_LOCATIONS,
  GET_ROUTELINES_SHIPMENTS,
  getCancelledTrip,
  MultipleReplaceTickets,
} from 'services';
import { notify } from 'utilities';
import ReplaceModal from './replace-modal';
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const CancelTrip = () => {
  const [visible, setVisible] = useState(false);
  const [showReplace, setShowReplace] = useState(false);
  const [showMultipleReplace, setShowMultipleReplace] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);
  const { tripId } = useParams();
  const [t,i18n]=useTranslation("translation");


  const { data: allLocationsData, loading: allLocationsLoading } = useQuery(
    GET_ACTIVE_LOCATIONS
  );
  const { data, loading, refetch } = useQuery(getCancelledTrip, {
    variables: { id: tripId },
    notifyOnNetworkStatusChange: true,
  });

  const [refundTicket, { loading: refunding }] = useMutation(REFUND_TICKET);
  const [multipleReplace, { loading: multipleReplaceLoading }] = useMutation(
    MultipleReplaceTickets
  );

  const { data: shipments, loading: shipmentsLoading } = useQuery(
    GET_ROUTELINES_SHIPMENTS,
    {
      variables: {
        routeLinesIds: data?.getTrip?.routeLines.map((route) => {
          return parseInt(route.id);
        }),
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  /**Confirm refunding the ticket for passenger */
  const handleOk = (e, id) => {
    refundTicket({
      variables: {
        id,
      },
      refetchQueries: () => [
        {
          query: getCancelledTrip,
          variables: {
            id: tripId,
          },
        },
      ],
    })
      .then((response) => {
        const {
          data: {
            refundTicket: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };
  const handleMultipleReplace = (selectedTrip, from, to) => {
    console.info(from, to, selectedTrip);
    multipleReplace({
      variables: {
        tickets: selectedRows,
        trip_id: selectedTrip,
        from,
        to,
      },
      refetchQueries: () => [
        {
          query: getCancelledTrip,
          variables: {
            id: tripId,
          },
        },
      ],
    })
      .then((response) => {
        setSelectedRows([]);
        setShowMultipleReplace(false);
        const {
          data: {
            multipleReplaceTickets: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        window.location.reload();
      })
      .catch((err) => {
        console.info(err);
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };
  const handleCheckbox = (record) => {
    if (selectedRows.length === 0) setSelectedRows([record.id]);
    else {
      if (selectedRows.includes(record.id)) {
        selectedRows.splice(selectedRows.indexOf(record.id), 1);
      } else setSelectedRows([...selectedRows, record.id]);
    }
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
      title: 'Select',
      render: (text, record, index) => {
        return (
          <Checkbox
            style={{ marginLeft: '30px' }}
            onChange={(e) => {
              handleCheckbox(record);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: 'C Name',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Phone',
      dataIndex: ['customer', 'phone'],
    },
    ,
    {
      title: 'Ticket code',
      dataIndex: 'code',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Payment method',
      dataIndex: 'payment_method',
    },
    {
      title: 'Seat #',
      dataIndex: 'seat_number',
    },
    ,
    {
      title: 'Seat Type',
      dataIndex: ['seat_type', 'name_en'],
    },
    ,
    {
      title: 'time',
      dataIndex: 'from_time',
    },
    {
      title: 'date',
      dataIndex: 'from_date',
    },
    {
      title: 'From',
      dataIndex: ['from_location', 'name_en'],
    },
    {
      title: 'To',
      dataIndex: ['to_location', 'name_en'],
    },
    {
      title: 'Ticket Status',
      dataIndex: 'status',
    },
    {
      title: 'Refund Payment',
      dataIndex: '',
      render: (ticket) => {
        return ticket.status === 'Paid' ? (
          <Can
            perform="REFUND_TICKET"
            yes={
              <Button
                onClick={() => {
                  setVisible(true);
                  setTicketInfo(ticket);
                }}
              >
                {t("Refund")}
              </Button>
            }
          />
        ) : (
          <p>{t("Cannot Refund Pending Ticket")}</p>
        );
      },
    },
    {
      title: 'Replace Ticket',
      dataIndex: '',
      render: (ticket) => {
        return ticket.status === 'Paid' ? (
          <Can
            perform="REPLACE_TICKET_ORDER"
            yes={
              <Button
                onClick={() => {
                  setShowReplace(true);
                  setTicketInfo(ticket);
                }}
              >
                {t("Replace Ticket")}
              </Button>
            }
          />
        ) : (
          <p>{t("Cannot Replace Pending Ticket")}</p>
        );
      },
    },
  ];

  //Translate Function For columns
const ti81n = columns.map((ele) => {
  ele.title = t(ele.title);
  });


  const shipmentsColumns = [
    {
      title: t('Id'),
      dataIndex: 'id',
    },
    {
      title: t('Order Number'),
      dataIndex: 'order_number',
    },
    {
      title: t('Trip Id'),
      dataIndex: ['trip_route_line', 'trip', 'id'],
    },
    {
      title: t('Departure Station'),
      dataIndex: ['from_location', 'name_en'],
    },
    {
      title: t('Arrival Station'),
      dataIndex: ['to_location', 'name_en'],
    },
    {
      title: t('Status'),
      dataIndex: ['status', 'label'],
    },
  ];
  const showMultipleReplaceModal = () => {
    if (selectedRows.length < 1) {
      notify('error', 'Select Tickets First !');
      return;
    }
    setShowMultipleReplace(true);
  };

  return (
    <Container>
      <ListHeader>
        <PrimaryTitle>{t("Passengers List")}</PrimaryTitle>

        <Card title="Trip Details" style={{ width: 300, margin: '10px' }}>
          <p>{t("ref code: ")}{data && data.getTrip.ref_code}</p>
          <p>{t("Updated at: ")}{data && data.getTrip.updated_at}</p>
        </Card>
      </ListHeader>

      <Modal
        visible={visible}
        title="Are you sure you want to refund this amount?."
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
            }}
          >
            {t("Cancel")}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={(e) => handleOk(e, ticketInfo && ticketInfo.id)}
          >
            {t("Confirm")}
          </Button>,
        ]}
      >
        {ticketInfo && (
          <p>
            {t("You are about to refund an amount of")} (
            {ticketInfo.status === 'Pending' ? 0 : ticketInfo.price}) {t("EGP to")}
            {t("passenger")} ({ticketInfo.customer.name}). {t("Please, confirm your action!")}
          </p>
        )}
      </Modal>

      <Spin spinning={loading || refunding}>
        <button
          style={{ marginTop: '10px' }}
          onClick={showMultipleReplaceModal}
          type="primary"
          className={'ant-btn ant-btn-primary apply'}
        >
          {t("Multiple Replace")}
        </button>
        {data &&
          data.getTrip &&
          data.getTrip.grouped_tickets &&
          data.getTrip.grouped_tickets.map((tickets) => (
            <Table
              style={{ marginTop: '10px' }}
              dataSource={tickets}
              columns={columns}
              loading={loading}
              pagination={false}
            />
          ))}
        {shipments &&
          shipments.getTripShipments &&
          shipments.getTripShipments.length > 0 && (
            <div>
              <ListHeader>
                <PrimaryTitle>Shipments List</PrimaryTitle>
              </ListHeader>
              <Table
                scroll={{ x: 400 }}
                dataSource={shipments.getTripShipments}
                columns={shipmentsColumns}
                loading={shipmentsLoading}
                pagination={false}
              />
            </div>
          )}
      </Spin>
      {data && ticketInfo && allLocationsData && (
        <ReplaceModal
          visible={showReplace}
          setVisible={setShowReplace}
          ticket={ticketInfo}
          allLocations={allLocationsData.activeLocations}
          getTrip={getCancelledTrip}
          refetchTrip={refetch}
          trip_Id={tripId}
          templateId={data.getTrip.trip_template.id}
          remainingTickets={1}
        ></ReplaceModal>
      )}
      {data && allLocationsData && showMultipleReplace && (
        <ReplaceModal
          visible={showMultipleReplace}
          setVisible={setShowMultipleReplace}
          ticket={ticketInfo}
          allLocations={allLocationsData.activeLocations}
          getTrip={getCancelledTrip}
          refetchTrip={refetch}
          trip_Id={tripId}
          templateId={data.getTrip.trip_template.id}
          remainingTickets={1}
          multiple={true}
          handleMultipleReplace={handleMultipleReplace}
          multipleReplaceLoading={multipleReplaceLoading}
        ></ReplaceModal>
      )}
    </Container>
  );
};

export default CancelTrip;
