import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  ProviderItems,
  CreatProviderItem,
  UpdateProviderItem,
  GetAccesories,
  GetTypes,
  GetProvider,
} from 'services/providers';
import { GET_ACTIVE_LOCATIONS, GET_USERS } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import { Table, Form, Button, Modal, Input, Select } from 'antd';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const Items = () => {
  const { t } = useTranslation('assets');
  const [createProviderItemVisiable, SetCreateProviderItemVisiable] = useState(
    false
  );
  const [UpdateItemsVisible, SetUpdateItemsVisible] = useState(false);
  const { data, loading, fetchMore, refetch } = useQuery(ProviderItems, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: usersdata } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 1000,
      page: 1,
    },
  });
  const { data: accessories } = useQuery(GetAccesories, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: locations } = useQuery(GET_ACTIVE_LOCATIONS);
  const { activeLocations } = locations || { activeLocations: [] };
  const [page, setPage] = useState(1);
  const [updateProviderItem, { loading: updateLoading }] = useMutation(
    UpdateProviderItem
  );
  const [createProviderItem, { loading: creating }] = useMutation(
    CreatProviderItem
  );
  const { data: typesData } = useQuery(GetTypes, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: providerData } = useQuery(GetProvider, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [updateForm] = Form.useForm();
  const [createForm] = Form.useForm();

  const accessoriesArray = accessories && accessories['accessories']['data'];
  const accesoriesOptions = [];
  for (let i = 0; i < accessoriesArray?.length; i++) {
    accesoriesOptions.push({
      label: accessoriesArray[i]?.name,
      value: accessoriesArray[i]?.id,
    });
  }
  const columns = [
    {
      title: t('items.columns.id'),
      dataIndex: 'id',
    },
    {
      title: t('items.columns.code'),
      dataIndex: 'code',
    },
    {
      title: t('items.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('items.columns.user'),
      dataIndex: ['user', 'name'],
    },
    {
      title: t('items.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('items.columns.createdAt'),
      dataIndex: 'created_at',
    },
    {
      title: t('items.columns.provider'),
      dataIndex: ['provider', 'name'],
    },
    {
      title: t('items.columns.monthesOfDep'),
      dataIndex: 'months_of_depreciation',
    },
    {
      title: t('items.columns.location'),
      dataIndex: 'location',
    },
    {
      title: t('items.columns.type'),
      dataIndex: ['item_type', 'name'],
    },
    {
      title: t('items.columns.itemPrice'),
      dataIndex: 'price',
    },
    {
      title: t('items.columns.accessories'),
      dataIndex: 'accessories',
      render: (accessories) => (
        <>
          {accessories.map((acc) => (
            <p>{acc.name}</p>
          ))}
        </>
      ),
    },
    {
      title: t('items.columns.accessoriesPrice'),
      dataIndex: 'accessories_price',
    },
    {
      title: t('items.columns.soldAccessoriesPrice'),
      dataIndex: 'sold_accessories_price',
    },
    {
      title: t('items.columns.totalCost'),
      dataIndex: 'total_cost',
    },
    {
      title: t('items.columns.depreciationYear'),
      dataIndex: 'current_year_depreciation',
    },
    {
      title: t('items.columns.depreciationPreviousYear'),
      dataIndex: 'Previous_years_depreciation',
    },
    {
      title: t('items.columns.totalDepreciation'),
      dataIndex: 'total_depreciation',
    },
    {
      title: t('items.columns.netCost'),
      dataIndex: 'net_cost',
    },
    {
      title: t('items.columns.taxDepreciation'),
      dataIndex: 'tax_deprecation',
    },
    {
      title: t('items.columns.updatedAt'),
      dataIndex: 'updated_at',
    },
    {
      title: t('items.columns.action'),
      render: (data) => {
        return (
          <Button
            onClick={() => {
              SetUpdateItemsVisible(true);
              const restructureAccessories = data.accessories.reduce(
                (acc, current) => {
                  acc.push(current.id);
                  return acc;
                },
                []
              );
              updateForm.setFieldsValue({
                id: data.id,
                code: data.code,
                name: data.name,
                price: data.price,
                location: data.location,
                type: data.item_type.id,
                description: data.description,
                provider_id: data.provider?.id,
                accessories: restructureAccessories,
              });
            }}
          >
            {t('items.buttons.edit')}
          </Button>
        );
      },
    },
  ];
  const handelCreateProviderItem = () => {
    createForm.validateFields().then((values) => {
      createProviderItem({
        variables: {
          ...values,
        },
      })
        .then((response) => {
          const {
            data: {
              createProviderItem: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetCreateProviderItemVisiable(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateItems = () => {
    updateForm.validateFields().then((values) => {
      updateProviderItem({
        variables: {
          ...values,
        },
      })
        .then((response) => {
          const {
            data: {
              updateProviderItem: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetUpdateItemsVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>
          {t('items.header')}
        </PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            SetCreateProviderItemVisiable(true);
          }}
        >
          {t('items.buttons.createItem')}
        </PrimaryButton>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={data && data.providersItems.data}
        columns={columns}
        loading={loading || updateLoading || creating}
        rowKey="id"
        pagination={{
          total: data?.providersItems?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title={t('items.buttons.createItem')}
        visible={createProviderItemVisiable}
        okText={t('providers.buttons.create')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelCreateProviderItem}
        onCancel={() => {
          SetCreateProviderItemVisiable(false);
        }}
      >
        <Form onFinish={handelCreateProviderItem} form={createForm}>
          <Item
            name="code"
            label={t('items.columns.code')}
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label={t('items.columns.name')}
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label={t('items.columns.description')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="user_id"
            label={t('items.columns.user')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select showSearch optionFilterProp="phone">
              {usersdata &&
                usersdata.users.data.map((user, idx) => (
                  <Select.Option key={idx} value={user.id} phone={user.phone}>
                    {user.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="location"
            label={t('items.columns.location')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="type"
            label={t('items.columns.type')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData &&
                typesData.accessoriesTypes.data.map((type, idx) => (
                  <Select.Option key={idx} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="price"
            label={t('items.columns.itemPrice')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="provider_id"
            label={t('items.columns.provider')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData &&
                providerData.providers.data.map((provider, idx) => (
                  <Select.Option key={idx} value={provider.id}>
                    {provider.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="accessories"
            label={t('items.columns.accessories')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={accesoriesOptions}
            />
          </Item>
        </Form>
      </Modal>
      <Modal
        title={t('items.buttons.updateItem')}
        visible={UpdateItemsVisible}
        okText={t('providers.buttons.update')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelUpdateItems}
        onCancel={() => {
          SetUpdateItemsVisible(false);
        }}
      >
        <Form onFinish={handelUpdateItems} form={updateForm}>
          <Item
            name="id"
            label="Id"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input disabled></Input>
          </Item>
          <Item
            name="code"
            label={t('items.columns.code')}
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label={t('items.columns.name')}
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label={t('items.columns.description')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="user_id"
            label={t('items.columns.user')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select showSearch optionFilterProp="children">
              {usersdata &&
                usersdata.users.data.map((user, idx) => (
                  <Select.Option key={idx} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="location"
            label={t('items.columns.location')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="type"
            label={t('items.columns.type')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData &&
                typesData.accessoriesTypes.data.map((type, idx) => (
                  <Select.Option key={idx} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="price"
            label={t('items.columns.itemPrice')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="provider_id"
            label={t('items.columns.provider')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData &&
                providerData.providers.data.map((provider, idx) => (
                  <Select.Option key={idx} value={provider.id}>
                    {provider.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
          <Item
            name="accessories"
            label={t('items.columns.accessories')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
              mode="multiple"
              value={['19', '20']}
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={accesoriesOptions}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Items;
