import React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, DatePicker } from 'antd';
import { isMobile, TabletView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const Filter = ({
  form: { getFieldDecorator, validateFields, resetFields },
  refetch,
  setIsSearching,
  resetPagination,
  currentPage,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        setIsSearching();
        refetch({
          id: values.id && values.id.length > 0 ? values.id : undefined,
          date: values.date
            ? values.date.toISOString().split('T')[0]
            : undefined,
          entityId:
            values.entityId && values.entityId.length > 0
              ? values.entityId
              : undefined,
          entity:
            values.entity && values.entity.length > 0
              ? values.entity
              : undefined,
          page: 1,
          first: 5,
        });
      }
    });
  };

  const handleReset = () => {
    resetFields();
    refetch({
      id: undefined,
      date: undefined,
      entity: undefined,
      entityId: undefined,
      page: currentPage,
      first: 5,
    });
    resetPagination();
  };
  const [t, i18n] = useTranslation("translation");
  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: `${isMobile && !TabletView ? 'column' : 'row'}`,
        flexWrap: 'wrap',
        margin: '1%',
      }}
      layout={isMobile ? 'horizontal' : 'inline'}
      onSubmit={handleSubmit}
    >
      <Form.Item label={t('Id')}>
        {getFieldDecorator('id')(
          <Input size="large" placeholder={t('Enter Log ID')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Date')}>
        {getFieldDecorator('date')(
          <DatePicker
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            placeholder={[t('Select date')]}
          />
        )}
      </Form.Item>
      <Form.Item label={t('Module Id')}>
        {getFieldDecorator('entityId')(
          <Input size="large" placeholder={t('Enter Module Id')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Module Name (case sensitive)')}>
        {getFieldDecorator('entity')(
          <Input size="large" placeholder={t('Enter Module Id')}></Input>
        )}
      </Form.Item>
      <br />
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit">
          {t('Filter Records')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" onClick={handleReset}>
          {t('Reset Fields')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({})(Filter);
