import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  GetTypes,
  CreateType,
  UpdateType
} from 'services/providers';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import { Table, Form, Button, Modal, Radio, Input, InputNumber } from 'antd';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const Types = () => {
  const {t} = useTranslation('assets');
  const [createTypeVisiable, SetCreateTypeVisiable] = useState(
    false
  );
  const [UpdateTypeVisible, SetUpdateTypeVisible] = useState(false);
  const { data, loading, fetchMore, refetch } = useQuery(GetTypes, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [page, setPage] = useState(1);
  const [updateType, { loading: updateLoading }] = useMutation(
    UpdateType
  );
  const [createType, { loading: creating }] = useMutation(
    CreateType
  );
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const columns = [
    {
      title: t('types.columns.id'),
      dataIndex: 'id',
    },
    {
      title: t('types.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('types.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('types.columns.depreciationRatio'),
      dataIndex: 'depreciation_ratio',
    },
    {
      title: t('types.columns.createdAt'),
      dataIndex: 'created_at',
    },
    {
      title: t('types.columns.updatedAt'),
      dataIndex: 'updated_at',
    },
    {
      title: t('types.columns.action'),
      render: (data) => {
        return (
          <Button
            onClick={() => {
              SetUpdateTypeVisible(true);
              updateForm.setFieldsValue({
                id: data.id,
                description: data.description,
                depreciation_ratio : data.depreciation_ratio,
                name: data.name

              });
            }}
          >
            {t('types.buttons.updateType')}
          </Button>
        );
      },
    },
  ];
  const handelCreateType = () => {
    createForm.setFieldsValue({
      id: data.id,
    });
    createForm.validateFields().then((values) => {
      createType({
        variables: {
          ...values,
        },
      })
        .then((response) => {
          const {
            data: {
              createAccessoriesType: { status, message },
            },
          } = response;
          notify('success', message);
          refetch();
          SetCreateTypeVisiable(false);
        })
        .catch((err) => {
          console.log(err)
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateType = () => {
    updateForm.validateFields().then((values) => {
      updateForm.setFieldsValue({
        id: data.id,
      });
      updateType({
        variables: {
          ...values ,
        },
      })
        .then((response) => {
          const {
            data: {
              updateAccessoriesType: { status, message },
            },
          } = response;
          if(status){
            refetch({
              page:page,
              first: 5,
            });
            notify('success', message);
            SetUpdateTypeVisible(false);
          }else{
            notify('success', message);
            SetUpdateTypeVisible(false);

          }
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>{t('types.header')}</PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            SetCreateTypeVisiable(true);
            createForm.setFieldsValue({
              id: data.id,
            });
          }}
        >
               {t('types.buttons.createType')}
        </PrimaryButton>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={data && data.accessoriesTypes.data}
        columns={columns}
        loading={loading || updateLoading || creating}
        rowKey="id"
        pagination={{
          total: data?.accessoriesTypes?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title={t('types.buttons.createType')}
        visible={createTypeVisiable}
        okText={t('providers.buttons.create')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelCreateType}
        onCancel={() => {
          SetCreateTypeVisiable(false);
        }}
      >
        <Form onFinish={handelCreateType} form={createForm}>
          <Item
            name="name"
            label={t('types.columns.name')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label={t('types.columns.description')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="depreciation_ratio"
            label={t('types.columns.depreciationRatio')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
        
        </Form>
      </Modal>
      <Modal
        title=   {t('types.buttons.updateType')}
        visible={UpdateTypeVisible}
        okText={t('providers.buttons.update')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelUpdateType}
        onCancel={() => {
          SetUpdateTypeVisible(false);
        }}
      >
        <Form onFinish={handelUpdateType} form={updateForm}>
          <Item
            name="id"
            label={t('types.columns.id')}
            rules={[
              {
                message: 'Please enter id',
              },
            ]}
          >
            <Input disabled={true} ></Input>
          </Item>
          <Item
            name="name"
            label={t('types.columns.name')}
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label={t('types.columns.description')}
            rules={[
              {
                required: true,
                message: 'Please enter description',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="depreciation_ratio"
            label={t('types.columns.depreciationRatio')}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Types;
