/**List all Staff members that were created in the system
 * with an option to edit, generate new code or add new staff/user
 */
import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Table, Space, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { GET_USERS, GENERATE_CODE, SUSPEND_USER } from 'services';
import { formatRole, notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, Can, PrimaryButton } from 'components';
import UserFilter from './filter';
import { useTranslation } from 'react-i18next';

const UsersListContainer = styled.div``;

const UsersList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [page, setPage] = useState(1);
  const [filteredValues, setFilteredValues] = useState({
    id: undefined,
    phone: undefined,
    station: undefined,
    role_id: undefined,
  });

  const { data, loading, fetchMore, refetch } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 5,
      page: 1,
    },
  });

  const [generateCode, { loading: generatingCode }] = useMutation(
    GENERATE_CODE,
    { awaitRefetchQueries: true }
  );

  const [suspendUser] = useMutation(SUSPEND_USER);
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Role',
      render: (user) => user.role && formatRole(user.role.name),
    },
    {
      title: 'Assigned Station',
      render: (user) => user.station && formatRole(user.station.name_en),
    },
    {
      title: 'Partner Segment',
      dataIndex: ['partner_segment', 'name'],
    },
    {
      title: 'Is Verified',
      render: (user) => `${user.is_verified}`,
    },
    {
      title: 'Verification Code',
      render: (user) => {
        return (
          <Can
            perform="VIEW_USER_VERIFICATION_CODE"
            yes={user.verification_code && user.verification_code.code}
          />
        );
      },
    },
    {
      title: 'User Status',
      render: (user) => `${user.active}`,
    },
    {
      title: 'Operations',
      render: (user) => {
        const currentTimestamp = new Date();
        const expiryTimestamp = new Date(
          user.verification_code && user.verification_code.expiry_time
        );
        return (
          <>
            <Space size="middle">
              <Can
                perform="UPDATE_USER"
                yes={
                  <a
                    onClick={() =>
                      history.push({
                        pathname: `/edit-user/${user.id}`,
                        state: { user: user },
                      })
                    }
                  >
                    {t('Edit')}
                  </a>
                }
              />
              <Button
                style={{ height: 18, padding: 0 }}
                type="link"
                disabled={currentTimestamp < expiryTimestamp}
                onClick={() =>
                  generateCode({
                    variables: {
                      id: user.id,
                    },
                    refetchQueries: () => [
                      {
                        query: GET_USERS,
                        variables: {
                          page,
                          first: 5,
                        },
                      },
                    ],
                  })
                    .then(function (data) {
                      data = data.data.userGenerateVerificationCode;

                      if (data.success !== true) {
                        notify('error', data.message);
                      }
                      if (data.success === true) {
                        notify('success', data.message);
                      }
                    })
                    .catch((err) => {
                      const {
                        extensions: { validation },
                        message,
                      } = err['graphQLErrors'][0];

                      if (validation) {
                        for (let error in validation) {
                          notify('error', validation[error][0]);
                        }
                      } else {
                        notify('error', message);
                      }
                    })
                }
              >
                {t('Regenerate Code')}
              </Button>
              <Can
                perform="UPDATE_USER"
                yes={
                  <Button
                    style={{
                      height: 18,
                      padding: 0,
                      color: user.active ? 'red' : 'green',
                    }}
                    type="link"
                    onClick={() =>
                      suspendUser({
                        variables: {
                          id: user.id,
                          status: !user.active,
                        },
                        refetchQueries: () => [
                          {
                            query: GET_USERS,
                            variables: {
                              page,
                              first: 5,
                            },
                          },
                        ],
                      })
                    }
                  >
                    {user.active ? t('Suspend') : t('Active')}
                  </Button>
                }
              />
            </Space>
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <UsersListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Staf')}</PrimaryTitle>
        <Can
          perform="CREATE_USER"
          yes={
            <PrimaryButton onClick={() => history.push('/new-user')}>
              {t('Add User')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <UserFilter
        setFilteredValues={setFilteredValues}
        refetch={refetch}
        resetPage={() => {
          setPage(1);
        }}
      />
      <Table
        bordered
        dataSource={data?.users?.data}
        columns={columns}
        loading={loading || generatingCode}
        rowKey="id"
        pagination={{
          total: data?.users?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
                ...filteredValues,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
        scroll={{ x: 400 }}
      />
    </UsersListContainer>
  );
};

export default UsersList;
