/**Component for creating new notifications to be sent to all or
 * specific users
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Divider, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SEND_NOTIFICATION } from 'services';
import { notify } from 'utilities';
import { CustomersSearch } from 'components';
import { ListHeader, PrimaryTitle } from 'components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**Submit the form for creating new notifications */
const SendNotificationForm = ({
  form: { getFieldDecorator, validateFields, getFieldValue },
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [sendNotification, { loading }] = useMutation(SEND_NOTIFICATION);
  const [customers_ids, setCustomersIds] = useState([]);
  const [files, setFiles] = useState([]);

  const setRecipientsValue = (value) => {
    setCustomersIds(value);
  };

  const handlesendNotification = (e, sendNotification) => {
    e.preventDefault();

    validateFields(
      ['title_en', 'title_ar', 'message_en', 'message_ar', 'to'],
      (err, values) => {
        if (!err) {
          const { title_en, title_ar, message_en, message_ar, to } = values;
          if (to === 'Specific' && !customers_ids.length) {
            return notify('error', 'Please select at least one recipient');
          }

          let csv_file = files[0];

          sendNotification({
            variables: {
              title_en,
              title_ar,
              message_en,
              message_ar,
              to,
              csv_file,
              customers_ids,
            },
          })
            .then((res) => {
              const {
                data: {
                  sendNotification: { id },
                },
              } = res;

              if (id) {
                notify('success', 'Notification sent successfully');
                history.push('/notifications');
              }
            })
            .catch((err) => {
              console.info(err);
              notify('error', 'oops something went wrong !');

              /*      const {
                               extensions: { validation },
                               message,
                             } = err['graphQLErrors'][0];

                             if (validation) {
                               for (let error in validation) {
                                 notify('error', validation[error][0]);
                               }
                             } else {
                               notify('error', message);
                             }
               */
            });
        }
      }
    );
  };

  const uploadProps = {
    accept: '.csv',
    onRemove: (file) => {
      setFiles([]);
    },
    beforeUpload: (file) => {
      setFiles([file]);
      return false;
    },
    fileList: files,
  };

  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Create Notification')}</PrimaryTitle>
      </ListHeader>

      <Divider />
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        onSubmit={(e) => handlesendNotification(e, sendNotification)}
      >
        <Form.Item label={t('English Title')}>
          {getFieldDecorator('title_en', {
            rules: [
              {
                required: true,
                message: t('Please enter the English title'),
              },
            ],
          })(<Input placeholder={t('English Notification title')} />)}
        </Form.Item>
        <Form.Item label={t('Arabic Title')}>
          {getFieldDecorator('title_ar', {
            rules: [
              {
                required: true,
                message: t('Please enter the Arabic title'),
              },
            ],
          })(<Input placeholder={t('Arabic Notification title')} />)}
        </Form.Item>

        <Form.Item label={t('English Content')}>
          {getFieldDecorator('message_en', {
            rules: [
              {
                required: true,
                message: t('Please enter the English content'),
              },
            ],
          })(<Input placeholder={t('English Notification content')} />)}
        </Form.Item>
        <Form.Item label={t('Arabic Content')}>
          {getFieldDecorator('message_ar', {
            rules: [
              {
                required: true,
                message: t('Please enter the Arabic content'),
              },
            ],
          })(<Input placeholder={t('Arabic Notification content')} />)}
        </Form.Item>

        <Form.Item label={t('Target Users')}>
          {getFieldDecorator('to', {
            rules: [
              {
                required: true,
                message: t('Please select target users'),
              },
            ],
          })(
            <Select placeholder={t('Select target users')}>
              <Option value="All">{t('All users')}</Option>
              <Option value="Specific">{t('Specific users')}</Option>
              <Option value="Csv">{t('Upload CSV file')}</Option>
            </Select>
          )}
        </Form.Item>
        {getFieldValue('to') === 'Specific' && (
          <Form.Item label="Recipients List">
            {getFieldDecorator('customers_ids', {
              rules: [
                {
                  required: getFieldValue('to') === 'Specific',
                  message: 'Please select at least one recipient',
                },
              ],
            })(
              <CustomersSearch
                setRecipientsValue={setRecipientsValue}
              ></CustomersSearch>
            )}
          </Form.Item>
        )}
        {getFieldValue('to') === 'Csv' && (
          <Form.Item label="CSV File">
            {getFieldDecorator('csv_file', {
              rules: [
                {
                  required: getFieldValue('to') === 'Csv',
                  message: 'Please upload a file',
                },
              ],
            })(
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>
                  {t('Click to Upload')}
                </Button>
              </Upload>
            )}
          </Form.Item>
        )}
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {t('Create')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const SendNotificationFormWrapper = Form.create({})(SendNotificationForm);

export default SendNotificationFormWrapper;
