import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  GetAccesories,
  CreateAccesories,
  UpdateAccessories,
  GetProvider,
  GetTypes
} from 'services/providers';
import { GET_ACTIVE_LOCATIONS,me , GET_USERS } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import { Table, Form, Button, Modal, Switch  , Input, InputNumber ,Select } from 'antd';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const Accessories = () => {
  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const {t}= useTranslation('assets')
  const [createAccessorieVisiable, SetCreateAccessorieVisiable] = useState(
    false
  );
  const {data : usersdata } = useQuery(GET_USERS,{
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 1000,
      page: 1,
    },
  });
  console.log('usersdata',usersdata)
  const [UpdateAccessorieVisible, SetUpdateAccessorieVisible] = useState(false);
  const { data, loading, fetchMore, refetch,error } = useQuery(GetAccesories, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [page, setPage] = useState(1);
  const [updateAccessories, { loading: updateLoading }] = useMutation(
    UpdateAccessories
  );
  const [createAccessorie, { loading: creating }] = useMutation(
    CreateAccesories
  );
  const { data:providerData } = useQuery(GetProvider, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: typesData } = useQuery(GetTypes, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: locations } = useQuery(GET_ACTIVE_LOCATIONS );
  const { activeLocations } = locations || { activeLocations: [] };
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: t('accessories.columns.code'),
      dataIndex: 'code',
    },
    {
      title: t('accessories.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('accessories.columns.user'),
      dataIndex: [ 'user' ,'name'],
    },
    {
      title: t('accessories.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('accessories.columns.provider'),
      dataIndex: ['provider', 'name'],
    },
    {
      title: t('accessories.columns.location'),
      dataIndex: ['location', 'name_en'],
    },
    {
      title: t('accessories.columns.type'),
      dataIndex: 'type',
    },
    {
      title: t('accessories.columns.price'),
      dataIndex: 'price',
    },
    {
      title: t('accessories.columns.soldPrice'),
      dataIndex: 'sold_price',
    },
    {
      title: t('accessories.columns.totalCost'),
      dataIndex: 'total_cost',
    },
    {
      title: t('accessories.columns.createdAt'),
      dataIndex: 'created_at',
    },
    {
      title: t('accessories.columns.updatedAt'),
      dataIndex: 'updated_at',
    },
    {
      title: t('accessories.columns.action'),
      render: (data) => {
        return (
          <Button
            onClick={() => {
              SetUpdateAccessorieVisible(true);
              updateForm.setFieldsValue({
                id: data.id,
                name: data.name,
                price:data.price,
                type:data.type,
                sold_price: data.sold_price,
                provider: data.provider.id,
                description: data.description,
                code: data.code
              });
            }}
          >
           {t('accessories.buttons.edit')}
          </Button>
        );
      },
    },
  ];
  const handelCreateAccessorie = () => {
    createForm.validateFields().then((values) => {
      createAccessorie({
        variables: {
          ...values
        },
      })
        .then((response) => {
          const {
            data: {
              createAccessories: { status, message },
            },
          } = response;
          if(status){
            notify('success', message);
            SetCreateAccessorieVisiable(false);
            refetch()
            
          }else{
            notify('error', message);
          }
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateAccessorie = () => {
    updateForm.validateFields().then((values) => {
      updateAccessories({
        variables: {
          ...values,
          user_id : userData?.me?.id,
          location_id: +values.location_id
        },
      })
        .then((response) => {
          const {
            data: {
              updateAccessories: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetUpdateAccessorieVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>{t('accessories.header')}</PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            createForm.resetFields();
            SetCreateAccessorieVisiable(true);
          }}
        >
            {t('accessories.buttons.createAccessories')}
        </PrimaryButton>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={data && data.accessories.data}
        columns={columns}
        loading={loading || updateLoading || creating}
        rowKey="id"
        pagination={{
          total: data?.accessories?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title={t('accessories.buttons.createAccessories')}
        visible={createAccessorieVisiable}
        okText={t('providers.buttons.create')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelCreateAccessorie}
        onCancel={() => {
          SetCreateAccessorieVisiable(false);
        }}
      >
        <Form onFinish={handelCreateAccessorie} form={createForm}>
          <Item
            name="name"
            label={t("accessories.columns.name")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="code"
            label={t("accessories.columns.code")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="user_id"
            label={t("accessories.columns.user")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
            showSearch
            optionFilterProp="children"
            >
              {usersdata&&usersdata.users.data.map((user, idx) => (
                <Select.Option key={idx} value={user.id}
                >
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="description"
            label={t("accessories.columns.description")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="type"
            label={t("accessories.columns.type")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.name}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label={t("accessories.columns.price")}
            rules={[
              {
                required: true,
                message: 'Please enter the price',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="sold_price"
            label={t("accessories.columns.soldPrice")}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="location_id"
            label={t("accessories.columns.description")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="provider_id"
            label={t("accessories.columns.provider")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
      <Modal
         title={t('accessories.buttons.updateAccessories')}
        visible={UpdateAccessorieVisible}
        okText={t('providers.buttons.update')}
        cancelText={t('providers.buttons.cancel')}
        onOk={handelUpdateAccessorie}
        onCancel={() => {
          SetUpdateAccessorieVisible(false);
        }}
      >
        <Form onFinish={handelUpdateAccessorie} form={updateForm}>
          <Item
            name="id"
            label="Id"
            rules={[
              {
                message: 'Please enter id',
              },
            ]}
          >
            <Input  disabled={true} ></Input>
          </Item>
          <Item
            name="name"
            label={t("accessories.columns.name")}
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="code"
            label={t("accessories.columns.code")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="user_id"
            label={t("accessories.columns.user")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
            showSearch
            optionFilterProp="children"
            >
              {usersdata&&usersdata.users.data.map((user, idx) => (
                <Select.Option key={idx} value={user.id}
                >
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="description"
            label={t("accessories.columns.description")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="type"
            label={t("accessories.columns.type")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.name}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label={t("accessories.columns.price")}
            rules={[
              {
                required: true,
                message: 'Please enter the price',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="sold_price"
            label={t("accessories.columns.soldPrice")}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="location_id"
            label={t("accessories.columns.description")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="provider_id"
            label={t("accessories.columns.provider")}
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Accessories;
