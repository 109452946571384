/**Component for searching trips to ship order on it */
import React, { useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled/macro';
import { useQuery } from '@apollo/react-hooks';
import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Spin,
  DatePicker,
} from 'antd';

import { GET_ACTIVE_LOCATIONS } from 'services';
import { ActionButton, CancelButton, colors, notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option, OptGroup } = Select;

const TripInfoContainer = styled.section`
  padding-top: 20px;
  background-color: ${colors.gray};

  .ant-form-item-label {
    color: ${colors.primaryColor};

    label {
      color: ${colors.primaryColor};
      height: 100%;
    }
  }

  ${ActionButton}
  ${CancelButton}

  .ant-btn-primary, .ant-btn-link {
    margin-top: 10px;
  }
`;

const TripInfo = ({ setSearchValues, loadingResults, children }) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const [selectedCityName, setSelectedCityName] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS
  );

  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const onChange = (fromLocationId) => {
    if (fromLocationId === form.getFieldValue('to'))
      form.setFieldsValue({
        to: undefined,
      });

    Object.keys(availableLocations).forEach((cityName) => {
      availableLocations[cityName].forEach((location) => {
        if (location.id === fromLocationId) {
          setSelectedCityName(cityName);
          return;
        }
      });
    });
  };

  const handleTripDate = (date) => {
    if (date) setIsActive(false);
    else setIsActive(true);
  };

  const onFinish = (values) => {
    setSearchValues(values);
  };

  const resetFields = () => {
    form.resetFields();
    setSearchValues(null);
  };

  return (
    <TripInfoContainer>
      <Form
        form={form}
        layout="vertical"
        name="new-customer-form"
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Item
              name="from"
              label={t('Departure Station')}
              rules={[
                {
                  required: true,
                  message: t('Please, select a departure station!'),
                },
              ]}
            >
              <Select
                placeholder={t('Select Station')}
                size="large"
                showSearch
                optionFilterProp="children"
                allowClear
                onChange={onChange}
              >
                {loadingLocations ? (
                  <Option value={null} disabled style={{ textAlign: 'center' }}>
                    <Spin tip="Loading Stations..." />
                  </Option>
                ) : (
                  Object.keys(availableLocations).map((cityName, cityIndex) => (
                    <OptGroup key={`city_${cityIndex}`} label={cityName}>
                      {availableLocations[cityName].map(
                        (location, locationIndex) => (
                          <Option
                            key={`location_${cityIndex}_${locationIndex}`}
                            value={location.id}
                          >
                            {nameLang === "en" ? location.name_en : location.name_ar}
                          </Option>
                        )
                      )}
                    </OptGroup>
                  ))
                )}
              </Select>
            </Item>
          </Col>

          <Col span={12}>
            <Item
              name="to"
              label={t('Destination Station')}
              rules={[
                {
                  required: true,
                  message: t('Please, select a destination station!'),
                },
              ]}
            >
              <Select
                placeholder={t('Select Station')}
                size="large"
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {loadingLocations ? (
                  <Option value={null} disabled style={{ textAlign: 'center' }}>
                    <Spin tip="Loading Stations..." />
                  </Option>
                ) : (
                  Object.keys(availableLocations)
                    .filter((cityName) => cityName !== selectedCityName)
                    .map((cityName, cityIndex) => (
                      <OptGroup key={`city_${cityIndex}`} label={cityName}>
                        {availableLocations[cityName].map(
                          (location, locationIndex) => (
                            <Option
                              key={`location_${cityIndex}_${locationIndex}`}
                              value={location.id}
                            >
                              {nameLang === "en" ? location.name_en : location.name_ar}
                            </Option>
                          )
                        )}
                      </OptGroup>
                    ))
                )}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Item
              name="tripDate"
              label={t('Departure Date')}
              rules={[
                {
                  required: true,
                  message: t('Please, select the trip date!'),
                },
              ]}
            >
              <DatePicker
                placeholder={t('Select Date')}
                size="large"
                style={{ width: '100%' }}
                onChange={handleTripDate}
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                }}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={15} align="middle">
          <Col span={10}>
            <Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loadingResults}
              >
                {t('Search Trips')}
              </Button>
            </Item>
          </Col>

          <Col span={5}>
            <Item>
              <Popconfirm
                title={t('Are you sure you want to discard?')}
                okText={t('Yes')}
                cancelText={t('No')}
                onConfirm={resetFields}
                onCancel={() => {}}
              >
                <Button type="link" size="large" danger>
                  {t('Reset Fields')}
                </Button>
              </Popconfirm>
            </Item>
          </Col>
        </Row>
      </Form>

      {children}
    </TripInfoContainer>
  );
};

export default TripInfo;
