/**Component for creating/editing shipment order */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Table, Typography, Button, Modal, Spin } from 'antd';
import { ListHeader, PrimaryTitle } from 'components';
import { colors, formatDate, formatTime, notify } from 'utilities';
import TripInfo from './trip-info';
import TripsList from './trips-list';
import ShipmentInfoForm from './shipment-info-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const NewShipmentContainer = styled.section`
  h2 {
    font-size: 18px;
    color: ${colors.primaryColor};
  }

  h3 {
    font-size: 16px;
    color: ${colors.primaryColor};
  }
`;

const ShipmentForm = ({ location: { state: { order } = {} } }) => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const [searchValues, setSearchValues] = useState(null);
  const [loadingResults, setLoadingResults] = useState(false);
  const [trip, setTrip] = useState(null);
  const [defaultTrip, setDefaultTrip] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (order) {
      const selectedTrip = {
        id: order?.trip_route_line?.trip.id,
        ref_code: order?.trip_route_line?.trip.ref_code,
        routeLine: order?.trip_route_line,
        fromDate: order?.trip_route_line?.trip.date,
        fromTime: order?.trip_route_line?.trip.time,
      };
      setTrip(selectedTrip);
      setDefaultTrip(selectedTrip);
    }
    if (history.location.pathname === '/new-shipment') {
      setTrip(null);
    }
  }, [order, history]);

  useEffect(() => {
    if (trip && trip.id != order?.trip_route_line?.trip?.id)
      notify('success', 'trip selected successfully');
  }, [order, trip]);
  const editing = () => {
    if (order) {
      return true;
    }
    return false;
  };
  const updateShipmentMutation = gql`
    mutation editShipmentTrip($id: ID!, $trip_id: ID!, $from: ID!, $to: ID!) {
      editShipmentTrip(id: $id, trip_id: $trip_id, from: $from, to: $to) {
        status
        message
        shipment {
          id
          from_location {
            id
            name_en
          }
          to_location {
            id
            name_en
          }
          trip_route_line {
            id
            trip {
              id
              ref_code
              date
              time
            }
            from_city {
              id
              name_en
            }
            to_city {
              id
              name_en
            }
          }
        }
      }
    }
  `;
  const [updateShipmentTrip, { loading: updateShipmentLoading }] = useMutation(
    updateShipmentMutation
  );
  const updateTrip = () => {
    if (
      !trip ||
      trip == defaultTrip ||
      !searchValues?.from ||
      !searchValues?.to
    ) {
      notify('error', 'please select trip!');
      return;
    }
    updateShipmentTrip({
      variables: {
        id: order ? order.id : null,
        trip_id: trip ? trip.id : null,
        from: searchValues?.from,
        to: searchValues?.to,
      },
    })
      .then((data) => {
        const selectedTrip = {
          id: data?.data?.editShipmentTrip?.shipment?.trip_route_line?.trip.id,
          ref_code:
            data?.data?.editShipmentTrip?.shipment?.trip_route_line?.trip
              ?.ref_code,
          routeLine: data?.data?.editShipmentTrip?.shipment?.trip_route_line,
          fromDate:
            data?.data?.editShipmentTrip?.shipment?.trip_route_line?.trip?.date,
          fromTime:
            data?.data?.editShipmentTrip?.shipment?.trip_route_line?.trip?.time,
        };
        setDefaultTrip(selectedTrip);
        let noti = 'error';
        if (data?.data?.editShipmentTrip.status) {
          noti = 'success';
          setVisible(false);
        }
        notify(noti, data?.data?.editShipmentTrip.message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', t(message));
        }
      });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Trip Code',
      dataIndex: 'ref_code',
    },
    {
      title: 'Departure City',
      dataIndex: ['routeLine', 'from_city', nameLang==="en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Arrival City',
      dataIndex: ['routeLine', 'to_city', nameLang==="en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Departure Date',
      render: (trip) => formatDate(trip?.fromDate),
    },
    {
      title: 'Departure Time',
      render: (trip) => formatTime(trip?.fromTime),
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <NewShipmentContainer>
      <Modal
        width={'auto'}
        style={{ margin: '10%' }}
        visible={visible}
        title="Confirm Action"
        onOk={updateTrip}
        onCancel={() => {
          setVisible(false);
          setTrip(defaultTrip);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setVisible(false);
              setTrip(defaultTrip);
            }}
          >
            {t('Cancel')}
          </Button>,
          <Button key="confirm" type="primary" onClick={updateTrip}>
            {t('Confirm')}
          </Button>,
        ]}
      >
        <Spin spinning={updateShipmentLoading}>
          <h1>{'Current Trip# ' + trip?.id}</h1>
          <TripInfo
            setSearchValues={setSearchValues}
            loadingResults={loadingResults}
          >
            {searchValues && (
              <TripsList
                searchValues={searchValues}
                setLoadingResults={setLoadingResults}
                setTrip={setTrip}
              />
            )}
          </TripInfo>
        </Spin>
      </Modal>
      <ListHeader>
        <PrimaryTitle>
          {editing() ? t('Edit Shipment Order') : t('Create Shipment Order')}
        </PrimaryTitle>
      </ListHeader>

      {trip || editing() ? (
        <div>
          <ListHeader>
            <Title level={2} style={{ marginTop: 15 }}>
              {t('Selected Trip For Shipment:')}
            </Title>
            {!editing() && (
              <Button
                style={{ marginTop: '30px', marginBottom: '30px' }}
                onClick={() => {
                  setTrip(null);
                  setSearchValues(null);
                }}
                type="primary"
              >
                {t('Change Shipment Trip')}
              </Button>
            )}
            {editing() && (
              <Button
                style={{ marginTop: '30px', marginBottom: '30px' }}
                onClick={() => {
                  setVisible(true);
                }}
                type="primary"
              >
                {t('Change Shipment Trip')}
              </Button>
            )}
          </ListHeader>
          <Table
            style={{ marginTop: 15 }}
            bordered
            rowKey="id"
            columns={columns}
            dataSource={trip ? [trip] : []}
            pagination={false}
          />

          {editing() ? (
            <ShipmentInfoForm
              selectedTrip={trip}
              order={order}
              searchValues={searchValues}
            />
          ) : (
            <ShipmentInfoForm selectedTrip={trip} searchValues={searchValues} />
          )}
        </div>
      ) : (
        <TripInfo
          setSearchValues={setSearchValues}
          loadingResults={loadingResults}
        >
          {searchValues && (
            <TripsList
              searchValues={searchValues}
              setLoadingResults={setLoadingResults}
              setTrip={setTrip}
            />
          )}
        </TripInfo>
      )}
    </NewShipmentContainer>
  );
};

export default ShipmentForm;
