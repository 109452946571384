/**Component for replacing ticket for a passenger when trip is cancelled
 * Displays search input fields for selecting trips and new seat to select for customer
 */
import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import PromoCode from 'scenes/ticketing/promo-code';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Row,
  Col,
  Button,
  Input,
  Select,
  DatePicker,
  Spin,
  notification,
} from 'antd';
import moment from 'moment';
import {
  ReservedTickets,
  allSeatTypes,
  ADD_CART_REPLACED,
  CreateCart,
  RemoveCartTickets,
  REPLACE_TICKET_ORDER,
} from 'services';
import Trips from 'scenes/ticketing/trips';
import BusSalon from 'scenes/ticketing/bus-salon-replace';
import Cart from 'scenes/ticketing/cart';
import { notify } from 'utilities';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ReplaceModal = ({
  visible,
  setVisible,
  ticket,
  allLocations,
  getTrip,
  refetchTrip,
  trip_Id,
  templateId,
  remainingTickets,
  multiple,
  handleMultipleReplace,
  multipleReplaceLoading,
  form: {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    setFieldsValue,
    resetFields,
  },
}) => {
  const [formValues, setFormValues] = useState(null);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [visibleSalon, setVisibleSalon] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [cartToken, setCartToken] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedCityName, setSelectedCityName] = useState(0);
  const [rounded, setRounded] = useState(false);
  const { Option, OptGroup } = Select;
  const history = useHistory();
  const [t,i18n]=useTranslation("translation");
  const { data: seatTypesData } = useQuery(allSeatTypes, {});

  const [replaceOrder, { loading: replacing }] = useMutation(
    REPLACE_TICKET_ORDER
  );

  const [
    getReservedTickets,
    { data: reservedSeats, loading: loadingReservedSeats },
  ] = useLazyQuery(ReservedTickets);
  const reset = () => {
    resetFields();
    setSelectedSeats({});
    setSelectedCustomer(false);
    setCurrentCart(null);
    setCartToken(null);
    setCurrentTrip(null);
    setFormValues(null);
  };
  let fromCityId = 0,
    fromCity = 'multiple select',
    toCityId = 0,
    toCity = 'multiple select';
  if (!multiple) {
    let {
      from_location: {
        city: { id: fromCityId, name_en: fromCity },
      },
      to_location: {
        city: { id: toCityId, name_en: toCity },
      },
    } = ticket;
  }

  const getCityLocations = (cityId) => {
    return allLocations.filter((location) => {
      return location.city.id === cityId;
    });
  };

  const availableLocations = allLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});
  const fromLocationId = formValues && formValues.from;

  const toLocationId = formValues && formValues.to;

  const handleSubmit = () => {
    reset();

    setVisible(false);
    refetchTrip();
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const formFields = ['from', 'to', 'trip_date'];

    validateFields(formFields, (err, fieldsValue) => {
      if (!err) {
        setFormValues(fieldsValue);
      }
    });
  };

  const showModal = ({ id: tripId }) => {
    setVisibleSalon(true);
    getReservedTickets({
      variables: {
        tripId,
        fromLocationId,
        toLocationId,
      },
    });
  };

  const handleCancel = () => {
    setVisibleSalon(false);
  };
  const onChange = (fromLocationId) => {
    if (fromLocationId === getFieldValue('to'))
      setFieldsValue({
        to: undefined,
      });

    Object.keys(availableLocations).forEach((cityName) => {
      availableLocations[cityName].forEach((location) => {
        if (location.id === fromLocationId) {
          setSelectedCityName(cityName);
          return;
        }
      });
    });
  };

  return (
    <Modal
      visible={visible}
      title="Replace Ticket"
      width="1000px"
      bodyStyle={{
        height: '500px',
        overflowY: formValues === null ? 'none' : 'scroll',
      }}
      onCancel={() => {
        reset();
        setVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            reset();
            setVisible(false);
          }}
        >
          {("Cancel")}
        </Button>,
      ]}
    >
      <Form onSubmit={handleSearch} layout="vertical">
        <Form.Item label={t("From Location")}>
          {getFieldDecorator('from', {
            rules: [{ required: true, message: t('Please enter the station!') }],
          })(
            <Select
              showSearch
              filterOption={true}
              optionFilterProp="children"
              placeholder={t("From Location")}
              onChange={onChange}
            >
              {Object.keys(availableLocations).map((cityName, cityIndex) => (
                <OptGroup key={`city_${cityIndex}`} label={cityName}>
                  {availableLocations[cityName].map(
                    (location, locationIndex) => (
                      <Option
                        key={`location_${cityIndex}_${locationIndex}`}
                        value={location.id}
                      >
                        {location.name_en}
                      </Option>
                    )
                  )}
                </OptGroup>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={t("To Location")}>
          {getFieldDecorator('to', {
            rules: [{ required: true, message: t('Please enter the station!') }],
          })(
            <Select
              showSearch
              filterOption={true}
              optionFilterProp="children"
              placeholder={t("From Location")}
            >
              {Object.keys(availableLocations)
                .filter((cityName) => cityName !== selectedCityName)
                .map((cityName, cityIndex) => (
                  <OptGroup key={`city_${cityIndex}`} label={cityName}>
                    {availableLocations[cityName].map(
                      (location, locationIndex) => (
                        <Option
                          key={`location_${cityIndex}_${locationIndex}`}
                          value={location.id}
                        >
                          {location.name_en}
                        </Option>
                      )
                    )}
                  </OptGroup>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={t("Trip Date")}>
          {getFieldDecorator('trip_date', {
            rules: [
              {
                type: 'object',
                required: true,
                message: t('Please specify the trip date!'),
              },
            ],
          })(
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(current) => {
                return current && current < moment().startOf('day');
              }}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Search")}
          </Button>
        </Form.Item>
      </Form>
      {formValues && (
        <Trips
          formValues={formValues}
          showModal={showModal}
          setCurrentTrip={setCurrentTrip}
          setRounded={setRounded}
        />
      )}
      {currentTrip && !multiple && (
        <>
          <Modal
            title={`${t("Select Your Seats for trip")} ${currentTrip.ref_code}`}
            visible={visibleSalon}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {t("Cancel")}
              </Button>,
            ]}
          >
            <Row>
              <Col span={18}>
                <Spin spinning={loadingReservedSeats}>
                  <BusSalon
                    getTrip={getTrip}
                    handleSubmit={handleSubmit}
                    exit_modal={handleCancel}
                    formValues={formValues}
                    trip={currentTrip}
                    data={{
                      cartToken: cartToken,
                      ticket_id: ticket.id,
                      from: fromLocationId,
                      to: toLocationId,
                    }}
                    allSeatTypes={seatTypesData.allSeatTypes}
                    reservedSeats={
                      reservedSeats && reservedSeats.tripReservedSeats
                        ? reservedSeats.tripReservedSeats.reserved_seats
                        : []
                    }
                    selectedSeats={
                      (currentTrip &&
                        selectedSeats[currentTrip.groupTicketId]) ||
                      []
                    }
                  />
                </Spin>
              </Col>
              <Col span={6}>
                <p>Seat Pricing:</p>
                {currentTrip &&
                  currentTrip.routeLine &&
                  currentTrip.routeLine.prices &&
                  currentTrip.routeLine.prices.length &&
                  currentTrip.routeLine.prices.map((seatPricing, index) => (
                    <p key={index}>
                      {seatPricing.seat_type.name_en}: {seatPricing.price}
                    </p>
                  ))}
              </Col>
            </Row>
          </Modal>
        </>
      )}
      {currentTrip && multiple && (
        <>
          <Modal
            title={`Replace Selected Tickets To Trip # ${currentTrip.ref_code}`}
            visible={visibleSalon}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {t("Cancel")}
              </Button>,
            ]}
          >
            <button
              onClick={() =>
                handleMultipleReplace(
                  currentTrip.id,
                  formValues.from,
                  formValues.to
                )
              }
              type="primary"
              className={'ant-btn ant-btn-primary apply'}
            >
              {multipleReplaceLoading ? (
                <Spin>{t("replacing tickets")}</Spin>
              ) : (
                t('apply replace tickets')
              )}
            </button>
          </Modal>
        </>
      )}
    </Modal>
  );
};

export default Form.create({})(ReplaceModal);
