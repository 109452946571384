import React from 'react';
import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';

const DriversInfo = ({ data }) => {
  const [t, i18n] = useTranslation("translation");
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Driver Name',
      dataIndex: 'name',
    },
    {
      title: 'Driver Phone',
      dataIndex: 'phone',
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <Card key="cardData">
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: 400 }}
      />
    </Card>
  );
};

export default DriversInfo;
