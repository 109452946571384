/**
 * Filter component to filter promo codes by
 * promo code ,
 * promo name
 * promo start date
 * promo status
 * */
import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Filter = ({
  form: { getFieldDecorator, validateFields, setFieldsValue, resetFields },
  refetch,
  setFilteredValues,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const { promoCode, promoName, status, startDate } = values;
        let active = undefined;

        if (status === 'All') active = '';
        else if (status === 'Active') active = true;
        else active = false;

        setFilteredValues({
          promoCode: promoCode ? promoCode : undefined,
          promoName: promoName ? promoName : undefined,
          active: active,
          startDate: startDate
            ? startDate.toISOString().split('T')[0]
            : undefined,
        });

        refetch({
          promoCode: promoCode ? promoCode : undefined,
          promoName: promoName ? promoName : undefined,
          startDate: startDate
            ? startDate.toISOString().split('T')[0]
            : undefined,
        });
        // if (promoCode === '' && promoName === '' && startDate) {
        //   refetch({ promoCode: undefined, promoName: undefined });
        // } else if (promoCode === '')
        //   refetch({ promoCode: undefined, promoName });
        // else if (promoName === '') refetch({ promoName: undefined, promoCode });
        // else refetch({ promoCode, promoName });
      }
    });
  };

  const handleChange = (value) => {
    setFilteredValues({ active: value });
    if (value === 'Active' || value === t('Active')) {
      refetch({ active: true });
    } else {
      refetch({ active: false });
    }
  };

  const handleReset = () => {
    resetFields();
    setFilteredValues({
      promoCode: '',
      promoName: '',
      active: '',
      startDate: '',
    });
    refetch({
      promoCode: undefined,
      promoName: undefined,
      active: undefined,
      startDate: undefined,
    });
  };

  const [t, i18n] = useTranslation("translation");
  const statusOptions = [t('Active'), t('Disabled')];

  return (
    <Form layout="inline" onSubmit={handleSubmit}>
      <Form.Item label={t('Promo Code')}>
        {getFieldDecorator('promoCode')(
          <Input size="large" placeholder={t('Enter Promo Code')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Promo Name')}>
        {getFieldDecorator('promoName')(
          <Input size="large" placeholder={t('Enter Promo Name')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Promo Status')}>
        {getFieldDecorator('status', {
          initialValue: t('All'),
        })(
          <Select size="large" style={{ width: 200 }} onChange={handleChange}>
            {statusOptions.map((option, idx) => (
              <Option key={idx} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('Start Date')}>
        {getFieldDecorator('startDate')(
          <DatePicker size="large" placeholder={t('select date')} />
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit">
          {t('Filter Records')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" onClick={handleReset}>
          {t('Reset Fields')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({})(Filter);
