/**Component that list all available trip templates in the system */
import React, {useRef, useState} from 'react';
import {
    Table,
    Spin,
    Space,
    Popconfirm,
    Input,
    Button,
    Modal,
    Form,
} from 'antd';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {TripTemplateList} from 'services';
import {ListHeader, PrimaryButton, PrimaryTitle, Can} from 'components';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {notify} from '../../utilities';
import UpdateTemplateStatus from '../../services/trips/update-template-status';
import {SearchOutlined} from '@ant-design/icons';
import {isMobile} from 'react-device-detect';
import UPDATE_TRIP_PRICE from '../../services/trips/update-trip-price';
import RouteLinesPricesCard from '../trips/route-lines-prices';
import {FetchTripsForPrices} from '../../services/trips/trip-list-for-price';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

const TripTempleteList = () => {
    const {Item} = Form;
    const [updatePriceForm] = Form.useForm();
    const searchInput = useRef(null);
    const [filteredValues, setFilteredValues] = useState({});
    const [activeTemplateId, setActiveTemplateId] = useState(0);
    const [activePrice, setActivePrice] = useState(0);
    const [activeType, setActiveType] = useState(0);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const nameLang = i18next.language;
    const [prices, setPrices] = useState([]);
    const [t, i18n] = useTranslation("translation");
    const [pricesModalVisible, setPricesModalVisible] = useState(false);
    const [priceFormModalVisible, setPriceFormModalVisible] = useState(false);
    const [getTrips, {loading: loadingPrices}] = useLazyQuery(
        FetchTripsForPrices,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setActiveTemplateId(activeTemplateId);
                setPrices(data);
                setPricesModalVisible(true);
            },
            onError: (err) => {
                setActiveTemplateId(0);
                if (err['graphQLErrors'][0]?.extensions?.validation) {
                    const {
                        extensions: {code, validation},
                        message,
                    } = err['graphQLErrors'][0];

                    if (validation) {
                        for (let error in validation) {
                            notify('error', validation[error][0]);
                        }
                    } else {
                        notify('error', t(message));
                    }
                }
            },
        }
    );
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const resetPriceValues = () => {
        setFrom(0);
        setTo(0);
        setActivePrice(0);
        setActiveType(0);
    };
    const updatePrice = (values) => {
        updateTripPrice({
            variables: {
                templateId: activeTemplateId,
                price: activePrice,
                type: activeType,
                from,
                to,
                new_price: values.new_price,
            },
        })
            .then((data) => {
                const res = data?.data?.updateTripPrice;
                if (res.status === true) {
                    notify('success', res.message);
                    setPriceFormModalVisible(false);
                    resetPriceValues();
                } else {
                    notify('error', 'some thing went wrong');
                }
            })
            .catch((err) => {
                console.info(err);
                const {
                    extensions: {validation},
                    message,
                } = err['graphQLErrors'][0];

                if (validation) {
                    for (let error in validation) {
                        notify('error', validation[error][0]);
                    }
                } else {
                    notify('error', t(message));
                }
            });
    };
    const getTripPrices = (templateId) => {
        setActiveTemplateId(templateId);
        getTrips({
            variables: {
                tripTemplateId: templateId,
                first: 10,
                page: 1,
            },
        });
    };
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: 'Ref Code',
            dataIndex: 'ref_code',
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => (
                <div style={{padding: 8}}>
                    <Input
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                        ref={searchInput}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value)}
                        placeholder="Enter the Ref Code"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, 'ref_code')}
                            icon={<SearchOutlined/>}
                            size="small"
                            style={{width: 90}}
                        >
                            {t("Search")}
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{width: 90}}
                        >
                            {t("Reset")}
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: filteredValues.ref_code,
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
            ),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.focus(), 100);
                }
            },
        },
        {
            title: 'Access Level',
            dataIndex: 'access_level',
            render: (_, record) => (
                <>
                    {record.access_level === 'Public' ? t("Public") : t("Private")}
                </>
            )
        },
        {
            title: 'From Date',
            dataIndex: 'from_date',
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
        },
        {
            title: 'Stations',
            render: ({locations}) =>
                locations.map(({name_ar, name_en}) => nameLang === "en" ? name_en : name_ar).join(', '),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <Space>
                    <Can
                        perform="VIEW_TRIP_DETAILS"
                        yes={<Link to={`/trip-details/${record.id}`}>{t('View')}</Link>}
                    />
                    {record.is_active == 'Active' && (
                        <Can
                            perform="CREATE_TRIP"
                            yes={
                                <Link
                                    to={{
                                        pathname: `/new-trip/`,
                                        search: `template_id=${record.id}`,
                                        hash: `template`,
                                    }}
                                >
                                    {t('Edit')}
                                </Link>
                            }
                        />
                    )}

                    {record.is_active == 'Active' && (
                        <Can
                            perform="CREATE_TRIP"
                            yes={
                                <Link
                                    to={'#'}
                                    onClick={() => {
                                        getTripPrices(record.id);
                                    }}
                                >
                                    {t('Prices')}
                                </Link>
                            }
                        />
                    )}

                    <Popconfirm
                        title={
                            record.is_active == 'Active'
                                ? t('Sure to Deactivate?')
                                : t('Sure to Activate?')
                        }
                        onConfirm={() => {
                            updateTemplate(
                                record.id,
                                record.is_active == 'Active' ? false : true
                            );
                        }}
                    >
                        <a href="#!">
                            {record.is_active == 'Active' ? t('Deactivate') : t('Activate')}
                        </a>
                    </Popconfirm>
                    {record.edited_to && (
                        <Can
                            perform="CREATE_TRIP"
                            yes={
                                <Link
                                    to={{
                                        pathname: `/cancel-template/${record.id}`,
                                        hash: `template`,
                                    }}
                                >
                                    {t('List Tickets')}
                                </Link>
                            }
                        />
                    )}
                </Space>
            ),
        },
    ];
    //Translate Function For columns
    const ti81n = columns.map((ele) => {
        ele.title = t(ele.title);
    });

    const history = useHistory();
    const {data, loading, refetch} = useQuery(TripTemplateList, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            first: 5,
            page: 1,
            ...filteredValues,
        },
    });

    const [updateTripPrice, {loading: updatingTripPrice}] = useMutation(
        UPDATE_TRIP_PRICE
    );
    const [updateTemplateStatus, {loading: updateStatusLoading}] = useMutation(
        UpdateTemplateStatus
    );

     const updateTemplate = (id, status) => {
        updateTemplateStatus({
            variables: {
                id: id,
                status: status,
            },
        })
            .then((response) => {
                refetch();
                const res = response?.data?.updateTripTemplateStatus;
                if (res.status === true) {
                    notify('success', res.message);
                } else {
                    notify('error', 'some thing went wrong');
                }
            })
            .catch((err) => {
                const {
                    extensions: {validation},
                    message,
                } = err['graphQLErrors'][0];

                if (validation) {
                    for (let error in validation) {
                        notify('error', validation[error][0]);
                    }
                } else {
                    notify('error', t(message));
                }
            });
    };
    const onChange = (pagination, filters) => {
        setFilteredValues({
            ...filteredValues,
            ref_code: filters.ref_code || undefined,
        });

        refetch({
            page: pagination.current,
            ref_code: filters.ref_code || undefined,
        });
    };
    console.info(from);

    return (
        <Spin
            spinning={
                loading || updateStatusLoading || loadingPrices || updatingTripPrice
            }
        >
            {data && data.tripTemplates && (
                <>
                    <ListHeader>
                        <PrimaryTitle>{t('Trips')}</PrimaryTitle>
                        <Can
                            perform="CREATE_TRIP"
                            yes={
                                <PrimaryButton
                                    new-user
                                    onClick={() => {
                                        history.push('/new-trip');
                                    }}
                                >
                                    {t('Add New trip')}
                                </PrimaryButton>
                            }
                        />
                    </ListHeader>

                    <Table
                        scroll={{x: 400}}
                        dataSource={data?.tripTemplates?.data}
                        rowKey="id"
                        columns={columns}
                        loading={loading}
                        onChange={onChange}
                        pagination={{
                            total: data?.tripTemplates?.paginatorInfo?.total,
                            pageSize: 5,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}

            <Modal
                width="1000px"
                title={t('Prices List')}
                visible={pricesModalVisible}
                footer={null}
                onOk={() => setPricesModalVisible(false)}
                onCancel={() => setPricesModalVisible(false)}
            >
                {
                    <RouteLinesPricesCard
                        cardTitle={t('Routelines')}
                        routeLines={prices?.tripTemplate?.routeLines}
                        setActivePrice={setActivePrice}
                        setActiveType={setActiveType}
                        setPricesModalVisible={setPricesModalVisible}
                        setPriceFormModalVisible={setPriceFormModalVisible}
                        setFrom={setFrom}
                        setTo={setTo}
                    />
                }
            </Modal>
            <Modal
                title={t('Change Price: ') + activePrice}
                visible={priceFormModalVisible}
                onOk={() => setPriceFormModalVisible(false)}
                onCancel={() => {
                    setPriceFormModalVisible(false);
                    setPricesModalVisible(true);
                }}
            >
                <Spin spinning={updatingTripPrice}>
                    <Form
                        form={updatePriceForm}
                        onFinish={updatePrice}
                        layout={isMobile ? 'horizontal' : 'inline'}
                    >
                        <Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please, enter the new amount!'),
                                },
                            ]}
                            name="new_price"
                            label={t('New amount')}
                        >
                            <Input placeholder={t('Enter new amount')}/>
                        </Item>
                        <Item>
                            <Button type="danger" htmlType="submit">
                                {t('Confirm')}
                            </Button>
                        </Item>
                    </Form>
                </Spin>
            </Modal>
        </Spin>
    );
};
export default TripTempleteList;
