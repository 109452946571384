/** List all system logs with an option to search for
 * a specific user that performed a certain action
 */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { Table, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatTime, notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, SelectUser } from 'components';
import Filter from './filter';
import { useTranslation } from 'react-i18next';
import { GET_AUDITS } from '../../services/audit';

const SearchContainer = styled.div`
  border-bottom: 1px solid #9b9b9b;

  .ant-form,
  .ant-form-inline {
    margin: 20px 0;
  }

  .ant-form label {
    font-size: 16px;
  }
`;
const HintContainer= styled.div`
div{
  align-items: center;
  display: flex;
  gap: 4px;
  h2{
   color:#4a4a4a
  };
 span{
  font-size: 26px;
    color: red;
 };
};
.status{
  p{
    margin-bottom:4px;
    background-color: #fafafa;
    width: fit-content;
    padding: 10px;
    border-radius: 6px;
    box-shadow: black 0px 1px 7px -5px;
  };
  display:flex
}
`
const AuditListContainer = styled.div``;

const AuditList = () => {
  ValidateUser();
  const [page, setPage] = useState(1);
  const [t, i18n] = useTranslation("translation");
  const [pagination, setPagination] = useState({
    total: 5,
    pageSize: 5,
    showSizeChanger: false,
  });
  const [isSearching, setIsSearching] = useState(false);
  const searchInput = useRef(null);
  const [filteredValues, setFilteredValues] = useState({
    actorId: null,
    id: null,
    moduleId: null,
    moduleName: null,
    date: null,
  });

  const { data, loading, refetch } = useQuery(GET_AUDITS, {
    variables: {
      first: 5,
      page: 1,
    },

    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const getPaginationObject = useCallback(() => {
    return {
      total: data?.audits?.paginatorInfo?.total,
      pageSize: 5,
      showSizeChanger: true,
      position: 1,
    };
  });
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'DateTime',
      dataIndex: 'created_at',
      width: 100,
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
    },
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <SelectUser
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, 'causer')}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.actorId,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },

    {
      title: 'Module',
      dataIndex: 'auditable_type',
    },
    {
      title: 'Module ID',
      dataIndex: 'auditable_id',
    },
    {
      title: 'Request Content',
      dataIndex: 'new_values',
      width: 100,
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  // only called on user's manual page changed
  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (filters['causer.name'] !== null) {
      refetch({
        page: pagination.current,
        actorId: filters['causer.name'],
        actorType: 'Admin',
      });
    } else {
      refetch({
        page: pagination.current,
        actorId: undefined,
      });
    }
    setFilteredValues({
      ...filteredValues,
      actorId: filters['causer.name'],
    });
  };

  return (
    <AuditListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Tickets And Orders Audits')}</PrimaryTitle>
      </ListHeader>
      <SearchContainer>
        <Filter
          refetch={refetch}
          setIsSearching={() => {
            setIsSearching(true);
          }}
          resetPagination={() => {
            setPagination(getPaginationObject());
            setIsSearching(false);
          }}
          currentPage={page}
        />
      </SearchContainer>
      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.audits.data}
        columns={columns}
        loading={loading}
        rowKey="id"
        onChange={onChange}
        pagination={{
          total: data?.audits?.paginatorInfo?.total + 1,
          pageSize: 5,
          showSizeChanger: false,
        }}
      />
  <HintContainer>
    <div>
      <span>*</span>
      <h2>Hint</h2> 
    </div>
    <div className='status'>
    <p>Status 0 : <span style={{color:'red',fontSize:14}}>Canceled</span> </p>
    <p>Status 2 : <span style={{color:'lightgreen',fontSize:14}}>Created</span> </p>
    <p>Status 4 : <span style={{color:'blue',fontSize:14}}>Modified</span> </p>
    </div>
  </HintContainer>
    </AuditListContainer>
  );
};

export default AuditList;
