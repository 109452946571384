/**Component for listing all promocodes that are available
 * with option to toggle activation on/off
 */
import React, { useState } from 'react';
import { Table, Spin, Switch, Divider, Button } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { allPromoCodes } from 'services';
import { Link, useHistory } from 'react-router-dom';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { notify } from 'utilities';
import gql from 'graphql-tag';
import Filter from './filter';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

const SearchContainer = styled.div`
  border-bottom: 1px solid #9b9b9b;

  .ant-form,
  .ant-form-inline {
    margin: 20px 0;
  }
  padding-top: 20px;

  .ant-form label {
    font-size: 16px;
  }
  .ant-btn {
    margin-bottom: 20px;
  }
`;

const activatePromocodeMutation = gql`
  mutation activatePromocode($id: ID!) {
    acivatePromocode(id: $id) {
      status
      message
    }
  }
`;
const deactivatePromocodeMutation = gql`
  mutation deactivatePromocode($id: ID!) {
    deacivatePromocode(id: $id) {
      status
      message
    }
  }
`;

const PromoCodesList = () => {
  const { data, loading, fetchMore, refetch } = useQuery(allPromoCodes, {
    variables: {
      first: 5,
      page: 1,
    },
    notifyOnNetworkStatusChange: true,
  });

  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const [deactivatePromocode] = useMutation(deactivatePromocodeMutation);
  const [activatePromocode] = useMutation(activatePromocodeMutation);
  const [filteredValues, setFilteredValues] = useState({
    code: '',
    name: '',
    start_date: '',
    status: '',
  });
  const columns = [
    {
      title: 'Id',
      dataIndex: 'key',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount',
      key: 'value',
      render: ({ value_type: valueType, value }) => {
        return `${value} ${valueType === 'Percentage' ? '%' : 'L.E.'}`;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'Max Quota',
      dataIndex: 'max_quota',
      key: 'max_quota',
    },
    {
      title: 'Total Revenue',
      key: 'total_revenue',
      render: ({ total_revenue: totalRevenue }) => {
        return `${totalRevenue} L.E.`;
      },
    },
    {
      title: 'Cost',
      key: 'cost',
      render: ({ cost }) => {
        return `${cost} L.E.`;
      },
    },
    {
      title: 'Applicable Seats',
      render: ({ seat_types }) => {
        return seat_types.map((seat_type, index) => (
          <p key={index}>{nameLang ==="en" ? seat_type.name_en : seat_type.name_ar }</p>
        ));
      },
    },
    {
      title: 'Status',
      render: ({ key, active }) => {
        return (
          <Can
            perform="UPDATE_PROMO_CODE"
            yes={
              <Switch
                onChange={(checked) => {
                  const togglePromocode = checked
                    ? activatePromocode
                    : deactivatePromocode;

                  togglePromocode({
                    variables: {
                      id: key,
                    },
                  }).catch((err) => {
                    const {
                      extensions: { validation },
                      message,
                    } = err['graphQLErrors'][0];

                    if (validation) {
                      for (let error in validation) {
                        notify('error', validation[error][0]);
                      }
                      return;
                    }
                    notify('error', message);
                  });
                }}
                defaultChecked={active}
              />
            }
          />
        );
      },
    },
    {
      title: 'Actions',
      render: (promocode) => {
        const ViewOrderButton = (
          <Link to={`/promo-code/${promocode?.key}`}>{t('View')}</Link>
        );

        const EditPromoButton = (
          <>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                history.push({
                  pathname: `/new-promo-code/${promocode?.key}`,
                  state: { promocode },
                });
              }}
            >
              {t('Edit')}
            </Button>
          </>
        );
        return (
          <>
            <Can perform="VIEW_PROMO_CODE" yes={ViewOrderButton} />
            <Can perform="CREATE_PROMO_CODE" yes={EditPromoButton} />
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const exportPromo = gql`
    mutation exportPromoReport {
      exportPromoReport {
        status
        message
        data {
          downloadable_link
        }
      }
    }
  `;
  const [getExport, { loading: exportingPromos }] = useMutation(exportPromo, {
    variables: {
      code: filteredValues.promoCode || '',
      name: filteredValues.promoName || '',
      active: filteredValues.active || '',
      start_date: filteredValues.startDate || '',
    },
  });
  const exportPromoCodes = () => {
    getExport()
      .then((res) => {
        const {
          data: {
            exportPromoReport: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };

  return (
    <Spin spinning={loading}>
      {data && data.allPromoCodes && (
        <>
          <ListHeader>
            <PrimaryTitle>{t('Promo Codes')}</PrimaryTitle>
            <div>
              <Can
                perform="EXPORT_PROMO_CODES"
                yes={
                  <PrimaryButton
                    style={{
                      backgroundColor: '#4a4a4a',
                      borderColor: '#4a4a4a',
                      marginRight: '10px',
                    }}
                    onClick={exportPromoCodes}
                  >
                    <Spin spinning={exportingPromos}>
                      {exportingPromos ? t('Exporting') : t('Export Promo Codes')}
                    </Spin>
                  </PrimaryButton>
                }
              />
              <Can
                perform="CREATE_PROMO_CODE"
                yes={
                  <PrimaryButton
                    onClick={() => history.push('/new-promo-code')}
                  >
                    {t('Add_promo')}
                  </PrimaryButton>
                }
              />
            </div>
          </ListHeader>
          <SearchContainer>
            <Filter refetch={refetch} setFilteredValues={setFilteredValues} />
          </SearchContainer>
          <Table
            scroll={{ x: 400 }}
            rowKey="key"
            dataSource={data.allPromoCodes.data.map(({ id, ...promoCode }) => {
              return {
                ...promoCode,
                key: id,
              };
            })}
            columns={columns}
            pagination={{
              defaultCurrent: 1,
              total:
                data.allPromoCodes && data.allPromoCodes.paginatorInfo.total,
              defaultPageSize: 5,
              onChange: (page) => {
                fetchMore({
                  variables: {
                    page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return fetchMoreResult;
                  },
                });
              },
            }}
          />
        </>
      )}
    </Spin>
  );
};

export default PromoCodesList;
