/** Component responsible for listing
 * the available shifts and action button for adding
 * new shifts and editing the shift time of an existing shift */
import React, { useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';
import { Table, Form, Popconfirm } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { notify, ValidateUser } from 'utilities';
import EditableCell from './editable-cell';
import { formatDate, formatTime } from 'utilities';
import { GET_SHIFTS, UPDATE_SHIFT } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';

const ShiftsListContainer = styled.div``;

const ShiftsList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (shift) => shift.id === editingKey;

  /** query for listing the available shifts */
  const { data, loading, fetchMore } = useQuery(GET_SHIFTS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [updateShift, { loading: updating }] = useMutation(UPDATE_SHIFT);

  /** This function is responsible for editing
   * the time of a selected shift */
  const edit = (shift) => {
    form.setFieldsValue({
      start_time: moment(shift.start_time, 'HH:mm a'),
      end_time: moment(shift.end_time, 'HH:mm a'),
    });
    setEditingKey(shift.id);
  };

  /**Cancel editing shift time */
  const cancel = () => {
    setEditingKey('');
  };

  /** Save and update the edited value of shift time */
  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const { start_time, end_time } = row;

      updateShift({
        variables: {
          id,
          startTime: start_time.format('HH:mm'),
          endTime: end_time.format('HH:mm'),
        },
      })
        .then((response) => {
          const {
            data: {
              updateStationShift: { message, status },
            },
          } = response;

          const notificationType = status ? 'success' : 'error';
          notify(notificationType, message);
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  /** Listed columns for available shifts */
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Start Time',
      key: 'start_time',
      render: (shift) => formatTime(shift.start_time),
      editable: true,
    },
    {
      title: 'End Time',
      key: 'end_time',
      render: (shift) => formatTime(shift.end_time),
      editable: true,
    },
    {
      title: 'Start Date',
      key: 'start_date',
      render: (shift) =>
        shift.start_date ? formatDate(shift.start_date) : 'N/A',
    },
    {
      title: 'End Date',
      key: 'end_date',
      render: (shift) => (shift.end_date ? formatDate(shift.end_date) : 'N/A'),
    },
    {
      title: 'Station',
      dataIndex: ['station', 'name_en'],
    },
    {
      title: 'Actions',
      render: (shift) => {
        const editable = isEditing(shift);

        return (
          <Can
            perform="EDIT_STATION_SHIFT"
            yes={
              editable ? (
                <span>
                  <a
                    href="#"
                    onClick={() => save(shift.id)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    {t("Save")}
                  </a>
                  <Popconfirm title={t("Sure to cancel?")} onConfirm={cancel}>
                    <a>{t("Cancel")}</a>
                  </Popconfirm>
                </span>
              ) : (
                <a disabled={editingKey !== ''} onClick={() => edit(shift)}>
                  {t('Edit Time')}
                </a>
              )
            }
          />
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (shift) => ({
        shift,
        dataIndex: col.key,
        title: col.title,
        editing: isEditing(shift),
      }),
    };
  });

  return (
    <ShiftsListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Station Shifts')}</PrimaryTitle>

        <Can
          perform="CREATE_STATION_SHIFT"
          yes={
            <PrimaryButton onClick={() => history.push('/new-shift')}>
              {t('Add Shift')}
            </PrimaryButton>
          }
        />
      </ListHeader>

      <Form form={form} component={false}>
        <Table
          scroll={{ x: 400 }}
          bordered
          dataSource={data?.stationShifts.data}
          columns={mergedColumns}
          loading={loading || updating}
          rowKey="id"
          pagination={{
            total: data?.stationShifts?.paginatorInfo?.total,
            pageSize: 7,
            showSizeChanger: false,
            onChange: (page) => {
              setEditingKey('');
              fetchMore({
                variables: {
                  page,
                },
                updateQuery: (prev, { fetchMoreResult }) =>
                  fetchMoreResult ? fetchMoreResult : prev,
              });
            },
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </ShiftsListContainer>
  );
};

export default ShiftsList;
