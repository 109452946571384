/**Component for entering number columns and rows for building a bus module */
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const GridBuilder = ({
  form: { getFieldDecorator, validateFields },
  setSelectedSeats,
}) => {
  const [t,i18n]=useTranslation("translation")
  const handleBuildGrid = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { rows, cols } = values;
        buildGrid(rows, cols);
      }
    });
  };

  const buildGrid = (rows, cols) => {
    const seats = [];
    for (var i = 0; i < rows; i++) {
      const row = [];
      for (var j = 0; j < cols; j++) {
        const key = i * cols + j + 1;
        const seat = {
          key,
          rows: [i],
          cols: [j],
          type: 'SEAT',
          seatType: '1',
          numbering: key,
        };
        row.push(seat);
      }
      seats.push(row);
      setSelectedSeats(seats);
    }
  };
  return (
    <Form layout="inline" onSubmit={handleBuildGrid}>
      <Form.Item>
        {getFieldDecorator('rows', {
          rules: [
            {
              required: true,
              message: t('Please enter the number of rows!'),
            },
          ],
        })(<InputNumber min={3} max={20} placeholder={t("rows")} />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('cols', {
          type: 'number',
          rules: [
            {
              required: true,
              message: t('Please enter the number of columns!'),
            },
          ],
        })(<InputNumber min={3} max={10} placeholder={t("columns")} />)}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("Build grid")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create()(GridBuilder);
