/**This component is for creating a new city or
 * updating a previously created city
 */
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Row,
  Col,
  Button,
  InputNumber,
  Spin,
  Upload,
  Checkbox,
  Collapse,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { CREATE_CITY, GET_CITY, UPDATE_CITY } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const CitiesForm = ({
  form: { validateFields, getFieldDecorator },
  match: {
    params: { id: cityId },
  },
}) => {
  const [files, setFiles] = useState([]);
  const [t,i18n]=useTranslation("translation");

  const [createCity, { loading: creating }] = useMutation(CREATE_CITY);
  const [updateCity, { loading: updating }] = useMutation(UPDATE_CITY);

  const { data, loading: gettingCity, error } = useQuery(GET_CITY, {
    variables: {
      id: cityId,
    },
  });

  const edit = cityId ? true : false;
  const city = data && data.city;

  /**Handle the the form submission for creating or editing a city */
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const {
          code,
          latitude: lat,
          longitude: long,
          files,
          name_ar,
          name_en,
          isSeasonal,
          seasonalPrice,
        } = values;

        const image = files && files.file;

        if (edit) {
          updateCity({
            variables: {
              id: cityId,
              code,
              long,
              lat,
              name_ar,
              name_en,
              image,
              isSeasonal,
              seasonalPrice,
            },
          })
            .then(() => {
              window.location.href = '/cities';
              notify('success', t('City has been updated successfully'));
            })
            .catch((err) => {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];

              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            });
        } else {
          createCity({
            variables: {
              code,
              long,
              lat,
              name_ar,
              name_en,
              image,
              isSeasonal,
              seasonalPrice,
            },
          })
            .then(() => {
              window.location.href = '/cities';
              notify('success', 'City has been created successfully');
            })
            .catch((err) => {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];

              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            });
        }
      }
    });
  };

  const uploadProps = {
    onRemove: (file) => {
      setFiles([]);
    },
    beforeUpload: (file) => {
      setFiles([file]);
      return false;
    },
    fileList: files,
  };

  return (
    <>
      <ListHeader>
        <PrimaryTitle>{edit ? t('Edit City') : t('Add New City')}</PrimaryTitle>
      </ListHeader>
      <Spin spinning={creating || updating || gettingCity}>
        <Col span={12}>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("Code")} place>
                  {getFieldDecorator('code', {
                    initialValue: city && city.code ? city.code : undefined,
                    rules: [
                      {
                        message: t('The code you entered is invalid!'),
                      },
                      {
                        required: true,
                        message: t('Please, enter the city code!'),
                      },
                    ],
                  })(<Input size="large" placeholder={t("Enter Code")} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("English Name")}>
                  {getFieldDecorator('name_en', {
                    initialValue:
                      city && city.name_en ? city.name_en : undefined,
                    rules: [
                      {
                        message: t('The name you entered is invalid!'),
                      },
                      {
                        required: true,
                        message: t('Please, enter the city name in English!'),
                      },
                      {
                        min: 3,
                        message: t('Name must be at least 3 characters'),
                      },
                    ],
                  })(<Input size="large" placeholder={t("Enter English Name")} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("Arabic Name")}>
                  {getFieldDecorator('name_ar', {
                    initialValue:
                      city && city.name_ar ? city.name_ar : undefined,
                    rules: [
                      {
                        messaget: t('The name you entered is invalid!'),
                      },
                      {
                        required: true,
                        min: 3,
                        message: t('Please, enter the city name in Arabic!'),
                      },
                      {
                        min: 3,
                        message: t('Name must be at least 3 characters'),
                      },
                    ],
                  })(<Input size="large" placeholder={t("Enter Arabic Name")} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("Latitude")}>
                  {getFieldDecorator('latitude', {
                    initialValue: city && city.lat ? city.lat : undefined,
                    rules: [
                      {
                        required: true,
                        message: t('Please, enter the city latitude!'),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      size="large"
                      min={-180}
                      max={180}
                      step={0.111111}
                      placeholder={t("Enter Latitude")}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("Longitude")}>
                  {getFieldDecorator('longitude', {
                    initialValue: city && city.long ? city.long : undefined,
                    rules: [
                      {
                        required: true,
                        message: t('Please, enter the city longitude!'),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      size="large"
                      min={-90}
                      max={90}
                      step={0.111111}
                      placeholder={t("Enter Longitude")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("City image")}>
                  {getFieldDecorator('files', {
                    rules: [
                      {
                        required: edit ? false : true,
                        message: t('Please, add a picture of the city!'),
                      },
                    ],
                  })(
                    <Upload {...uploadProps}>
                      <Button size="large">
                        <UploadOutlined /> {t("Select File")}
                      </Button>
                      {city && city.image && (
                        <img
                          src={city.image}
                          alt="city thumbnail"
                          style={{ maxWidth: '420px', marginTop: '15px' }}
                        ></img>
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Collapse style={{ marginBottom: '32px' }}>
                <Collapse.Panel header={t("Seasonal Details")}>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item>
                        {getFieldDecorator('isSeasonal', {
                          initialValue:
                            city && city.is_seasonal === 'Active'
                              ? true
                              : false,
                          valuePropName: 'checked',
                        })(<Checkbox>{t("Seasonal Destination")}</Checkbox>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item label={t("Seasonal Price")}>
                        {getFieldDecorator('seasonalPrice', {
                          initialValue:
                            city && city.seasonal_price
                              ? city.seasonal_price
                              : undefined,
                        })(
                          <InputNumber
                            size="large"
                            style={{ width: 250 }}
                            placeholder={t("Enter Seasonal Price")}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Row>
            <Row gutter={12} align="middle">
              <Col span={30}>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ marginBottom: '1%', textAlign: 'center' }}
                  >
                    {edit ? t('Update City') : t('Create City')}
                  </Button>
                  <Button
                    href="/cities"
                    size="large"
                    style={{ textAlign: 'center' }}
                  >
                    {t("Cancel")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Spin>
    </>
  );
};

const WrappedCitiesForm = Form.create({ name: 'cities' })(CitiesForm);
export default WrappedCitiesForm;
