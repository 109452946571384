/**Component for creating a new trip in the system */
import React, { useState, useEffect, useRef } from 'react';
import moment, { weekdays } from 'moment';
import { Query } from '@apollo/react-components';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import notify from 'utilities/notifications';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ListHeader, PrimaryTitle } from 'components';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Input,
  Button,
  Row,
  Divider,
  Spin,
  Radio,
  Select,
  DatePicker,
  Popconfirm,
} from 'antd';
import { CreatTrip, AllLocations, AllSalons, FetchTrips } from 'services';
import { DraggableCards, SeatPricing } from './components';
import TimelineTable from './components/timeline-table';
import { handleSeatPricing } from 'utilities';
import { parse } from 'graphql';
import { string } from 'prop-types';
import TripTimeLine from '../trips/trip-timeline';
import { useTranslation } from 'react-i18next';

const { Group: RadioGroup } = Radio;
const { RangePicker } = DatePicker;
const { Option } = Select;
const days = weekdays();

const CreateTrip = ({
  form: {
    getFieldDecorator,
    validateFields,
    getFieldValue,
    resetFields,
    setFieldsValue,
  },
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedBusSalons, setSelectedBusSalons] = useState([]);
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;

  const sortLocations = (locations) => {
    locations.sort(
      (a, b) => selectedCities.indexOf(a.city) - selectedCities.indexOf(b.city)
    );
    return locations;
  };

  const sortSelectedLocations = () => {
    const sortedSelectedLocations = sortLocations([...selectedLocations]);
    setSelectedLocations(sortedSelectedLocations);
  };
  const timelineRef = useRef();
  const routelineRef = useRef();

  const { data: allLocations, loading: allLocationsLoading, error } = useQuery(
    AllLocations,{
        variables: {
          staff: true,
        },
      }
  );
  const [createTrip, { loading: createTripLoading }] = useMutation(CreatTrip);

  useEffect(() => {
    sortSelectedLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCities]);

  const goBack = () => {
    setCurrentStep(1);
  };

  const activeLocations =
    allLocations && allLocations.activeLocations
      ? allLocations.activeLocations
      : [];
  const activeCities = activeLocations.map((location) => location.city);
  const uniqueActiveCities = [...new Set(activeCities)];

  const selectedCitiesObjects = selectedCities.map((cityName) => ({
    ...uniqueActiveCities.find((city) =>  city.name_en  === cityName),
    name: cityName,
  }));
  const accessLevel = getFieldValue('access_level');

  const formFields = [
    'ref_code',
    'access_level',
    'trip_range',
    'selectedCities',
  ];
  if (accessLevel === 'Private') formFields.push('bookingLocations.create');

  let canShowTimeline = true;
  let locationsCount = 0;
  selectedLocations.forEach((l) => {
    if (l.locations.length) locationsCount++;
  });
  if (locationsCount < 2 || selectedLocations.length < 2)
    canShowTimeline = false;

  /**Submit form for creating a new trip */
  const handleCreateTrip = (e, createTrip) => {
    e.preventDefault();

    validateFields(formFields, (err, values) => {
      if (err) return;

      if (!canShowTimeline) {
        notify(
          'error',
          'Selected locations must be 2 at least in 2 different cities!'
        );

        return;
      }

      if (template && values.ref_code == template?.tripTemplate?.ref_code) {
        notify('error', 'ref code must be changed!');
        return;
      }
      if (canShowTimeline && currentStep === 1) {
        setSelectedBusSalons([]);
        setCurrentStep(2);
      }

      if (timelineRef.current) {
        timelineRef.current.validateFields((timelineErrors, timelineValues) => {
          if (routelineRef.current) {
            routelineRef.current.validateFields(
              (routelineErrors, routelineValues) => {
                if (!err && !timelineErrors && !routelineErrors) {
                  const routeLines = handleSeatPricing(routelineValues);
                  const {
                    access_level,
                    ref_code,
                    trip_range,
                    bookingLocations,
                  } = values;
                  const locations = {
                    create: [],
                  };
                  timelineValues.timeLines.create.forEach((tl) => {
                    const timeLineDays = tl.days;
                    delete tl.days;
                    delete tl.inTwoDays;
                    tl.timeLineSlots.create = tl.timeLineSlots.create.filter(
                      (tlS) => tlS.time
                    );
                    tl.timeLineSlots.create.forEach((tlS) => {
                      tlS.days = [];
                      if (tlS.inTwoDays) {
                        timeLineDays.forEach((day) => {
                          const dayIndex = days.findIndex((d) => d === day);
                          const nextDayIndex =
                            dayIndex === days.length - 1 ? 0 : dayIndex + 1;
                          tlS.days.push(days[nextDayIndex]);
                        });
                      } else {
                        tlS.days = timeLineDays;
                      }
                      delete tlS.inTwoDays;
                      tlS.time = tlS.time.format('HH:mm:ss');
                      const currentLocationsIds = locations.create
                        .map((locationObject) => Object.values(locationObject))
                        .flat();
                      if (!currentLocationsIds.includes(tlS.location_id)) {
                        locations.create.push({
                          location_id: tlS.location_id,
                        });
                      }
                    });
                  });

                  const tripValues = {
                    access_level,
                    ref_code,
                    old_template: template_id ?? '',
                    is_active: 'Active',
                    from_date: trip_range[0].format('YYYY-MM-DD'),
                    to_date: trip_range[1].format('YYYY-MM-DD'),
                    locations,
                    ...timelineValues,
                    routeLines,
                    bookingLocations:
                      bookingLocations && bookingLocations.create
                        ? {
                            create: bookingLocations.create.map(
                              (bookingLocation) => ({
                                location_id: bookingLocation,
                              })
                            ),
                          }
                        : {
                            create: [],
                          },
                    addons: {
                      create: [],
                    },
                  };

                  createTrip({
                    variables: {
                      input: tripValues,
                    },
                  })
                    .then(() => {
                      notify('success', 'Trip has been created successfully');
                      if (template_id)
                        history.push(`/cancel-template/${template_id}`);
                      else history.push('/trips');
                    })
                    .catch((err) => {
                      const {
                        extensions: { validation },
                        message,
                      } = err['graphQLErrors'][0];

                      if (validation) {
                        for (let error in validation) {
                          notify('error', validation[error][0]);
                        }
                      } else {
                        notify('error', t(message));
                      }
                    });
                }
              }
            );
          }
        });
      }
    });
  };
  const dateFormat = 'YYYY/MM/DD';

  const updateLocationsArray = (city, arrayofObjects, locations) => {
    // arrayofObjects = [{city: 'Cairo', locations: ['Maadi', 'Naser City']}]
    const isCityExist = arrayofObjects.findIndex(
      (cityObj) => cityObj.city === city
    );
    if (isCityExist === -1) {
      setSelectedLocations([...arrayofObjects, { city, locations }]);
    } else {
      // TODO: hack to force update the nested obj
      const newArrOfObjects = arrayofObjects.map((obj, idx) => {
        if (idx === isCityExist) {
          return {
            ...obj,
            locations,
          };
        } else {
          return obj;
        }
      });
      const sortedArrOfObjects = sortLocations(newArrOfObjects);
      setSelectedLocations(sortedArrOfObjects);
    }
    return arrayofObjects;
  };

  const updateLocations = (locations, city) => {
    updateLocationsArray(city, [...selectedLocations], locations);
  };

  const [timLine, setTimeLine] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  let template_id = query.get('template_id');
  const { data: template, loading, fetchMore } = useQuery(FetchTrips, {
    variables: { tripTemplateId: template_id, first: 10, page: 1 },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (template) {
      let routes = template?.tripTemplate?.routeLines;
      let defaultCities = [];
      routes.forEach((line) => {
        if (!defaultCities.find((dcity) => line.from_city.name_en == dcity)) {
          defaultCities.push(nameLang === "en" ?  line.from_city.name_en : line.from_city.name_ar);
        }
        if (!defaultCities.find((dcity) => line.to_city.name_en == dcity)) {
          defaultCities.push(nameLang === "en" ? line.to_city.name_en : line.to_city.name_ar);
        }
      });
      setSelectedCities(defaultCities);
      setFieldsValue({
        trip_range: [
          moment(template?.tripTemplate?.from_date, dateFormat),
          moment(template?.tripTemplate?.to_date, dateFormat),
        ],
        selectedCities: defaultCities,
      });

      setTimeLine(
        <TripTimeLine
          cardTitle={t('Timeline Info (Locations)')}
          tripTemplate={template.tripTemplate}
        />
      );
    }
  }, [setFieldsValue, t, template]);

  useEffect(() => {
    console.info(selectedLocations);
    const updateSortedLocation = selectedCities.map(
      (item) =>
        selectedLocations.find((j) => j.city === item) || { locations: [] }
    );
    if (
      JSON.stringify(updateSortedLocation) !== JSON.stringify(selectedLocations)
    ) {
      setSelectedLocations(updateSortedLocation);
    }
  }, [selectedLocations, selectedCities]);
  return (
    <>
      <ListHeader>
        <PrimaryTitle>
          {template ? t('Edit Trip Template') : t('New Trip')}
        </PrimaryTitle>
      </ListHeader>
      <Divider />
      <Spin spinning={createTripLoading || allLocationsLoading}>
        {allLocations && allLocations.activeLocations && (
          <Form
            layout="vertical"
            onSubmit={(e) => handleCreateTrip(e, createTrip)}
          >
            <div style={currentStep === 2 ? { display: 'none' } : {}}>
              <Form.Item label={t('Ref Code')}>
                {getFieldDecorator('ref_code', {
                  rules: [
                    {
                      required: true,
                      message: t('Please enter trip ref code!'),
                    },
                  ],
                  initialValue: template?.tripTemplate?.ref_code,
                })(
                  <Input
                    style={{ width: '150px' }}
                    placeholder={t('Ref code')}
                  />
                )}
              </Form.Item>
              <Form.Item label={t('Access Level')}>
                {getFieldDecorator('access_level', {
                  rules: [
                    {
                      required: true,
                      message: t('Please specify the access level!'),
                    },
                  ],
                  initialValue: template?.tripTemplate?.access_level,
                })(
                  <RadioGroup>
                    <Radio value={t('Public')}>{t('Public')}</Radio>
                    <Radio value={t('Private')}>{t('Private')}</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              {accessLevel === 'Private' && (
                <Form.Item label={t('Access Level')}>
                  {getFieldDecorator('bookingLocations.create', {
                    rules: [
                      {
                        required: true,
                        message: t('Please specify the point(s) of sale!'),
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      placeholder="Specify POS"
                      style={{ width: '150px' }}
                    >
                      {activeLocations.map((option, idx) => (
                        <Option key={idx} value={option.id}>
                          {nameLang ==="en" ? option.name_en : option.name_ar}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              <Form.Item>
                {getFieldDecorator('trip_range', {
                  rules: [
                    {
                      type: 'array',
                      required: true,
                      message: t('Please specify the trip range!'),
                    },
                  ],
                })(
                  <RangePicker
                    placeholder={[t('Start date'), t('End date')]}
                    disabledDate={(current) => {
                      return current && current < moment().startOf('day');
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('selectedCities', {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (!value || (value && value.length < 2))
                          callback(t('Please specify at least 2 cities!'));
                        else callback();
                      },
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    placeholder={t('Select Cities')}
                    onChange={(cities) => setSelectedCities(cities)}
                    style={{ width: '220px' }}
                  >
                    {uniqueActiveCities.map((item) => (
                      <Select.Option
                        key={item.id}
                        // Why name instead of id?
                        value={nameLang ==="en" ? item.name_en : item.name_ar}
                      >
                        {nameLang ==="en" ? item.name_en : item.name_ar}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {selectedCities.length > 1 && (
                <DraggableCards
                  selectedCities={selectedCities}
                  setSelectedCities={setSelectedCities}
                  sortSelectedLocations={sortSelectedLocations}
                  getLocations={updateLocations}
                  selectedLocations={selectedLocations}
                  activeLocations={allLocations.activeLocations}
                />
              )}
            </div>
            {currentStep === 2 && (
              <Query query={AllSalons}>
                {({ data: allBusSalons, loading, error }) => {
                  return (
                    <>
                      {loading && <p>Loading ...</p>}
                      {error && <p>{error}</p>}
                      {allBusSalons &&
                        allBusSalons.allBusSalons &&
                        canShowTimeline &&
                        currentStep === 2 && (
                          <>
                            <TimelineTable
                              allBusSalons={allBusSalons.allBusSalons}
                              arrangedSelectedLocations={selectedLocations}
                              locations={allLocations.activeLocations}
                              selectedBusSalons={selectedBusSalons}
                              setSelectedBusSalons={setSelectedBusSalons}
                              ref={timelineRef}
                            />
                            {selectedBusSalons.length > 0 && (
                              <SeatPricing
                                selectedCities={selectedCitiesObjects}
                                busTypes={selectedBusSalons}
                                ref={routelineRef}
                              />
                            )}
                          </>
                        )}
                    </>
                  );
                }}
              </Query>
            )}
            <Row>
              {currentStep === 2 && (
                <Popconfirm
                  title={t("Are you sure discard this step?")}
                  onConfirm={() => goBack()}
                  okText={t('Yes')}
                  cancelText={t('No')}
                >
                  <Button
                    style={{ marginTop: '30px', marginRight: '30px' }}
                    type="primary"
                    htmlType="submit"
                  >
                    {t('Back')}
                  </Button>
                </Popconfirm>
              )}

              <Button
                style={{ marginTop: '30px' }}
                type="primary"
                htmlType="submit"
                loading={createTripLoading}
              >
                {currentStep === 1 ? t('Next') : t('Create')}
              </Button>
            </Row>
          </Form>
        )}
        <div>
          {timLine && (
            <h1 style={{ marginTop: '10px' }}> {t('Old time line data')}</h1>
          )}
          {timLine}
        </div>
      </Spin>
    </>
  );
};

const CreateTripWrapper = Form.create({})(CreateTrip);

export default CreateTripWrapper;
