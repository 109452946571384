import React, { useEffect, useRef, useState, useTransition } from 'react';
import styled from '@emotion/styled/macro';
import { Button, Modal, Skeleton, Space, Spin } from 'antd';
import { colors, formatTime, notify } from 'utilities';
import TripDepartureBackground from '../../images/departure-icon.svg';
import TripArrivalBackground from '../../images/arrival-icon.svg';
import TripDateBackgound from '../../images/date-icon.svg';
import { ValidateUser } from '../../utilities/ValidateUser';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import domtoimage from 'dom-to-image';
import qz from 'qz-tray';
import i18next from 'i18next';
import * as qrcode from 'qrcode';
import {
  GET_PRINT_TICKET_FEES,
  GET_TICKET_PRINT,
  PRINT_TICKET,
} from 'services';
import { TicketInformationToPrint } from './ticketInformationToPrint';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styles from './print.module.css';

const TicketPrintContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: block;
`;
const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  margin-bottom: 40px;
  page-break-before: always;
  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
`;
const TripInfo = styled.div`
  background: #002d59;
  color: #fff;
  border-radius: 10px;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 33%;
  flex-grow: 1;

  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  span {
    margin-inline-start: 30px;
  }
`;
const TripDetails = styled.div`
  flex-basis: 33%;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  flex-grow: 1;
`;
const TripParameter = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;
const TripTotal = styled.p`
  margin: 0;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1025px) {
    margin-top: 15px;
  }
`;
const TripActionsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-inline-start: 10px;
  list-style: none;
  justify-content: space-between;

  a {
    font-size: 14px;
    font-weight: 500;
  }

  @media print {
    display: none;
  }
`;
const NumberWrapper = styled.p`
  margin-top: 20px;
  color: ${colors.primaryColor};
`;
const TripShare = styled.div`
  background: #fff;
  padding: 34px 20px;
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
  border-inline-start: 1px dashed #002d59;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: 980px) {
    border-inline-start: none;
    border-top: 1px dashed #002d59;
  }

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    ${(props) => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 10px #fff;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    ${(props) => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 -10px #fff;
  }

  div:first-of-type {
    display: flex;
    justify-content: space-between;
  }
`;
const TripDepature = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDepartureBackground})
    ${(props) => props.backgroundPosition} 50% no-repeat;
`;
const TripArrival = styled.p`
  padding-inline-start: 30px;
  background: url(${TripArrivalBackground})
    ${(props) => props.backgroundPosition} 50% no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;
const TicketStatus = styled.span`
  display: block;
  color: ${(props) => props.color};
  margin-bottom: 0;
  font-size: 14px;

  ::before {
    content: '';
    display: inline-block;
    border: 1px solid ${(props) => props.color};
    width: 8px;
    height: 8px;
    border-radius: 50px;
    margin-inline-end: 5px;
  }
`;
const TripDate = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDateBackgound}) ${(props) => props.backgroundPosition}
    50% no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;

const ViewTickets = () => {
  const [defaultPrinter, setDefaultPrinter] = useState(null);
  const [loadingPrint, setLoadingPrint] = useState(false);
  ValidateUser();
  useEffect(async () => {
    await getDefault();
  }, []);

  function getDefault() {
    qz.websocket
      .connect()
      .then(qz.printers.getDefault)
      .then(function (printer) {
        console.info(printer);
        if (printer.includes('pdf') || printer.includes('PDF')) {
          window.location.href = '/tickets';
          return;
        }
        setDefaultPrinter(printer);
      })
      .catch(function (e) {
        console.error(e);
      });
  }
  function findPrinters() {
    qz.printers
      .find()
      .then(function (data) {
        var list = '';
        for (var i = 0; i < data.length; i++) {
          console.info(data[i]);
          list += '&nbsp; ' + data[i] + '<br/>';
        }
      })
      .catch(function (e) {
        console.error(e);
      });
  }

  const print_tickets = async () => {
    await data?.getTicketsPrint?.map((ticket, i) => {
      print(ticket);
    });
  };

  const print = async (ticket) => {
    await printTicket({
      variables: {
        id: ticket.id,
      },
    });
    setPrintModalVisible(false);
    const config = qz.configs.create(defaultPrinter);
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const todayDate = today.toDateString();
    const result = await qrcode.toDataURL(ticket.qr_code_name);

    console.info(result);

    const dataToPrint =
      '<html>' +
      '<body>' +
      "<style> .printContainer{  display: 'flex';  flex-direction: 'column';  align-items: center;  align-self: center;  height: fit-content;  }.print {  transform: rotate(270deg) translateX(-5.5cm);  width: 21cm;  height: 8cm;  align-self: center;  page-break-before: always;  page-break-after: always;  padding: 0 !important;}.print span {  font-size: 10px;  color: black;  position: absolute;  line-height: 12px;}.departure {  bottom: 1.6cm;  left: 7.5cm;}.date {  bottom: 1.6cm;  left: 4.2cm;}.seatNumber {  bottom: 1.6cm;  left: 9.75cm;}.tripNumber {  top: 1cm;  left: 4.25cm;}.tripSerial {  top: 2cm;  left: 7cm;}.price {  bottom: 0.1cm;  right: 3cm;}.priceTop {  top: 3.25cm;  left: 12.85cm;}.fromLocation {  bottom: 2.9cm;  left: 4.5cm;}.ticketCodeTop {  bottom: 6.6cm;  left: 6.5cm;}.toLocation {  bottom: 2.9cm;  left: 11.8cm;}.issueDateTop {  top: 2.3cm;  left: 12.85cm;}.issueDateBottom {  bottom: 1cm;  right: 1.9cm;}.tripId{  bottom: 1.7cm;  right: 2.5cm;}.qrCode {  position: absolute;  top: 1.5cm;  right: 1.5cm;} </style> " +
      '<div class="printContainer">' +
      '<div class="print" key=' +
      ticket.id +
      '">' +
      '              <span class="departure">' +
      ticket.from_time +
      '</span>' +
      '              <span class="date">' +
      ticket.from_date +
      '</span>' +
      '              <span class="seatNumber">' +
      +ticket.seat_number +
      ' - ' +
      ticket.seat_type.name_en +
      '              </span>' +
      '              <span class="price"> ' +
      ticket.price +
      ' EGP </span>' +
      '              <span class="priceTop">' +
      ticket.price +
      ' EGP</span>' +
      '              <span class="ticketCodeTop">' +
      ticket.code +
      '</span>' +
      '              <span class="fromLocation">' +
      ticket.from_location.city.name_en +
      '                <br />' +
      ticket.from_location.name_en +
      '              </span>' +
      '              <span class="toLocation">' +
      ticket.to_location.city.name_en +
      '                <br />' +
      ticket.to_location.name_en +
      '              </span>' +
      '              <span class="tripNumber">' +
      ticket.trip.ref_code +
      '</span>' +
      '              <span class="issueDateTop">' +
      todayDate +
      '</span>' +
      '              <span class="issueDateBottom">' +
      todayDate +
      '</span>' +
      '              <span class="tripId">' +
      ticket.code +
      '</span>' +
      '              <img class="qrCode" src="' +
      result +
      '" />' +
      '            </div>';

    const printData = [
      {
        type: 'pixel',
        format: 'html',
        flavor: 'plain',
        data: dataToPrint,
      },
    ];
    qz.print(config, printData)
      .catch((e) => {
        console.info(e);
      })
      .then(async () => {
        // console.info('done')
      });
  };

  const componentRef = useRef();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18next.language;
  const { ticketIds } = useParams();
  const ids = ticketIds.split('&');

  const [printModalVisible, setPrintModalVisible] = useState(false);

  const handleCancel = () => {
    setPrintModalVisible(false);
  };

  const { data, loading, refetch, error } = useQuery(GET_TICKET_PRINT, {
    variables: {
      id: ids,
    },
  });
  const [getPrintFees, { data: printFees }] = useLazyQuery(
    GET_PRINT_TICKET_FEES,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [printTicket] = useMutation(PRINT_TICKET);
  return (
    <>
      {/* {data?.getTicketsPrint?.length > 0 && (
        <>
          <Button
            type="primary"
            shape="round"
            style={{ float: 'right' }}
            size="large"
            onClick={() => {
              getPrintFees({
                variables: {
                  id: ids,
                },
              });
              setPrintModalVisible(true);
            }}
          >
            Print
          </Button>
        </>
      )} */}
      <TripActionsList className="action-list">
        <li>
          {
            <Button
              style={{
                float: 'right',
                backgroundColor: 'white',
                color: '#1E93FF',
                border: ' 0px',
              }}
              size="large"
              onClick={async () => {
                await getPrintFees({
                  variables: {
                    id: ids,
                  },
                });
                setPrintModalVisible(true);
              }}
            >
              {t('Print Ticket')}
            </Button>
          }
        </li>
        <li></li>
      </TripActionsList>

      <TicketPrintContainer>
        {data?.getTicketsPrint?.map((ticket, i) => {
          const saveTicket = () => {
            const el = document.getElementById(ticket.id);
            document.querySelector('.action-list').style.visibility = 'hidden';
            domtoimage.toBlob(el).then(function (blob) {
              window.saveAs(blob, `Ticket_${ticket.id}.png`);
              document.querySelector('.action-list').style.visibility = 'unset';
            });
          };
          const color =
            ticket.status === 'Cancelled'
              ? colors.danger
              : ticket.status === 'Paid'
              ? colors.success
              : colors.primaryColor;
          return (
            <TicketContainer id={ticket.id} key={i}>
              <TripInfo>
                <TripDepature backgroundPosition="0%">
                  {nameLang === 'en'
                    ? ticket.from_location.city.name_en
                    : ticket.from_location.city.name_ar}{' '}
                  -{' '}
                  {nameLang === 'en'
                    ? ticket.from_location.name_en
                    : ticket.from_location.name_ar}{' '}
                  <span>{formatTime(ticket.from_time)}</span>
                </TripDepature>
                <TripArrival backgroundPosition="0%">
                  {nameLang === 'en'
                    ? ticket.to_location.city.name_en
                    : ticket.to_location.city.name_ar}{' '}
                  -{' '}
                  {nameLang === 'en'
                    ? ticket.to_location.name_en
                    : ticket.to_location.name_ar}{' '}
                  <span>{formatTime(ticket.to_time)}</span>
                </TripArrival>
                <TripDate backgroundPosition="0%">
                  {t('Departure Date')} <span>{ticket.from_date}</span>
                </TripDate>
              </TripInfo>
              <TripDetails>
                <TripParameter>
                  Trip Number <span>{ticket.trip.ref_code}</span>
                </TripParameter>
                <TripParameter>
                  Seat Number {ticket.seat_number}{' '}
                  <span>
                    {nameLang === 'en'
                      ? ticket.seat_type.name_en
                      : t(ticket.seat_type.name_en)}
                  </span>
                </TripParameter>
                <TripTotal>
                  <span>
                    {t('Price')}{' '}
                    <TicketStatus color={color} style={{ marginTop: '10px' }}>
                      {ticket.status}
                    </TicketStatus>
                  </span>
                  <span>{ticket.price}</span>
                </TripTotal>
              </TripDetails>
              <TripShare start="left">
                <div>
                  <div>
                    <QRCode
                      id={`qr-${ticket.id}`}
                      value={ticket.qr_code_name}
                    />
                  </div>
                </div>
                <NumberWrapper>
                  {t('Order Number:')} {ticket.order.order_number}
                </NumberWrapper>
                <NumberWrapper>
                  {t('Ticket Code:')} {ticket.code}
                </NumberWrapper>
              </TripShare>
            </TicketContainer>
          );
        })}
      </TicketPrintContainer>

      <Spin spinning={loading}>
        <div style={{ display: 'none' }}>
          <TicketInformationToPrint data={data} ref={componentRef} />
        </div>
      </Spin>
      <Modal
        title={<Space size={'middle'}>Print Fees</Space>}
        visible={printModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Cancel')}
          </Button>,
          <Button
            key="cancel"
            onClick={() => {
              setLoadingPrint(true);
              print_tickets().then((r) => {});
            }}
            disabled={loadingPrint}
          >
            {t('Confirm')}
          </Button>,
        ]}
      >
        <Skeleton
          active
          round
          title={false}
          paragraph={{ rows: 2, width: ['100%', '75%'] }}
          loading={false}
        >
          {printFees && printFees.checkPrintTicketFees.printFees > 0 && (
            <p>
              Please note that printing the ticket will cost{' '}
              {printFees.checkPrintTicketFees.printFees} L.E., confirm to
              continue...
            </p>
          )}
          {printFees && printFees.checkPrintTicketFees.printFees == 0 && (
            <p>
              Please note that printing this ticket will cost{' '}
              {printFees.checkPrintTicketFees.printFees} L.E., and Re-Printing
              the ticket will cost additional fees, confirm to continue...
            </p>
          )}
        </Skeleton>
      </Modal>
    </>
  );
};

export default ViewTickets;
