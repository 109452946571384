/**Component for Listing all available Stations in the system
 * with action buttons for editing or deactivating stations
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Table, Divider, Popconfirm } from 'antd';
import { Mutation } from '@apollo/react-components';
import {
  GET_LOCATIONS,
  DEACTIVATE_LOCATION,
  ACTIVATE_LOCATION,
} from 'services';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { useHistory } from 'react-router-dom';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const LocationsList = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const nameLang = i18n.language;
  const [page, handlePageChange] = useState(1);
  const { data, loading, error, fetchMore } = useQuery(GET_LOCATIONS, {
    variables: {
      page: 1,
      first: 5,
      orderBy: 'EN',
    },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'City',
      dataIndex: ['city', nameLang === "en" ? 'name_en' : 'name_ar'],
      key: 'city.name_en',
    },
    {
      title: 'English Name',
      dataIndex: 'name_en',
      key: 'name_en',
    },
    {
      title: 'Arabic Name',
      dataIndex: 'name_ar',
      key: 'name_ar',
    },
    {
      title: 'Type',
      // dataIndex: 'type',
      key: 'type',
      render : (record)=> record.type ==="Station" ? t("Station") : record.type,
    },
    {
      title: 'Active Statue',
      // dataIndex: 'is_active',
      key: 'is_active',
      render : (statue)=> statue.is_active ==="Active"? t("Active") : t("Inactive"),
    },
    {
      title: '',
      render: (location) => {
        const isActiveLocation = location.is_active === 'Active';
        return (
          <>
            <Can
              perform="UPDATE_STATION"
              yes={<a href={`/edit-location/${location.id}`}>{t('Edit')}</a>}
            />

            <Divider type="vertical" />

            <Can
              perform="UPDATE_STATION"
              yes={
                <Mutation
                  onError={(error) => {
                    if (error) {
                      if (error['graphQLErrors'][0]?.extensions) {
                        const {
                          extensions: { validation },
                          message,
                        } = error['graphQLErrors'][0];
                        if (validation) {
                          for (let error in validation) {
                            notify('error', validation[error][0]);
                          }
                        }
                        notify('error', t(message));
                      }
                    }
                  }}
                  mutation={
                    isActiveLocation ? DEACTIVATE_LOCATION : ACTIVATE_LOCATION
                  }
                  variables={{ id: Number(location.id) }}
                  refetchQueries={() => [
                    {
                      query: GET_LOCATIONS,
                      variables: {
                        page,
                        first: 5,
                        orderBy: 'EN',
                      },
                    },
                  ]}
                >
                  {(mutationFunc, { loading, error }) => {
                    return (
                      <>
                        {loading && <p>{loading}</p>}
                        <Popconfirm
                          title={
                            isActiveLocation
                              ? t('Sure to Deactivate?')
                              : t('Sure to Activate?')
                          }
                          onConfirm={mutationFunc}
                        >
                          <a href="#!">
                            {isActiveLocation ? t('Deactivate') : t('Activate')}
                          </a>
                        </Popconfirm>
                      </>
                    );
                  }}
                </Mutation>
              }
            />
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Stations/Stops')}</PrimaryTitle>
        <Can
          perform="CREATE_STATION"
          yes={
            <PrimaryButton
              onClick={() => {
                history.push('/new-location');
              }}
            >
              {t('Add New')}
            </PrimaryButton>
          }
        />
      </ListHeader>

      <Table
        scroll={{ x: 400 }}
        dataSource={data.locations.data}
        columns={columns}
        loading={loading}
        pagination={{
          total:
            data &&
            data.locations &&
            data.locations.paginatorInfo &&
            data.locations.paginatorInfo.total,
          pageSize: 5,
          onChange: (page) => {
            fetchMore({
              variables: {
                page: page,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              },
            });
          },
        }}
      />
    </>
  );
};

export default LocationsList;
